.edit-container {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 16px;
  flex: 100%;
  height: 100%;
}
@media (max-width: 1024px) {
  .edit-container {
    padding: 0;
    margin: 0;
    flex: 100%;
  }
}
.edit-container .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .edit-container .section-title {
    font-size: 16px;
  }
}
.edit-container .section-header {
  display: flex;
  flex-direction: revert;
  justify-content: space-around;
  padding: 1em 0;
}
@media (max-width: 1024px) {
  .edit-container .section-header {
    padding: 10px 0;
  }
}
.edit-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.edit-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.edit-container .tabs {
  width: 100%;
}
@media (max-width: 1024px) {
  .edit-container .tabs {
    margin: 10px auto;
  }
}
.edit-container .tabs .tab-content .status-logs-container .container-box .form-sections .empty-summary-container {
  width: auto;
  margin: 0;
}
.edit-container .tabs .tab-list {
  width: 100%;
  display: flex;
}
@media (max-width: 1024px) {
  .edit-container .tabs .tab-list {
    margin: 0 0 10px 0;
  }
}
@media (max-width: 500px) {
  .edit-container .tabs .tab-list .tab-list-item {
    padding: 6px 12px;
    font-size: 12px;
  }
}
@media (max-width: 321px) {
  .edit-container .tabs .tab-list .tab-list-item {
    padding: 6px 12px;
    font-size: 10px;
  }
}
