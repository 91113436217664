.ReactModalPortal .modal-content .field-title {
  font-family: 'Roboto-Medium';
  color: #048041;
}
.ReactModalPortal .modal-content .field-multiselect-val-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.ReactModalPortal .modal-content .field-multiselect-val-container div {
  background-color: #048041;
  color: #FFFFFF;
  padding: 0.5em;
  border-radius: 6px;
  margin: 0.2em;
}
