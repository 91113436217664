.banking-details-container {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 16px;
  flex: 100%;
}
@media (max-width: 1024px) {
  .banking-details-container {
    padding: 0;
    margin: 0;
    flex: 100%;
    min-height: 360px;
  }
}
.banking-details-container .MuiTableCell-body p {
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}
.banking-details-container .container-box {
  width: auto;
  overflow-y: auto;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #E3DDDA;
  padding: 20px;
  height: 52vh;
  text-align: center;
  display: flex;
  flex-direction: column;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box {
    max-height: 250px;
  }
}
@media (max-width: 415px) {
  .banking-details-container .container-box {
    max-height: 250px;
    overflow: auto;
    margin-bottom: 15px;
  }
}
@media (max-width: 321px) {
  .banking-details-container .container-box {
    max-height: 200px;
    margin-bottom: 15px;
  }
}
.banking-details-container .container-box .bank-details {
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .bank-details {
    display: unset;
  }
}
.banking-details-container .container-box .bank-details .details {
  width: 52%;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .bank-details .details {
    width: 100%;
    margin-bottom: 30px;
  }
}
.banking-details-container .container-box .bank-details .details .inst-code {
  margin-bottom: 0px;
}
.banking-details-container .container-box .bank-details .details div {
  display: grid;
  grid-template-columns: 45% 55%;
  margin-bottom: 80px;
  align-items: center;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .bank-details .details div {
    display: flex;
    flex-direction: column;
    margin-bottom: 10px;
  }
}
.banking-details-container .container-box .bank-details .details div label {
  text-align: right;
  padding-right: 10px;
  font-size: 18px;
  font-family: 'Roboto-Medium';
  color: #048041;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .bank-details .details div label {
    width: 100%;
    text-align: left;
    padding-right: unset;
    padding-bottom: 4px;
    font-size: 14px;
  }
}
.banking-details-container .container-box .bank-details .details div span {
  text-align: left;
  padding-left: 10px;
  font-size: 18px;
  font-family: 'Roboto-Regular';
  color: #686C6D;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .bank-details .details div span {
    width: 100%;
    text-align: left;
    padding-left: unset;
    font-size: 14px;
  }
}
.banking-details-container .container-box .bank-details .vertical-separator {
  width: 4%;
  min-height: 230px;
}
.banking-details-container .container-box .bank-details .vertical-separator .separator {
  background-color: #E3DDDA;
  width: 1px;
  height: 180px;
  border: none;
  margin-top: 50px;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .bank-details .vertical-separator {
    display: none;
  }
}
.banking-details-container .container-box .bank-details .cheque-container {
  width: 44%;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .bank-details .cheque-container {
    width: 100%;
    max-height: 400px;
  }
}
.banking-details-container .container-box .bank-details .cheque-container .cheque {
  background-color: #fff;
  border-radius: 5px;
}
.banking-details-container .container-box .bank-details .cheque-container .cheque div .pdf-file {
  height: 320px;
  width: 100%;
}
.banking-details-container .container-box .bank-details .cheque-container .cheque .tools {
  display: flex;
  flex-direction: row;
  justify-content: end;
}
.banking-details-container .container-box .bank-details .cheque-container .cheque .tools button {
  margin-right: 5px;
}
.banking-details-container .container-box .bank-details .cheque-container .cheque .react-transform-wrapper {
  height: 300px;
  width: 100%;
  border: 0.5px solid #E3DDDA;
  border-radius: 6px;
  margin-top: 10px;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .bank-details .cheque-container .cheque .react-transform-wrapper {
    height: 366px;
    width: unset;
  }
}
.banking-details-container .container-box .bank-details .confirm-message {
  width: 48%;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .bank-details .confirm-message {
    width: 100%;
  }
}
.banking-details-container .container-box .bank-details .confirm-message p {
  text-align: left;
}
.banking-details-container .container-box .horizontal-bar {
  height: 0px;
  border-bottom: 1px solid #E3DDDA;
  margin: 2em 25%;
  width: 50%;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .horizontal-bar {
    display: none;
  }
}
.banking-details-container .container-box .review-actions {
  padding-bottom: 20px;
}
.banking-details-container .container-box .review-actions .approved-decision-section .approved-decision-values {
  display: flex;
  flex-direction: row;
}
@media (max-width: 896px) {
  .banking-details-container .container-box .review-actions .approved-decision-section .approved-decision-values {
    flex-direction: column;
  }
}
@media (max-width: 896px) {
  .banking-details-container .container-box .review-actions .approved-decision-section .approved-decision-values .vertical-separator {
    display: none;
  }
}
.banking-details-container .container-box .review-actions .approved-decision-section .approved-decision-values .input-parent .input-container .input-label {
  font-size: 16px;
}
@media (max-width: 896px) {
  .banking-details-container .container-box .review-actions .approved-decision-section .approved-decision-values .input-parent .input-container .input-label {
    font-size: 14px;
  }
}
.banking-details-container .container-box .review-actions .approved-decision-section .approved-decision-values .input-parent .input-container .switch-container {
  flex: 0 1 40%;
}
.banking-details-container .container-box .review-actions .form-inner-elements {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .review-actions .form-inner-elements {
    display: flex;
    flex-direction: column;
  }
}
.banking-details-container .container-box .review-actions .form-inner-elements .form-element {
  width: 35%;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .review-actions .form-inner-elements .form-element {
    width: 100%;
    margin-bottom: 10px;
  }
}
.banking-details-container .container-box .review-actions .form-inner-elements .form-element .input-parent .input-container {
  margin-top: unset;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .review-actions .form-inner-elements .form-element .input-parent .input-container {
    margin-top: 20px;
  }
}
.banking-details-container .container-box .review-actions .form-inner-elements .form-element .input-parent .input-container .input-label {
  font-family: 'Roboto-Medium';
  font-size: 18px;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .review-actions .form-inner-elements .form-element .input-parent .input-container .input-label {
    font-size: 14px;
  }
}
@media (max-width: 415px) {
  .banking-details-container .container-box .review-actions .form-inner-elements .form-element .input-parent .input-container .input-box {
    font-size: 14px;
  }
  .banking-details-container .container-box .review-actions .form-inner-elements .form-element .input-parent .input-container .input-box input {
    font-size: 14px;
  }
}
.banking-details-container .container-box .review-actions .form-inner-elements .form-element-two {
  width: 70%;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .review-actions .form-inner-elements .form-element-two {
    width: 100%;
  }
}
.banking-details-container .container-box .review-actions .form-inner-elements .form-element-two .input-parent .input-container {
  margin-top: unset;
}
.banking-details-container .container-box .review-actions .form-inner-elements .form-element-two .input-parent .input-container .input-label {
  font-family: 'Roboto-Medium';
  font-size: 18px;
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .review-actions .form-inner-elements .form-element-two .input-parent .input-container .input-label {
    font-size: 14px;
  }
}
@media (max-width: 415px) {
  .banking-details-container .container-box .review-actions .form-inner-elements .form-element-two .input-parent .input-container .input-box {
    font-size: 14px;
  }
  .banking-details-container .container-box .review-actions .form-inner-elements .form-element-two .input-parent .input-container .input-box input {
    font-size: 14px;
  }
}
.banking-details-container .container-box .form-inner-section {
  margin-top: 40px;
}
.banking-details-container .container-box .form-inner-section .note-label {
  text-align: left;
  font-size: 18px;
  font-family: 'Roboto-Medium';
}
@media (max-width: 1024px) {
  .banking-details-container .container-box .form-inner-section .note-label {
    font-size: 16px;
  }
}
.banking-details-container .action-buttons {
  display: flex;
  justify-content: end;
}
.banking-details-container .action-buttons .button-set {
  width: 70%;
  justify-content: end;
}
@media (max-width: 1024px) {
  .banking-details-container .action-buttons .button-set {
    width: 100%;
    display: grid;
    grid-template-columns: 100%;
  }
}
.banking-details-container .action-buttons .button-set .confirm-btn {
  width: 50%;
}
@media (max-width: 1024px) {
  .banking-details-container .action-buttons .button-set .confirm-btn {
    width: 100%;
  }
}
.banking-details-container .action-buttons .button-set .goback {
  width: 50%;
}
@media (max-width: 1024px) {
  .banking-details-container .action-buttons .button-set .goback {
    width: 100%;
  }
}
.banking-details-container .action-buttons .button-set .enerva-btn {
  width: 96%;
}
.banking-details-container .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .banking-details-container .section-header {
    padding: 10px 0;
  }
}
.banking-details-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media (max-width: 1024px) {
  .banking-details-container .section-header .section-title {
    font-size: 16px;
  }
}
.banking-details-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.banking-details-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.banking-details-container .section-below-header {
  display: flex;
  padding-top: 0.5em;
  padding-bottom: 1em;
}
@media (max-width: 1024px) {
  .banking-details-container .section-below-header {
    padding: 10px 0;
    flex-direction: column;
  }
}
.banking-details-container .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .banking-details-container .section-below-header .section-title {
    font-size: 14px;
  }
}
