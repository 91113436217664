.table-loading .skeleton:nth-child(odd) {
  opacity: 0.7;
  animation: skeleton-loading-odd 1s linear infinite alternate;
}
.table-loading .skeleton:nth-child(even) {
  opacity: 0.7;
  animation: skeleton-loading-even 1s linear infinite alternate;
}
.table-loading .skeleton1 {
  opacity: 0.7;
  animation: skeleton-loading-text 1s linear infinite alternate;
}
.table-loading .skeleton-table {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 5rem;
}
@media (max-width: 1024px) {
  .table-loading .skeleton-table {
    height: 300px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.table-loading .skeleton-text {
  width: 10%;
  margin: 30px;
  height: 1rem;
  border-radius: 1rem;
}
@media (max-width: 1024px) {
  .table-loading .skeleton-text {
    height: 25px;
    width: 80%;
    margin: 10px;
  }
}
@keyframes skeleton-loading-odd {
  0% {
    background-color: hsl(30, 12%, 97%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
@keyframes skeleton-loading-odd {
  0% {
    background-color: hsl(20, 14%, 87%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
@keyframes skeleton-loading-text {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.card-loading {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-gap: 3em;
  margin: 15px 0;
}
@media (max-width: 950px) {
  .card-loading {
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
  }
}
.card-loading .card {
  width: auto;
  font-size: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
@media (max-width: 950px) {
  .card-loading .card {
    margin: 1em 0;
    width: auto;
    max-width: 100%;
  }
}
.card-loading .card .ipn-head {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  width: 50%;
  padding: 0 15px;
  position: relative;
  border-radius: 2rem;
  margin-left: 10px;
}
.card-loading .card .apps-content {
  height: 240px;
}
.card-loading .card .apps-content .app-text1 {
  height: 20px;
  border-radius: 1rem;
  margin-top: 10px;
}
.card-loading .card .apps-content .app-text2 {
  height: 30px;
  border-radius: 1rem;
  margin-top: 10px;
}
.card-loading .card .bar {
  height: 50px;
  background: #048041;
  box-shadow: 0px 4px 4px 0px #CCCCB8;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.card-loading .card .fiar-bar {
  height: 50px;
}
.card-loading .skeleton-app {
  opacity: 0.7;
  animation: loading-head 1s linear infinite alternate;
}
.card-loading .skeleton-head {
  opacity: 0.7;
  animation: loading-head 1s linear infinite alternate;
}
@keyframes loading-head {
  0% {
    background-color: hsl(200, 20%, 70%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}
.card-loading .skeleton-bar {
  opacity: 0.7;
  animation: skeleton-loading-card 1s linear infinite alternate;
}
@keyframes skeleton-loading-card {
  0% {
    background-color: hsl(150, 94%, 26%);
  }
  100% {
    background-color: hsl(139, 29%, 72%);
  }
}
