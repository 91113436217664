.check-box {
  width: 20px;
  height: 20px;
  border-radius: 30%;
  border: 1px solid #DBDBDB;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
@media (max-width: 896px) {
  .check-box {
    width: 24px;
    height: 24px;
  }
}
.check-box-unchecked {
  background-color: #FFFFFF;
}
.check-box-unchecked .check-mark {
  display: none;
}
.check-box-checked {
  background-color: #048041;
}
.check-box-checked .check-mark {
  display: block;
  position: absolute;
  width: 10px;
}
