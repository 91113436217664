.document-container {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 16px;
  flex: 100%;
}
@media (max-width: 1024px) {
  .document-container {
    padding: 0;
    margin: 0;
    flex: 100%;
    height: 80vh;
    min-height: 360px;
  }
}
.document-container .section-header {
  display: flex;
  padding: 1em 0;
}
@media (max-width: 1024px) {
  .document-container .section-header {
    padding: 10px 0;
  }
}
.document-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  flex: 28% 0;
}
@media (max-width: 1024px) {
  .document-container .section-header .section-title {
    font-size: 16px;
  }
}
.document-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.document-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.document-container .section-below-header {
  display: flex;
  padding: 1em 0;
}
@media (max-width: 1024px) {
  .document-container .section-below-header {
    padding: 10px 0;
    flex-direction: column;
  }
}
.document-container .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  flex: 50% 0;
  text-align: left;
}
@media (max-width: 1024px) {
  .document-container .section-below-header .section-title {
    font-size: 16px;
  }
}
.document-container .section-below-header .document-search-selection {
  flex: 0 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
@media (max-width: 1024px) {
  .document-container .section-below-header .document-search-selection {
    justify-content: flex-start;
  }
}
.document-container .section-below-header .document-search-selection label {
  color: #98989A;
}
.document-container .section-below-header .document-search-selection .input-container {
  width: 400px;
}
@media (max-width: 1024px) {
  .document-container .section-below-header .document-search-selection .input-container {
    margin: 0;
    width: 300px;
  }
}
.document-container .section-below-header .document-search-selection .input-container .enerva-dropdown__control {
  font-size: 14px;
}
.document-container .section-below-header .document-search-selection .input-container .enerva-dropdown__control .enerva-dropdown__single-value {
  font-size: 14px;
}
.document-container .section-below-header .document-search-selection .input-container .enerva-dropdown__menu {
  font-size: 14px;
}
.document-container .section-below-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
}
.document-container .section-below-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.document-container .contractor-document-outer-container {
  overflow: overlay;
  display: flex;
}
.document-container .contractor-document-outer-container .contractor-document-container {
  padding: 1em;
  color: #001737;
  border: 1px solid #f2f2f2;
  min-width: 450px;
  width: 100%;
}
.document-container .contractor-document-outer-container .contractor-document-container P {
  color: #001737;
  font-size: 14px;
}
.document-container .contractor-document-outer-container .contractor-document-container .contractor-document-header {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Medium';
}
.document-container .contractor-document-outer-container .contractor-document-container .contractor-document-header .document-detail {
  width: 25%;
  display: flex;
  align-items: center;
  padding: 0 1em;
  text-align: left;
}
.document-container .contractor-document-outer-container .contractor-document-container .contractor-document-table {
  display: flex;
  flex-direction: column;
  max-height: 50vh;
  overflow: overlay;
  position: relative;
}
.document-container .contractor-document-outer-container .contractor-document-container .contractor-document-table .contractor-document-details {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Regular';
}
.document-container .contractor-document-outer-container .contractor-document-container .contractor-document-table .contractor-document-details .document-detail {
  width: 25%;
  display: flex;
  align-items: center;
  padding: 0 1em;
  text-align: left;
}
.document-container .contractor-document-outer-container .contractor-document-container .contractor-document-table .contractor-document-details .document-actions span {
  margin: 0 1em;
}
.document-container .contractor-document-outer-container .contractor-document-container .contractor-document-table .contractor-document-details:nth-child(even) {
  background: #F8F7F6;
}
@media (max-width: 1024px) {
  .document-container .contractor-document-outer-container .contractor-document-container .contractor-document-table {
    padding: 1em;
    flex-direction: column;
  }
  .document-container .contractor-document-outer-container .contractor-document-container .contractor-document-table .vertical-separator-box {
    display: none;
  }
}
