.admin-footer {
  text-align: center;
  color: #FFFFFF;
  clear: both;
  background: #C00000;
  font-size: 18px;
  display: flex;
  height: 10vh;
  align-items: center;
  justify-content: center;
}
.admin-footer img {
  vertical-align: middle;
  margin: 0 0.5em 0 0;
}
@media (max-width: 1024px) {
  .admin-footer {
    font-size: 12px;
  }
}
