.review-container {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 16px;
  flex: 100%;
  min-height: 650px;
}
@media (max-width: 1024px) {
  .review-container {
    padding: 0;
    margin: 0;
    flex: 100%;
    min-height: 360px;
  }
}
.review-container .section-header {
  display: flex;
  padding: 1em 0;
}
@media (max-width: 1024px) {
  .review-container .section-header {
    padding: 10px 0;
  }
}
.review-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  flex: 28% 0;
}
@media (max-width: 1024px) {
  .review-container .section-header .section-title {
    font-size: 16px;
  }
}
.review-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.review-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.review-container .section-below-header {
  display: flex;
  padding: 1em 0;
}
@media (max-width: 896px) {
  .review-container .section-below-header {
    padding: 10px 0;
    flex-direction: column;
  }
}
.review-container .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .review-container .section-below-header .section-title {
    font-size: 16px;
  }
}
.review-container .section-below-header .review-status-selection {
  flex: 0 72%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .review-container .section-below-header .review-status-selection {
    justify-content: flex-start;
  }
}
@media (max-width: 415px) {
  .review-container .section-below-header .review-status-selection {
    margin-bottom: 20px;
  }
}
.review-container .section-below-header .review-status-selection label {
  color: #98989A;
}
.review-container .section-below-header .review-status-selection .input-container {
  width: 400px;
}
.review-container .section-below-header .review-status-selection .input-container .input-label {
  padding: 1em 1em;
  font-size: 14px;
}
@media (max-width: 896px) {
  .review-container .section-below-header .review-status-selection .input-container .input-label {
    padding: 4px;
    font-size: 13px;
  }
}
@media (max-width: 1023px) {
  .review-container .section-below-header .review-status-selection .input-container {
    margin: 0;
    width: 300px;
  }
}
@media (width: 1024px) {
  .review-container .section-below-header .review-status-selection .input-container {
    margin: 0;
    width: 315px;
  }
}
.review-container .section-below-header .review-status-selection .input-container .enerva-dropdown__control {
  font-size: 14px;
}
.review-container .section-below-header .review-status-selection .input-container .enerva-dropdown__control .enerva-dropdown__single-value {
  font-size: 14px;
}
.review-container .section-below-header .review-status-selection .input-container .enerva-dropdown__menu {
  font-size: 14px;
}
.review-container .section-below-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
}
.review-container .section-below-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.review-container .customer-review-container {
  padding: 1em;
  color: #001737;
  border: 1px solid #f2f2f2;
  max-height: 440px;
}
@media (max-height: 640px) {
  .review-container .customer-review-container {
    height: 202px;
    overflow: auto;
  }
}
@media (max-height: 731px) {
  .review-container .customer-review-container {
    height: 230px;
    overflow: auto;
  }
}
.review-container .customer-review-container .contractor-review-table {
  display: flex;
  flex-direction: row;
  overflow: overlay;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-left {
  display: flex;
  flex-direction: column;
  flex: 65% 0;
  min-width: 600px;
  overflow: overlay;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-left .contractor-review-header {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Medium';
  text-align: left;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-left .contractor-review-header .review-detail {
  display: flex;
  align-items: center;
  padding: 0 1em;
  text-align: left;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-left .contractor-review-header .review-detail:nth-child(1) {
  width: 10%;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-left .contractor-review-header .review-detail:nth-child(2) {
  width: 20%;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-left .contractor-review-header .review-detail:nth-child(3) {
  width: 40%;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-left .contractor-review-header .review-detail:nth-child(4) {
  width: 30%;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-left .contractor-review-inner-container {
  max-height: 200px;
  overflow: overlay;
}
@media (max-width: 896px) {
  .review-container .customer-review-container .contractor-review-table .contractor-review-table-left .contractor-review-inner-container {
    max-height: unset;
    overflow: hidden;
  }
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-left .contractor-review-inner-container .contractor-review-details {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Regular';
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail {
  display: flex;
  align-items: center;
  padding: 0 1em;
  text-align: left;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(1) {
  width: 30%;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(2) {
  width: 40% ;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(3) {
  width: 30% ;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-status span {
  margin-right: 1em;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-right {
  display: flex;
  flex: 0 1 35%;
  align-items: center;
  font-size: 14px;
  text-align: left;
  color: #001737;
  flex-direction: column;
  justify-content: center;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-table-right span {
  font-family: 'Roboto-Regular';
  padding: 1em;
}
.review-container .customer-review-container .contractor-review-table .contractor-review-details:nth-child(even) {
  background: #F8F7F6;
}
@media (max-width: 1024px) {
  .review-container .customer-review-container .contractor-review-table {
    padding: 1em;
    flex-direction: column;
  }
  .review-container .customer-review-container .contractor-review-table .vertical-separator-box {
    display: none;
  }
}
.review-container .customer-review-container .review-mode {
  flex: 100% 0;
}
.review-container .customer-review-container .review-mode .contractor-review-table-left {
  flex: 100% 0;
}
.review-container .customer-review-container .review-mode .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(1) {
  width: 10%;
}
.review-container .customer-review-container .review-mode .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(2) {
  width: 20%;
}
.review-container .customer-review-container .review-mode .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(3) {
  width: 40%;
}
.review-container .customer-review-container .review-mode .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(4) {
  width: 30%;
}
.review-container .customer-review-container .review-mode .vertical-separator-box {
  display: none;
}
.review-container .customer-review-container .review-mode .contractor-review-table-right {
  display: none;
}
.review-container .customer-review-container .review-notes-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1em 0;
}
.review-container .customer-review-container .review-notes-container label {
  font-size: 14px;
  font-family: 'Roboto-Medium';
  text-align: left;
  padding: 1em 0;
}
.review-container .customer-review-container .review-notes-container textarea {
  width: auto;
  border: 1px solid #f2f2f2;
  padding: 1em;
}
.review-container .customer-review-container .review-notes-container textarea:focus {
  border: 1px solid #048041;
  outline: none;
}
.review-container .customer-review-container .review-notes-container .review-notes-text {
  display: flex;
  width: auto;
  padding: 1em 2em;
  font-size: 12px;
  border: 1px solid #CCC;
  min-height: 50px;
  color: #048041;
  background: #F8F7F6;
  text-align: left;
}
.review-container .review-btn-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(33.33%, 0fr));
  justify-content: flex-end;
  margin: 1em 0 0 0;
}
@media (max-width: 896px) {
  .review-container .review-btn-container {
    grid-template-columns: repeat(auto-fit, minmax(33.33%, 1fr));
  }
}
.review-container .review-btn-container button {
  margin: 10px 0 10px 20px;
}
@media (max-width: 896px) {
  .review-container .review-btn-container button {
    font-size: 12px;
  }
}
.review-container .review-btn-container button.edit-review {
  background-color: #FFF;
  color: #048041;
}
