.card-slider-container {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
  flex-direction: row;
  width: 100%;
}
.card-slider {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  padding: 10px 0;
}
@media (max-width: 1024px) {
  .card-slider {
    padding: 10px 20px;
  }
}
.react-multi-carousel-item--active {
  display: flex;
  justify-content: center;
}
@media (min-width: 646px) {
  .react-multi-carousel-item--active {
    width: 250px !important;
  }
}
.react-multi-carousel-item {
  display: flex;
  justify-content: center;
}
@media (min-width: 646px) {
  .react-multi-carousel-item {
    width: 250px !important;
  }
}
.react-multiple-carousel__arrow {
  background: #048041 !important;
  z-index: 8 !important;
}
.cards {
  display: flex;
  width: 180px;
  padding: 20px;
  flex-direction: column;
  align-items: flex-start;
  flex-shrink: 0;
  border-radius: 7px;
  border: 1px solid #E3DDDA;
  background: #FFF;
  box-shadow: 0px 5px 8px 0px rgba(0, 0, 0, 0.25);
  margin: 10px 0;
}
.cards p {
  font-size: 14px;
  font-family: 'Roboto-Medium';
  color: #333131;
  padding-right: 5px ;
  margin: 0;
}
.cards .details-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 10px;
}
.cards .details-box .count {
  color: #048041;
  font-family: 'Roboto-Medium';
  font-size: 32px;
}
.cards .details-box .vertical-bar {
  height: 0px;
  border-bottom: 1px solid #e3ddda;
  margin: 10px 0em;
}
.cards .details-box .value-box {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 5px;
}
.cards .details-box .value-box .dollar {
  width: 100%;
  display: flex;
  gap: 5px;
  font-size: 14px;
  font-family: 'Roboto-Regular';
  color: #333131;
}
.cards .details-box .dollar {
  width: 100%;
  display: flex;
  gap: 5px;
  font-size: 14px;
  font-family: 'Roboto-Regular';
  color: #333131;
}
.prev-button,
.next-button {
  background-color: transparent;
  color: #1933d9;
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .prev-button,
  .next-button {
    width: 40px;
  }
}
/* Hide horizontal scrollbar */
.card-slider::-webkit-scrollbar {
  display: none;
}
/* Optional: Add responsive styles for smaller screens */
@media (max-width: 768px) {
}
