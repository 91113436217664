.ReactModalPortal h3 {
  text-transform: uppercase;
  text-align: center;
}
.ReactModalPortal p {
  font-size: 16px;
  text-align: center;
}
.ReactModalPortal .subtitle {
  color: #048041;
}
.ReactModalPortal .disabled-link {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed !important;
}
.ReactModalPortal .disabled-link:hover {
  cursor: no-drop !important;
}
.ReactModalPortal .terms-list {
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  margin: auto;
  max-width: 550px;
}
.ReactModalPortal .terms-list li {
  text-align: left;
  font-size: 16px;
  font-family: 'Roboto-Regular';
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReactModalPortal .terms-list li p {
  flex: 80%;
  text-align: left;
}
.ReactModalPortal .terms-list li p span {
  color: #048041;
  text-decoration: underline;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.ReactModalPortal .loading-container {
  height: 100%;
  width: 100%;
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.ReactModalPortal .loading-container img {
  width: 70%;
  max-width: 300px;
  animation: spin 3s linear infinite;
}
.ReactModalPortal .close-icon {
  display: none;
  z-index: 999;
}
@media (max-width: 415px) {
  .ReactModalPortal .close-icon {
    display: flex;
    position: absolute;
    left: 96%;
    top: -2vh;
    height: 22px;
  }
}
.ReactModalPortal .close-icon svg {
  fill: #048041;
}
.ReactModalPortal .modal-content {
  text-align: center;
  padding: 0 2em 2em 2em;
}
@media (max-width: 1024px) {
  .ReactModalPortal .modal-content {
    padding: 0;
  }
}
@media (max-width: 1024px) {
  .ReactModalPortal .modal-content .input-parent .input-container .quill .ql-container {
    height: 80px;
  }
}
.ReactModalPortal .modal-content .work-in-progress {
  color: #048041 !important;
  text-transform: uppercase;
  font-family: 'Roboto-Bold';
  font-size: 2.5em;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 300px;
}
.ReactModalPortal .modal-content .password-inputs {
  max-width: 550px;
  margin-bottom: 2em;
  margin-top: 2em;
}
.ReactModalPortal .modal-content .password-inputs .input-parent .error-message {
  padding-left: 42%;
}
.ReactModalPortal .modal-content .password-inputs .input-parent .passwordReqs {
  padding-left: 42%;
}
.ReactModalPortal .modal-content .input-container {
  text-align: right;
  margin-top: 15px;
  display: flex;
}
.ReactModalPortal .modal-content .input-container .input-wrapper {
  width: 100%;
}
@media (max-width: 768px) {
  .ReactModalPortal .modal-content .input-container {
    text-align: left;
    flex-direction: column;
  }
}
.ReactModalPortal .modal-content .input-container .input-label {
  font-size: 18px;
  flex: 63%;
  color: #048041;
}
@media (max-width: 768px) {
  .ReactModalPortal .modal-content .input-container .input-label {
    width: unset;
  }
}
.ReactModalPortal .modal-content .input-container .input-box {
  width: 100%;
  font-size: 16px;
}
@media (max-width: 768px) {
  .ReactModalPortal .modal-content .input-container .input-box {
    width: auto;
  }
}
.ReactModalPortal .modal-content .input-container .password-toggle {
  height: 52px;
}
.ReactModalPortal .modal-content .error-text {
  color: #DB1900;
}
.ReactModalPortal .modal-content p {
  font-family: 'Roboto-Light';
  font-size: 18px;
  color: #98989A;
}
.ReactModalPortal .modal-content p span {
  color: #048041;
  font-weight: bold;
}
@media (max-width: 1024px) {
  .ReactModalPortal .modal-content p {
    font-size: 12px;
  }
}
.ReactModalPortal .modal-content .modal-question {
  font-family: 'Roboto-Medium';
}
.ReactModalPortal .modal-content .button-set {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .button-set {
    flex-direction: column;
  }
}
.ReactModalPortal .modal-content .button-set .enerva-btn {
  flex: 50%;
}
.ReactModalPortal .modal-content .button-set .enerva-btn button {
  width: 90%;
}
@media (max-width: 896px) {
  .ReactModalPortal .modal-content .button-set .enerva-btn {
    margin: 10px 0;
  }
}
.ReactModalPortal .modal-content .button-set a {
  flex: 50%;
}
.ReactModalPortal .modal-content .button-set a button {
  width: 90%;
}
@media (max-width: 690px) {
  .ReactModalPortal .ReactModal__Content--after-open {
    max-height: 88% !important;
  }
}
.ReactModalPortal .ReactModal__Overlay {
  background-color: rgba(152, 152, 154, 0.8) !important;
  z-index: 12 !important;
}
.ReactModalPortal a {
  text-decoration: underline;
}
