.portcustomer-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: hidden;
  overflow-x: none;
}
.portcustomer-container .input-parent .error-message {
  padding-left: 0px !important;
}
.portcustomer-container .MuiTableCell-body p {
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}
@media (max-width: 1024px) {
  .portcustomer-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
    padding-bottom: 2em;
  }
}
.portcustomer-container .button-set {
  width: 100%;
}
.portcustomer-container .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .portcustomer-container .section-header {
    padding: 10px 0;
  }
}
.portcustomer-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media screen and (max-width: 1024px) {
  .portcustomer-container .section-header .section-title {
    font-size: 16px !important;
  }
}
.portcustomer-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  font-family: 'Roboto-Medium';
  word-break: break-word;
  padding: 0 2%;
}
.portcustomer-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.portcustomer-container .header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media (max-width: 896px) {
  .portcustomer-container .header-container {
    flex-direction: column;
  }
}
.portcustomer-container .header-container .section-below-header {
  display: flex;
  padding-top: 0.5em;
  padding-bottom: 1em;
  width: 50%;
}
@media (max-width: 896px) {
  .portcustomer-container .header-container .section-below-header {
    width: 100%;
    align-items: center;
  }
}
@media (max-width: 321px) {
  .portcustomer-container .header-container .section-below-header .section-title {
    font-size: 14px !important;
  }
}
@media (max-width: 1024px) {
  .portcustomer-container .header-container .section-below-header .section-title {
    font-size: 16px !important;
  }
}
.portcustomer-container .header-container .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
.portcustomer-container .header-container .port-btn {
  width: 50%;
  text-align: right;
  align-self: center;
}
@media (max-width: 896px) {
  .portcustomer-container .header-container .port-btn {
    width: 100%;
  }
}
.portcustomer-container .header-container .port-btn .change-btn {
  width: 220px;
}
@media (max-width: 896px) {
  .portcustomer-container .header-container .port-btn .change-btn {
    width: 100%;
    margin: 0px 0px 20px 0px;
  }
}
.portcustomer-container .logs-container-box {
  border: 0.5px solid #f2f2f2;
  width: 99%;
}
@media (max-width: 321px) {
  .portcustomer-container .logs-container-box .MuiTableContainer-root {
    max-height: 300px;
  }
}
.portcustomer-container .logs-container-box .empty-summary-container {
  margin: 0px;
  width: 100%;
  padding: 0;
}
@media (max-width: 896px) {
  .portcustomer-container .logs-container-box .empty-summary-container {
    font-size: 14px;
  }
}
.portcustomer-container .container-box {
  border: 1px solid #f2f2f2;
  width: 96%;
  padding: 10px;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box {
    max-height: 210px;
    overflow-y: auto;
    width: 91% !important;
  }
  .portcustomer-container .container-box .MuiTableContainer-root {
    max-height: 300px;
  }
}
.portcustomer-container .container-box .slide-title-container {
  width: 100%;
  padding: 5px 5px 5px 5px;
}
.portcustomer-container .container-box .slide-title-container .slide-title {
  font-family: 'Roboto-Medium';
  font-size: 16px;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .slide-title-container .slide-title {
    font-size: 14px;
  }
}
.portcustomer-container .container-box .slide-details {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .slide-details {
    flex-direction: column;
    padding: 5px 0px 5px 0px;
    align-items: center;
  }
}
.portcustomer-container .container-box .slide-details .slide-text {
  padding: 15px 0 10px 0;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .slide-details .slide-text {
    width: 90%;
    padding: 5px 0 5px 0;
  }
}
.portcustomer-container .container-box .slide-details .duplicate-btn {
  padding: 5px 0 5px 0;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .slide-details .duplicate-btn {
    width: 90%;
  }
}
.portcustomer-container .container-box .facility-table {
  max-height: 350px;
  overflow: auto;
  padding: 5px 5px 5px 5px;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .facility-table {
    max-height: 210px !important;
    padding: 5px 8px 5px 5px;
    overflow: auto;
  }
}
@media (max-width: 1024px) {
  .portcustomer-container .container-box .facility-table {
    max-height: 300px;
    padding: 5px 8px 5px 5px;
    overflow: auto;
  }
}
.portcustomer-container .container-box .inner-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 50px 1% 50px 1%;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .inner-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 5px 2px 5px 2px;
    overflow: auto;
  }
}
.portcustomer-container .container-box .inner-container .selector-label {
  width: 43%;
  padding: 10px 15px 10px 10px;
  text-align: right;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .inner-container .selector-label {
    width: 90%;
    text-align: center;
    padding: 5px 5px 5px 5px;
  }
}
.portcustomer-container .container-box .inner-container .selector-element {
  width: 40%;
  padding-bottom: 10px;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .inner-container .selector-element {
    width: 90%;
  }
}
.portcustomer-container .container-box .input-facility-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  overflow: auto;
  padding: 50px 1% 50px 1%;
}
@media (max-width: 1024px) {
  .portcustomer-container .container-box .input-facility-container {
    flex-direction: column;
    padding: 5px 2px 5px 2px;
  }
}
.portcustomer-container .container-box .input-facility-container .facility-selector-label {
  width: 50%;
  padding-top: 10px;
}
@media (max-width: 1024px) {
  .portcustomer-container .container-box .input-facility-container .facility-selector-label {
    width: 90%;
  }
}
.portcustomer-container .container-box .input-facility-container .facility-selector-element {
  width: 40%;
}
@media (max-width: 1024px) {
  .portcustomer-container .container-box .input-facility-container .facility-selector-element {
    width: 90%;
  }
}
.portcustomer-container .container-box .confirm-container {
  display: flex;
  flex-direction: column;
  padding: 5px 5px 5px 5px;
}
.portcustomer-container .container-box .confirm-container .confirmation-slide-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .confirm-container .confirmation-slide-container {
    flex-direction: column;
  }
}
.portcustomer-container .container-box .confirm-container .confirmation-slide-container .confirmation-input {
  width: 65%;
  padding-top: 30px;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .confirm-container .confirmation-slide-container .confirmation-input {
    width: 100%;
    padding: 5px 5px 5px 5px;
  }
}
.portcustomer-container .container-box .confirm-container .confirmation-slide-container .confirmation-seperator {
  width: 35%;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .confirm-container .confirmation-slide-container .confirmation-seperator {
    width: 100%;
  }
}
.portcustomer-container .container-box .confirm-container .confirm-inner-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .confirm-container .confirm-inner-container {
    flex-direction: column;
    width: 100%;
  }
}
.portcustomer-container .container-box .confirm-container .confirm-inner-container .confirm-selector-label {
  width: 50%;
  text-align: right;
  padding-top: 10px;
  padding-right: 20px;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .confirm-container .confirm-inner-container .confirm-selector-label {
    width: 98%;
    text-align: left;
  }
}
.portcustomer-container .container-box .confirm-container .confirm-inner-container .confirm-selector-element {
  width: 40%;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .confirm-container .confirm-inner-container .confirm-selector-element {
    width: 98%;
  }
}
.portcustomer-container .container-box .form-label {
  width: 33%;
  padding-right: 15px;
  text-align: left;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .form-label {
    flex-direction: column;
    width: 100%;
    padding-right: 0;
  }
}
.portcustomer-container .container-box .form-label.full {
  width: 100% !important;
}
.portcustomer-container .container-box .form-label.bold {
  font-weight: bold;
}
.portcustomer-container .container-box .info-text {
  border-left: 2px solid #048041;
  text-align: left;
  padding: 2em 2em;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .info-text {
    padding-top: 1em;
  }
}
.portcustomer-container .container-box .info-text p {
  text-align: left;
}
.portcustomer-container .container-box .email-text {
  padding: 5px 5px 5px 5px;
}
.portcustomer-container .container-box .form-inner-section {
  padding: 5px 5px 5px 5px;
}
.portcustomer-container .container-box .second-container {
  padding: 0 15% 10px 15%;
}
.portcustomer-container .container-box .second-container .input-parent .input-container {
  display: grid;
  grid-template-columns: 55% 45%;
}
.portcustomer-container .container-box .second-container .input-parent .input-container .input-label {
  width: 90%;
  font-size: 14px;
}
.portcustomer-container .container-box .second-container .input-parent .input-container .input-box {
  width: 89%;
}
.portcustomer-container .container-box .additional-btn {
  display: grid;
  grid-template-columns: 50% 50%;
  width: 100%;
}
.portcustomer-container .container-box .additional-btn .back-btn {
  text-align: left;
  cursor: pointer;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .additional-btn .back-btn {
    font-size: 12px;
  }
}
.portcustomer-container .container-box .additional-btn .details-btn {
  text-align: right;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .additional-btn .details-btn {
    font-size: 12px;
  }
}
.portcustomer-container .container-box .empty-summary-container {
  margin: 0px;
  width: 100%;
  padding: 0;
}
@media (max-width: 896px) {
  .portcustomer-container .container-box .empty-summary-container {
    font-size: 14px;
  }
}
.portcustomer-container .container-box span {
  color: #048041;
}
.portcustomer-container .container-box .details p {
  font-size: 16px;
  text-align: left;
}
@media (max-width: 1024px) {
  .portcustomer-container .container-box .details p {
    text-align: center;
  }
}
.portcustomer-container .container-box .note-section {
  text-align: left;
}
@media (max-width: 415px) {
  .portcustomer-container .container-box .note-section .note-label {
    margin-left: 2px;
  }
}
