.drawer-component {
  display: flex;
  flex-direction: row;
  flex: 100%;
  margin: 10px 0;
}
.drawer-container {
  width: 350px;
  min-width: 350px;
  height: 100%;
  padding: 0 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
}
@media (max-width: 896px) {
  .drawer-container {
    width: 318px;
    min-width: unset;
  }
}
.drawer-container .drawer-btn {
  position: fixed;
  bottom: 0;
  width: 378px;
  margin: 0;
  background: #048041;
  padding: 15px 0;
  border: none;
  border-radius: 0;
}
@media (max-width: 896px) {
  .drawer-container .drawer-btn {
    width: 347px;
  }
}
.drawer-container .drawer-btn.first-btn {
  bottom: 49px;
  background: #fff;
  border: 1px solid #048041;
  color: #048041;
}
.drawer-container .close-icon {
  height: 26px;
  width: 26px;
  position: absolute;
  top: 3px;
  right: 3px;
  z-index: 99;
  cursor: pointer;
}
.drawer-container .close-icon svg {
  fill: #048041;
}
.drawer-container .drawer-heading {
  font-size: 20px;
  display: flex;
  text-align: center;
  font-family: 'Roboto-Medium';
  text-transform: uppercase;
  color: #048041;
  justify-content: center;
  padding: 1em;
  width: auto;
  border-bottom: 1px solid #048041;
}
.drawer-container .drawer-form-container {
  display: flex;
  flex-direction: column;
  height: 85vh;
  overflow: overlay;
  font-size: 16px;
  font-family: 'Roboto-Regular';
  width: 100%;
  padding: 1em 0;
}
