.location-input-wrapper {
  width: 100%;
  height: 100%;
  position: relative;
}
.location-input-wrapper .location-dropdown__single-value {
  padding-right: 2em;
}
@media (max-width: 896px) {
  .location-input-wrapper .location-dropdown__single-value {
    padding-right: 3em;
  }
}
.location-input-wrapper .clear-button {
  position: absolute;
  right: 2%;
  top: 22%;
  z-index: 2;
  width: 1.2em;
  cursor: pointer;
  opacity: 1;
}
.location-input-wrapper .clear-button:hover {
  opacity: 0.7;
}
