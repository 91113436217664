@import "react-date-range/dist/styles.css";
@import "react-date-range/dist/theme/default.css";
.program-filter .check-box-checked {
  background-color: #048041 !important;
}
.payout-tab {
  padding: 20px 20px;
  border: 1px solid #f2f2f2;
  width: 100%;
  flex-direction: column;
}
@media (max-width: 1024px) {
  .payout-tab {
    padding: 1em;
  }
}
.payout-tab .payout-top-bar {
  display: flex;
  flex-direction: row;
  flex: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .payout-tab .payout-top-bar {
    flex-direction: row;
    padding-bottom: 1em;
  }
}
@media (max-width: 768px) {
  .payout-tab .payout-top-bar {
    flex-direction: column;
    padding-bottom: 1em;
  }
}
.payout-tab .payout-top-bar .payout-search {
  max-width: 500px;
  display: flex;
  flex-direction: row;
  flex: 60% 0;
}
@media (max-width: 896px) {
  .payout-tab .payout-top-bar .payout-search {
    max-width: unset;
    width: 100%;
  }
}
.payout-tab .payout-top-bar .payout-search .search-box {
  width: 100%;
}
.payout-tab .payout-top-bar .enerva-react-date-picker-container {
  width: 100%;
}
.payout-tab .payout-top-bar .payout-filter {
  display: flex;
  flex: 0 40%;
  max-width: 240px;
  margin: 0 20px 0 0;
  position: relative;
}
@media (max-width: 1024px) {
  .payout-tab .payout-top-bar .payout-filter {
    max-width: 420px;
    margin: auto;
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 680px) {
  .payout-tab .payout-top-bar .payout-filter {
    max-width: unset;
    width: 100%;
  }
}
.payout-tab .payout-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-gap: 3em;
  margin: 15px 0;
  max-height: 75vh;
  padding-right: 10px;
  overflow: auto;
  position: relative;
}
@media (max-width: 950px) {
  .payout-tab .payout-card-container {
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
  }
}
.payout-tab .payout-card-container .card {
  width: auto;
  font-size: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
@media (max-width: 950px) {
  .payout-tab .payout-card-container .card {
    margin: 1em 0;
    width: 90vw;
    max-width: 100%;
  }
}
.payout-tab .payout-card-container .card .header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  padding: 0 15px;
  position: relative;
}
.payout-tab .payout-card-container .card .header .ipn-details {
  width: 80%;
  display: flex;
  flex-direction: COLUMN;
  justify-content: flex-start;
}
.payout-tab .payout-card-container .card .header .ipn-details .ipnno {
  color: #000;
  font-weight: "Roboto-Medium";
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.payout-tab .payout-card-container .card .header .ipn-details .ipnno .status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 20px;
  background: #e3ddda;
}
.payout-tab .payout-card-container .card .header .ipn-details .ipnno .status.pending {
  background: #e3ddda;
}
.payout-tab .payout-card-container .card .header .ipn-details .ipnno .status.generated {
  background: #535765;
}
.payout-tab .payout-card-container .card .header .ipn-details .ipnno .status.received {
  background: #fed108;
}
.payout-tab .payout-card-container .card .header .ipn-details .ipnno .status.spot-check-completed {
  background: #ffa500;
}
.payout-tab .payout-card-container .card .header .ipn-details .ipnno .status.pvrr-approved {
  background: #66e100;
}
.payout-tab .payout-card-container .card .header .ipn-details .ipnno .status.initiated {
  background: #146031;
}
.payout-tab .payout-card-container .card .header .ipn-details .info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: "Roboto-Regular";
  font-size: 12px;
  padding: 5px 0;
}
.payout-tab .payout-card-container .card .header .ipn-details .info .date {
  border-right: 1px solid #048041;
  width: 30%;
}
@media (max-width: 600px) {
  .payout-tab .payout-card-container .card .header .ipn-details .info .date {
    padding-right: 10px;
  }
}
.payout-tab .payout-card-container .card .header .ipn-details .info .creator {
  width: 70%;
  padding: 0 15px;
}
.payout-tab .payout-card-container .card .header .ipn-action {
  cursor: pointer;
  width: 20%;
  display: flex;
  background-size: 18px;
  height: 19px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../../images/ExportIcon.svg");
  position: relative;
}
.payout-tab .payout-card-container .card .apps-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 180px !important;
}
.payout-tab .payout-card-container .card .apps-list .app {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 10px;
  align-items: center;
  padding: 0 15px;
  height: 50px;
}
.payout-tab .payout-card-container .card .apps-list .app .column {
  width: 33%;
  font-size: 12px;
  font-weight: "Roboto-Light";
}
.payout-tab .payout-card-container .card .apps-list .app .column.right {
  text-align: right;
}
.payout-tab .payout-card-container .card .apps-list .app .column.name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 28%;
  padding-right: 10px;
}
.payout-tab .payout-card-container .card .footer {
  font-size: 12px;
  font-weight: "Roboto-Medium";
  color: #048041;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 15px;
}
.payout-tab .payout-card-container .card .footer .morelink {
  cursor: pointer;
}
.payout-tab .payout-card-container .card .action-bar {
  height: 50px;
  background: #048041;
  box-shadow: 0px 4px 4px 0px #ccccb8;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.payout-tab .payout-card-container .card .action-bar .disabledButton {
  opacity: 0.3;
  pointer-events: none;
}
.payout-tab .payout-card-container .card .action-bar .column {
  width: 25%;
  border-right: 1px solid #ffffff;
  background-size: 23px;
  height: 26px;
}
.payout-tab .payout-card-container .card .action-bar .column:hover {
  opacity: 0.7;
}
.payout-tab .payout-card-container .card .action-bar .column.received {
  cursor: pointer;
  width: 25%;
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../../images/MoneyReceived.svg");
  position: relative;
}
.payout-tab .payout-card-container .card .action-bar .column.spot-check {
  cursor: pointer;
  width: 25%;
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../../images/SpotCheck.svg");
  position: relative;
  justify-content: center;
}
.payout-tab .payout-card-container .card .action-bar .column.initiated {
  cursor: pointer;
  width: 25%;
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../../images/PayoutInitiated.svg");
  position: relative;
}
.payout-tab .payout-card-container .card .action-bar .column.pvrr-approved {
  cursor: pointer;
  width: 25%;
  display: flex;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url("../../../../images/TransferedToPlooto.svg");
  position: relative;
  justify-content: center;
  border-right: none;
}
.payout-tab .no-records-found {
  text-align: center;
  padding: 1em;
  background: #f8f7f6;
}
.payout-tab .expand-ipn-menu {
  display: none;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 125%;
  background: #f1eeec;
  border: 1px solid #f1eeec;
  text-align: right;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 10px 10px 0px #ccccb8;
  color: #001737;
  z-index: 1;
  width: auto;
  min-width: 130px;
}
@media (max-width: 1000px) {
  .payout-tab .expand-ipn-menu {
    width: 100%;
    left: 0px;
    min-width: unset;
  }
}
.payout-tab .expand-ipn-menu.ipn-menu-open {
  display: block;
}
.payout-tab .expand-ipn-menu .expanded-menu-panel div {
  padding: 1em;
  font-size: 16px;
  border-top: 1px solid #e6e5e5;
  text-align: left;
}
@media (max-width: 1000px) {
  .payout-tab .expand-ipn-menu .expanded-menu-panel div {
    font-size: 12px;
  }
}
.payout-tab .expand-ipn-menu .expanded-menu-panel div:hover {
  background: rgba(4, 128, 65, 0.25);
  color: #048041;
}
.payout-tab .expand-ipn-menu .expanded-menu-panel-loading div:hover {
  background: #ffffff !important;
  cursor: default;
}
