.label-filter {
  background-size: 18px !important;
  background-image: url('../../../images/LabelIcon.svg') !important;
}
@media (max-width: 321px) {
  .status-logs-container {
    max-height: 490px;
  }
}
.incentive-total-validation-mdl .button-set {
  justify-content: space-around !important;
}
.incentive-total-validation-mdl .button-set button {
  width: 50%;
  flex: none !important;
}
@media (max-width: 1024px) {
  .incentive-total-validation-mdl .button-set button {
    width: 100%;
  }
}
