.retrofit-review-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: hidden;
}
@media (max-width: 1024px) {
  .retrofit-review-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
    padding-bottom: 2em;
  }
}
@media (max-width: 896px) {
  .retrofit-review-container {
    overflow-x: hidden;
    max-width: 90vw;
  }
}
.retrofit-review-container .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .retrofit-review-container .section-header {
    padding: 10px 0;
  }
}
.retrofit-review-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media (max-width: 1024px) {
  .retrofit-review-container .section-header .section-title {
    font-size: 16px;
  }
}
.retrofit-review-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.retrofit-review-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.retrofit-review-container .section-below-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .retrofit-review-container .section-below-header {
    padding: 10px 0;
    flex-direction: column;
  }
}
.retrofit-review-container .section-below-header .title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .retrofit-review-container .section-below-header .title {
    font-size: 16px;
    margin: 0;
  }
}
.retrofit-review-container .container {
  width: 97%;
  overflow-y: auto;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #98989A;
  padding: 10px;
  max-height: 370px;
  text-align: center;
}
@media (max-width: 896px) {
  .retrofit-review-container .container {
    width: 92%;
    max-height: 260px;
  }
}
.retrofit-review-container .container span {
  color: #048041;
}
.retrofit-review-container .container .facility-table {
  min-height: 50vh;
}
.retrofit-review-container .container .facility-table .horizontal-bar {
  height: 0px;
  border-bottom: 1px solid #E3DDDA;
  margin: 3em 25%;
  width: 50%;
}
.retrofit-review-container .container .facility-table .message-block {
  display: grid;
  grid-template-columns: 55% 5% 40%;
}
@media (max-width: 896px) {
  .retrofit-review-container .container .facility-table .message-block {
    grid-template-rows: auto;
  }
}
.retrofit-review-container .container .facility-table .message-block .fields-block .decision-options {
  margin-top: 30px;
}
.retrofit-review-container .container .facility-table .message-block .fields-block .decision-options label {
  text-align: left;
  padding-left: 0;
  flex: 40% 1;
}
.retrofit-review-container .container .facility-table .message-block .fields-block .decision-options .date-picker {
  border-bottom: 1px solid #048041;
}
.retrofit-review-container .container .facility-table .message-block .fields-block .decision-options .date-picker svg {
  fill: #048041;
}
.retrofit-review-container .container .facility-table .message-block .message p span {
  color: #048041;
}
.retrofit-review-container .container .facility-table .message-block .vertical-separator {
  width: 4%;
  min-height: 120px;
}
.retrofit-review-container .container .facility-table .message-block .vertical-separator .separator {
  background-color: #E3DDDA;
  width: 1px;
  height: 110px;
  border: none;
  margin-top: 10px;
}
@media (max-width: 1024px) {
  .retrofit-review-container .container .facility-table .message-block .vertical-separator {
    display: none;
  }
}
.retrofit-review-container .container .facility-table .note-section {
  margin-top: 20px;
}
.retrofit-review-container .container .facility-table .note-section label {
  display: flex;
  font-size: 16px;
}
.retrofit-review-container .button-set {
  width: 100%;
  margin-top: 20px;
}
