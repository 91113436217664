.send-cheque-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.send-cheque-container .MuiTableCell-body p {
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}
@media (max-width: 1024px) {
  .send-cheque-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
  }
}
.send-cheque-container .button-set {
  width: 100%;
  padding-top: 22px;
}
.send-cheque-container .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .send-cheque-container .section-header {
    padding: 10px 0;
  }
}
.send-cheque-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media (max-width: 1024px) {
  .send-cheque-container .section-header .section-title {
    font-size: 16px;
  }
}
.send-cheque-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.send-cheque-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.send-cheque-container .section-below-header {
  display: flex;
  padding-top: 0.5em;
  padding-bottom: 1em;
  width: 100%;
}
@media (max-width: 1024px) {
  .send-cheque-container .section-below-header {
    padding: 10px 0;
    flex-direction: column;
  }
}
.send-cheque-container .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .send-cheque-container .section-below-header .section-title {
    font-size: 18px;
  }
}
.send-cheque-container .open-app-container {
  width: 100%;
}
.send-cheque-container .open-app-container .main-container {
  border: 0.5px solid #E3DDDA;
  padding: 20px 10px;
}
@media (max-width: 321px) {
  .send-cheque-container .open-app-container .main-container {
    max-height: 205px;
    overflow-y: auto;
  }
}
.send-cheque-container .open-app-container .main-container .form-inner-section .note-label {
  font-family: 'Roboto-Medium';
  font-size: 20px;
  text-align: start;
  color: black;
}
.send-cheque-container .open-app-container .main-container p {
  text-align: start;
}
.send-cheque-container .info-container {
  border: 0.5px solid #E3DDDA;
  width: 99%;
  padding: 45px 0px;
  display: flex;
  flex-direction: row;
}
@media (max-width: 896px) {
  .send-cheque-container .info-container {
    flex-direction: column;
    padding: unset;
  }
}
@media (max-width: 415px) {
  .send-cheque-container .info-container {
    max-height: 280px;
    overflow: auto;
  }
}
@media (max-width: 321px) {
  .send-cheque-container .info-container {
    max-height: 195px;
  }
}
@media (max-width: 896px) {
  .send-cheque-container .info-container .vertical-separator-box {
    display: none;
  }
}
.send-cheque-container .info-container .payee-address-container-left {
  width: 47%;
}
@media (max-width: 896px) {
  .send-cheque-container .info-container .payee-address-container-left {
    width: 100%;
    padding: 20px;
  }
}
.send-cheque-container .info-container .payee-address-container-left .payee-details-row {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  margin-bottom: 10px;
}
@media (max-width: 896px) {
  .send-cheque-container .info-container .payee-address-container-left .payee-details-row {
    flex-direction: column;
    font-size: 14px;
  }
}
.send-cheque-container .info-container .payee-address-container-left .payee-details-row label {
  font-family: 'Roboto-Medium';
  color: #048041;
  width: 40%;
  text-align: right;
  margin-right: 10%;
}
@media (max-width: 896px) {
  .send-cheque-container .info-container .payee-address-container-left .payee-details-row label {
    text-align: left;
    width: 100%;
    margin: unset;
  }
}
.send-cheque-container .info-container .payee-address-container-left .payee-details-row span {
  font-family: 'Roboto-Regular';
  width: 50%;
  text-align: start;
}
@media (max-width: 896px) {
  .send-cheque-container .info-container .payee-address-container-left .payee-details-row span {
    text-align: left;
    width: 100%;
    margin: unset;
  }
}
.send-cheque-container .info-container .payee-address-container-right {
  width: 47%;
}
@media (max-width: 896px) {
  .send-cheque-container .info-container .payee-address-container-right {
    padding: 20px;
    text-align: left;
    width: 88%;
    margin: unset;
  }
}
.send-cheque-container .container {
  border: 0.5px solid #E3DDDA;
  width: 99%;
  padding: 28px 0px;
}
@media (max-width: 896px) {
  .send-cheque-container .container {
    padding: unset;
    max-height: 440px;
    overflow-y: auto;
  }
}
@media (max-width: 415px) {
  .send-cheque-container .container {
    max-height: 280px;
    overflow: auto;
  }
}
@media (max-width: 321px) {
  .send-cheque-container .container {
    max-height: 195px;
  }
}
@media (max-width: 896px) {
  .send-cheque-container .container .payee-form-section {
    padding: 20px;
  }
}
.send-cheque-container .container .payee-form-section .payee-details .detail-one {
  display: flex;
  flex-direction: row;
  font-size: 18px;
  padding-bottom: 8px;
}
@media (max-width: 896px) {
  .send-cheque-container .container .payee-form-section .payee-details .detail-one {
    flex-direction: column;
    font-size: 14px;
  }
}
.send-cheque-container .container .payee-form-section .payee-details .detail-one label {
  width: 47%;
  text-align: right;
  margin-right: 20px;
  font-family: 'Roboto-Medium';
  color: #048041;
}
@media (max-width: 896px) {
  .send-cheque-container .container .payee-form-section .payee-details .detail-one label {
    margin: unset;
    width: 100%;
    text-align: left;
  }
}
.send-cheque-container .container .payee-form-section .payee-details .detail-one span {
  width: 40%;
  text-align: left;
  margin-left: 10px;
  font-family: 'Roboto-Regular';
}
@media (max-width: 896px) {
  .send-cheque-container .container .payee-form-section .payee-details .detail-one span {
    margin: unset;
    width: 100%;
    text-align: left;
  }
}
.send-cheque-container .container .payee-form-section .payee-details .detail-two {
  display: flex;
  flex-direction: column;
  font-size: 18px;
}
@media (max-width: 896px) {
  .send-cheque-container .container .payee-form-section .payee-details .detail-two {
    font-size: 14px;
  }
}
.send-cheque-container .container .payee-form-section .payee-details .detail-two .input-parent {
  padding-bottom: 8px;
}
.send-cheque-container .container .payee-form-section .payee-details .detail-two .input-parent .error-message {
  padding-left: 50%;
}
.send-cheque-container .container .payee-form-section .payee-details .detail-two .input-parent .input-container {
  display: grid;
  grid-template-columns: 50% 50%;
}
@media (max-width: 896px) {
  .send-cheque-container .container .payee-form-section .payee-details .detail-two .input-parent .input-container {
    display: flex;
    flex-direction: column;
    width: 94%;
  }
}
@media (max-width: 415px) {
  .send-cheque-container .container .payee-form-section .payee-details .detail-two .input-parent .input-container {
    display: flex;
    flex-direction: column;
    width: 102%;
  }
}
.send-cheque-container .container .payee-form-section .payee-details .detail-two .input-parent .input-container .button-selection-container .button-selection-list .btn-selection:hover {
  background: #048041;
  color: #FFF;
}
.send-cheque-container .container .payee-form-section .payee-details .detail-two .input-parent .input-container .button-selection-container .button-selection-list .btn-selection label input {
  position: absolute;
  top: -20px;
}
.send-cheque-container .container .payee-form-section .payee-details .detail-two .input-parent .input-container .button-selection-container .button-selection-list .btn-selection label input:checked + span {
  background-color: #048041;
  color: #fff;
}
.send-cheque-container .container .payee-form-section .payee-details .detail-two .input-parent .input-container .input-label {
  width: 90%;
  font-family: 'Roboto-Medium';
}
@media (max-width: 896px) {
  .send-cheque-container .container .payee-form-section .payee-details .detail-two .input-parent .input-container .input-label {
    width: 100%;
    font-size: 14px;
  }
}
.send-cheque-container .container .payee-form-section .payee-details .detail-two .input-parent .input-container .input-box {
  width: 70%;
}
@media (max-width: 896px) {
  .send-cheque-container .container .payee-form-section .payee-details .detail-two .input-parent .input-container .input-box {
    width: 100%;
  }
}
.send-cheque-container .container .payee-form-section .payee-details .detail-two .without-label .input-parent .input-container {
  display: flex;
  width: 100%;
}
.send-cheque-container .container .payee-form-section .payee-details .detail-two .without-label .input-parent .input-container .disable-input {
  background-color: #E3DDDA;
}
.send-cheque-container .container .payee-form-section .payee-details .detail-two .without-label .input-parent .input-container .input-box-pass {
  box-shadow: none;
  color: #1D1D1D;
}
.send-cheque-container .container .payee-form-section .payee-details .detail-two .without-label .input-parent .input-container .input-box-pass .postal-input {
  color: #1D1D1D;
}
.send-cheque-container .container .payee-form-section .payee-details .detail-two .without-label .input-parent .input-container .input-box {
  margin-left: 50%;
  width: 32%;
}
@media (max-width: 896px) {
  .send-cheque-container .container .payee-form-section .payee-details .detail-two .without-label .input-parent .input-container .input-box {
    width: 89%;
    margin: unset;
  }
}
