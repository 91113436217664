.page-loader {
  position: fixed;
  width: 100%;
  flex-direction: column;
  height: 100%;
  background: #FFF;
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  min-height: 150px;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.page-loader img {
  width: 70%;
  max-width: 300px;
  animation: spin 3s linear infinite;
  margin: 20px 0;
}
.page-loader h3 {
  margin: 10px 0;
  font-family: 'Roboto-Light';
  font-weight: normal;
  color: #98989A;
}
@media (max-width: 1000px) {
  .page-loader h3 {
    font-size: 18px;
  }
}
.page-loader h3 .highlight-text {
  color: #048041;
  font-weight: bold;
}
.ReactModal__Content--after-open {
  min-height: 200px;
}
