.edit-participant-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media (max-width: 1024px) {
  .edit-participant-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
  }
}
.edit-participant-container .section-header {
  padding-top: 1em;
  padding-bottom: 2em;
}
@media (max-width: 1024px) {
  .edit-participant-container .section-header {
    padding: 10px 0;
  }
}
.edit-participant-container .section-header .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  margin: auto;
}
@media (max-width: 1024px) {
  .edit-participant-container .section-header .section-title {
    font-size: 18px;
  }
}
.edit-participant-container .container-box {
  width: 96%;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #f2f2f2;
  padding: 2%;
  text-align: center;
  max-height: 400px;
  overflow: auto;
}
@media (max-width: 1024px) {
  .edit-participant-container .container-box {
    max-height: 320px;
    overflow: auto;
  }
}
@media (max-width: 415px) {
  .edit-participant-container .container-box {
    max-height: 300px;
    overflow: auto;
  }
}
@media (max-height: 600px) {
  .edit-participant-container .container-box {
    max-height: 250px;
    overflow: auto;
  }
}
.edit-participant-container .container-box .vertical-separator-box {
  display: flex;
  opacity: 0.5;
  flex: 8%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.edit-participant-container .container-box .vertical-separator-box .separator {
  background-color: #048041;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32%;
  height: 77%;
}
@media (max-width: 1024px) {
  .edit-participant-container .container-box .vertical-separator-box {
    display: none;
  }
}
.edit-participant-container .container-box .form-sections {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-height: 400px;
}
@media (max-width: 1024px) {
  .edit-participant-container .container-box .form-sections {
    flex-direction: column;
    max-height: unset;
    display: unset;
  }
}
@media (max-width: 896px) {
  .edit-participant-container .container-box .form-sections {
    max-height: 320px;
  }
}
@media (max-height: 600px) {
  .edit-participant-container .container-box .form-sections {
    max-height: 250px;
    overflow: hidden;
  }
}
@media (max-width: 1024px) {
  .edit-participant-container .container-box .form-sections .MuiTableContainer-root {
    max-height: 320px;
    overflow-y: scroll;
  }
}
@media (max-width: 415px) {
  .edit-participant-container .container-box .form-sections .MuiTableContainer-root {
    max-height: 290px;
  }
}
@media (max-width: 321px) {
  .edit-participant-container .container-box .form-sections .MuiTableContainer-root {
    max-height: 250px;
  }
}
.edit-participant-container .container-box .form-sections .form-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 44%;
}
@media (max-width: 1024px) {
  .edit-participant-container .container-box .form-sections .form-section {
    flex: unset;
  }
}
.edit-participant-container .container-box .form-sections .form-section .input-container {
  margin-top: 10px;
  margin-bottom: 10px;
  align-items: center;
}
.edit-participant-container .container-box .form-sections .form-section .input-container .input-box {
  width: 56% !important;
  font-size: 14px !important;
  height: 26px;
}
@media (max-width: 896px) {
  .edit-participant-container .container-box .form-sections .form-section .input-container .input-box {
    font-size: 12px !important;
  }
}
.edit-participant-container .container-box .form-sections .form-section .input-container .input-box.react-tel-input,
.edit-participant-container .container-box .form-sections .form-section .input-container .input-box.location-dropdown {
  width: 63.5% !important;
  height: 40px;
}
@media (max-width: 1024px) {
  .edit-participant-container .container-box .form-sections .form-section .input-container .input-box.react-tel-input,
  .edit-participant-container .container-box .form-sections .form-section .input-container .input-box.location-dropdown {
    width: 96% !important;
    padding: 0;
  }
}
.edit-participant-container .container-box .form-sections .form-section .input-container .input-box.enerva-dropdown {
  width: 63.5% !important;
  height: 46px;
}
@media (max-width: 1024px) {
  .edit-participant-container .container-box .form-sections .form-section .input-container .input-box.enerva-dropdown {
    width: 98% !important;
    padding: 0;
  }
}
@media (max-width: 1024px) {
  .edit-participant-container .container-box .form-sections .form-section .input-container .input-box {
    width: 92% !important;
    height: 30px;
    padding: 2%;
    margin: 2%;
  }
}
.edit-participant-container .container-box .form-sections .form-section .input-container .input-label {
  flex: 44% 0;
  padding: 0 5px;
}
@media (max-width: 1024px) {
  .edit-participant-container .container-box .form-sections .form-section .input-container .input-label {
    width: 100%;
  }
}
@media (max-width: 896px) {
  .edit-participant-container .container-box .form-sections .form-section .input-container .input-label {
    font-size: 12px;
  }
}
@media (max-width: 1024px) {
  .edit-participant-container .container-box .form-sections .form-section .input-container {
    margin-top: 5px;
  }
}
.edit-participant-container .signup-btn {
  width: 100%;
  margin-top: 25px;
  max-width: 250px;
}
@media (max-width: 1024px) {
  .edit-participant-container .signup-btn {
    max-width: unset;
  }
}
.edit-participant-container .confirm-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
@media (max-width: 1024px) {
  .edit-participant-container .confirm-btn-container {
    flex-direction: column;
    min-width: 180px;
  }
}
.edit-participant-container .confirm-btn-container .enerva-btn {
  margin: 10px 0px;
}
