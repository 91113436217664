.check-box-checked {
  background-color: #C00000;
}
h3 {
  color: #C00000;
}
.admin-dashboard-container .tabs {
  width: 100%;
}
.admin-dashboard-container .tabs .tab-list-item {
  color: #000;
}
.admin-dashboard-container .tabs .tab-list-item.tab-list-active {
  border-bottom: 2px solid #C00000;
  color: #C00000;
}
@media (max-width: 359px) {
  .admin-dashboard-container .tabs .tab-list-item {
    font-size: 14px;
    padding: 12px 20px;
  }
}
@media (max-width: 321px) {
  .admin-dashboard-container .tabs .tab-list-item {
    padding: 6px 12px;
    font-size: 10px;
  }
}
.admin-dashboard-container .enerva-btn {
  background-color: #C00000;
}
.admin-dashboard-container .enerva-btn.inversed-btn {
  background: #FFFFFF;
  border: 1px solid #C00000;
  color: #C00000;
}
.admin-dashboard-container .filter-container {
  background-color: #C00000;
}
.admin-dashboard-container .search-box .search-input:hover,
.admin-dashboard-container .search-box .search-input:focus {
  box-shadow: 0px 0px 5px #C00000;
}
.admin-dashboard-container .page-loader h3 .highlight-text {
  color: #C00000;
}
.admin-dashboard-container .enerva-dropdown__control {
  background: #C00000;
}
.admin-dashboard-container .enerva-dropdown__option--is-focused {
  background-color: rgba(142, 0, 0, 0.25);
}
.admin-dashboard-container .enerva-dropdown__option--is-selected {
  background-color: rgba(142, 0, 0, 0.25);
  color: #C00000;
}
.admin-dashboard-container .admin-dashboard h4 {
  font-family: 'Roboto-Medium';
  font-size: 16px;
  font-weight: normal;
  border-bottom: 1px solid #686C6D;
  width: fit-content;
  padding: 1em 0;
  margin: 1em 0;
}
.admin-dashboard-container .admin-dashboard h4 span {
  color: #C00000;
}
.admin-dashboard-container .admin-dashboard .admin-dashboard-top-bar {
  display: flex;
  flex-direction: row;
}
@media (max-width: 1024px) {
  .admin-dashboard-container .admin-dashboard .admin-dashboard-top-bar {
    flex-direction: column;
  }
}
.admin-dashboard-container .admin-dashboard .admin-dashboard-top-bar .admin-dashboard-search {
  max-width: 450px;
  display: flex;
  flex: auto;
}
.admin-dashboard-container .admin-dashboard .admin-dashboard-top-bar .admin-dashboard-search .search-box .search-btn {
  background: #C00000;
  border: 1px solid #C00000;
}
.admin-dashboard-container .admin-dashboard .admin-dashboard-top-bar .admin-dashboard-filter {
  margin: 0 20px 0 0;
}
.admin-dashboard-container .admin-dashboard .admin-dashboard-top-bar .add-user {
  width: 23px;
  height: 20px;
  background-color: #C00000;
  background-repeat: no-repeat;
  padding: 10px 20px;
  margin: 10px 0;
  background-position: center;
  border-radius: 4px;
  cursor: pointer;
  background-image: url('../../../images/AddUserVector.svg');
}
.admin-dashboard-container .admin-dashboard .admin-dashboard-top-bar .add-user:hover {
  opacity: 0.7;
}
.admin-dashboard-container .admin-dashboard .admin-dashboard-top-bar .add-client {
  width: 23px;
  height: 20px;
  background-color: #C00000;
  background-repeat: no-repeat;
  padding: 10px 20px;
  margin: 10px 0;
  background-position: center;
  border-radius: 4px;
  cursor: pointer;
  background-image: url('../../../images/PlusIcon.svg');
}
.admin-dashboard-container .admin-dashboard .admin-dashboard-top-bar .add-client:hover {
  opacity: 0.7;
}
.admin-dashboard-container .admin-dashboard .admin-dashboard-top-bar .add-program {
  width: 23px;
  height: 20px;
  background-color: #C00000;
  background-repeat: no-repeat;
  padding: 10px 20px;
  margin: 10px 0;
  background-position: center;
  border-radius: 4px;
  cursor: pointer;
  background-image: url('../../../images/PlusIcon.svg');
}
.admin-dashboard-container .admin-dashboard .admin-dashboard-top-bar .add-program:hover {
  opacity: 0.7;
}
.admin-panel-drawer .drawer-container .input-container {
  margin-top: 10px;
  align-items: center;
}
.admin-panel-drawer .drawer-container .input-container .input-box {
  width: 56% !important;
  font-size: 14px !important;
  height: 26px;
}
.admin-panel-drawer .drawer-container .input-container .input-box.enerva-multiselect {
  height: auto;
}
.admin-panel-drawer .drawer-container .input-container .input-box.enerva-multiselect .enerva-multiselect__placeholder {
  color: #C00000;
}
.admin-panel-drawer .drawer-container .input-container .input-box.enerva-multiselect .enerva-multiselect__indicator svg {
  color: #C00000;
}
.admin-panel-drawer .drawer-container .input-container .input-box.enerva-multiselect .enerva-dropdown__menu {
  border: #C00000;
}
.admin-panel-drawer .drawer-container .input-container .input-box.enerva-multiselect .enerva-dropdown__control {
  background: #C00000;
  border: #C00000;
}
.admin-panel-drawer .drawer-container .input-container .input-box.enerva-multiselect .enerva-multiselect__multi-value {
  background: #C00000;
}
.admin-panel-drawer .drawer-container .input-container .input-box.react-tel-input,
.admin-panel-drawer .drawer-container .input-container .input-box.location-dropdown {
  width: 61.5% !important;
  height: 40px;
}
@media (max-width: 1024px) {
  .admin-panel-drawer .drawer-container .input-container .input-box.react-tel-input,
  .admin-panel-drawer .drawer-container .input-container .input-box.location-dropdown {
    width: 96% !important;
    padding: 0;
  }
}
.admin-panel-drawer .drawer-container .input-container .input-box.enerva-dropdown {
  width: 62.5% !important;
  height: 40px;
}
@media (max-width: 1024px) {
  .admin-panel-drawer .drawer-container .input-container .input-box.enerva-dropdown {
    width: 98% !important;
    padding: 0;
  }
}
.admin-panel-drawer .drawer-container .input-container .input-box .enerva-dropdown__control {
  background: #C00000;
  border: 1px solid #C00000;
}
.admin-panel-drawer .drawer-container .input-container .input-box .enerva-dropdown__option--is-focused {
  background-color: rgba(142, 0, 0, 0.25);
}
.admin-panel-drawer .drawer-container .input-container .input-box .enerva-dropdown__option--is-selected {
  background-color: rgba(142, 0, 0, 0.25);
  color: #C00000;
}
@media (max-width: 1024px) {
  .admin-panel-drawer .drawer-container .input-container .input-box {
    width: 92% !important;
    height: 30px;
    padding: 2%;
    margin: 2%;
  }
}
.admin-panel-drawer .drawer-container .input-container .input-label {
  flex: 44% 0;
  padding: 0 5px;
  color: #686C6D;
}
@media (max-width: 1024px) {
  .admin-panel-drawer .drawer-container .input-container .input-label {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .admin-panel-drawer .drawer-container .input-container {
    margin-top: 5px;
  }
}
.admin-panel-drawer .drawer-container .drawer-heading {
  color: #C00000;
  border-bottom: 1px solid #C00000;
}
