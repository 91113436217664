.add-participant-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media (max-width: 1024px) {
  .add-participant-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
  }
}
.add-participant-container .section-header {
  padding-top: 1em;
  padding-bottom: 2em;
}
@media (max-width: 1024px) {
  .add-participant-container .section-header {
    padding: 10px 0;
  }
}
.add-participant-container .section-header .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  margin: auto;
}
@media (max-width: 1024px) {
  .add-participant-container .section-header .section-title {
    font-size: 18px;
  }
}
.add-participant-container .container-box {
  width: 96%;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #CCC;
  padding: 2%;
  text-align: center;
}
@media (max-width: 1024px) {
  .add-participant-container .container-box {
    border: none;
  }
}
.add-participant-container .container-box .vertical-separator-box {
  display: flex;
  opacity: 0.5;
  flex: 2%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.add-participant-container .container-box .vertical-separator-box .separator {
  background-color: #048041;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32%;
  height: 77%;
}
@media (max-width: 1024px) {
  .add-participant-container .container-box .vertical-separator-box {
    display: none;
  }
}
.add-participant-container .container-box .form-sections {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media (max-width: 1024px) {
  .add-participant-container .container-box .form-sections {
    flex-direction: column;
  }
}
.add-participant-container .container-box .form-sections .form-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 44%;
}
@media (max-width: 1024px) {
  .add-participant-container .container-box .form-sections .form-section {
    flex: unset;
  }
}
.add-participant-container .container-box .form-sections .form-section .input-container {
  margin-top: 10px;
  align-items: center;
}
.add-participant-container .container-box .form-sections .form-section .input-container .input-box {
  width: 56% !important;
  font-size: 14px !important;
  height: 26px;
}
.add-participant-container .container-box .form-sections .form-section .input-container .input-box.react-tel-input,
.add-participant-container .container-box .form-sections .form-section .input-container .input-box.location-dropdown {
  width: 61.5% !important;
  height: 40px;
}
@media (max-width: 1024px) {
  .add-participant-container .container-box .form-sections .form-section .input-container .input-box.react-tel-input,
  .add-participant-container .container-box .form-sections .form-section .input-container .input-box.location-dropdown {
    width: 96% !important;
    padding: 0;
  }
}
.add-participant-container .container-box .form-sections .form-section .input-container .input-box.enerva-dropdown {
  width: 62.5% !important;
  height: 40px;
}
@media (max-width: 1024px) {
  .add-participant-container .container-box .form-sections .form-section .input-container .input-box.enerva-dropdown {
    width: 98% !important;
    padding: 0;
  }
}
@media (max-width: 1024px) {
  .add-participant-container .container-box .form-sections .form-section .input-container .input-box {
    width: 92% !important;
    height: 30px;
    padding: 2%;
    margin: 2%;
  }
}
.add-participant-container .container-box .form-sections .form-section .input-container .input-label {
  flex: 44% 0;
  padding: 0 5px;
}
@media (max-width: 1024px) {
  .add-participant-container .container-box .form-sections .form-section .input-container .input-label {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .add-participant-container .container-box .form-sections .form-section .input-container {
    margin-top: 5px;
  }
}
.add-participant-container .signup-btn {
  width: 100%;
  margin-top: 25px;
  max-width: 250px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  float: right;
}
@media (max-width: 1024px) {
  .add-participant-container .signup-btn {
    max-width: unset;
  }
}
.add-participant-container .confirm-btn-container {
  display: flex;
  width: 50%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .add-participant-container .confirm-btn-container {
    flex-direction: column;
    min-width: 180px;
  }
}
