.responsive-table-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #f1f1f1;
}
@media (max-width: 896px) {
  .responsive-table-container {
    border: none;
  }
}
@media (max-width: 896px) {
  .responsive-table-container .hide-on-mobile {
    display: none;
  }
}
.responsive-table-container .link-button {
  text-decoration: underline;
  color: #048041;
  cursor: pointer;
}
.responsive-table-container .responsive-table-row {
  display: flex;
  flex-direction: row;
}
@media (max-width: 896px) {
  .responsive-table-container .responsive-table-row {
    flex-direction: column;
    border: solid 1px #e3ddda;
    margin-bottom: 0.5em;
    padding: 0.2em;
  }
}
.responsive-table-container .responsive-table-row.responsive-table-header {
  background: #048041;
  color: #FFFFFF;
  min-height: 70px;
}
@media (max-width: 896px) {
  .responsive-table-container .responsive-table-row.responsive-table-header {
    display: none;
  }
}
.responsive-table-container .responsive-table-row .responsive-table-details {
  width: 33%;
  text-align: center;
  padding: 1em;
  display: flex;
  align-items: center;
  border: 1px solid #f1f1f1;
  justify-content: center;
  font-size: 16px;
  word-break: break-word;
}
@media (max-width: 896px) {
  .responsive-table-container .responsive-table-row .responsive-table-details .hide-on-mobile {
    display: none;
  }
}
.responsive-table-container .responsive-table-row .responsive-table-details label {
  display: none;
  margin-right: 1em;
  width: 100px;
  padding-top: 0.5em;
}
@media (max-width: 896px) {
  .responsive-table-container .responsive-table-row .responsive-table-details label {
    display: inline-block;
    width: 100%;
    margin-right: 0;
    font-family: 'Roboto-Medium';
  }
}
.responsive-table-container .responsive-table-row .responsive-table-details span {
  width: 100%;
  padding-top: 0.5em;
  text-align: center;
}
@media (max-width: 896px) {
  .responsive-table-container .responsive-table-row .responsive-table-details {
    width: 100%;
    font-size: 12px;
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0.4em;
    border: none;
  }
  .responsive-table-container .responsive-table-row .responsive-table-details span {
    text-align: left;
  }
}
.responsive-table-container .responsive-table-row .responsive-table-details .document-name-input {
  height: 24px;
}
.responsive-table-container .responsive-table-row .responsive-table-details .summary-action-btns {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 10px;
}
.responsive-table-container .responsive-table-row .responsive-table-details .summary-action-btns svg {
  margin: 5px;
}
.responsive-table-container .responsive-table-row .responsive-table-details .summary-action-btns svg:hover {
  cursor: pointer;
  opacity: 0.7;
}
.responsive-table-container .responsive-table-row .responsive-table-details .summary-action-btns span {
  font-size: 12px;
}
.responsive-table-container .responsive-table-row .responsive-table-details .input-container {
  justify-content: center;
}
.responsive-table-container .responsive-table-row .responsive-table-details .document-name-input {
  position: relative;
  border: 1px solid #048041;
  padding: 0.5em 1em;
  border-radius: 6px;
  font-size: 14px;
  color: #048041;
}
.responsive-table-container.document-container .responsive-table-row .responsive-table-details {
  width: 50%;
}
