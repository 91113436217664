.tab-list {
  padding-left: 0;
  color: #001737;
  display: flex;
}
.tab-list-item {
  display: flex;
  list-style: none;
  margin-bottom: -1px;
  padding: 10px 20px;
  border-bottom: 2px solid #F1EEED;
  background: #F1EEED;
  font-size: 16px;
  align-items: center;
  cursor: pointer;
  color: #146031;
  font-family: 'Roboto-Regular';
}
@media (max-width: 359px) {
  .tab-list-item {
    font-size: 12px;
    padding: 5px 8px;
  }
}
.tab-list-active {
  background-color: #FBFBFB;
  border-radius: 4px 4px 0 0;
  border-bottom: 2px solid #048041;
  color: #009639;
  font-family: 'Roboto-Medium';
}
