.incentive-payment-note {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}
.incentive-payment-note .button-set {
  float: right;
  justify-content: flex-end !important;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}
.incentive-payment-note .enerva-btn {
  width: 100%;
  margin-top: 25px;
  max-width: 300px;
}
@media (max-width: 1024px) {
  .incentive-payment-note {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
    padding-bottom: 2em;
  }
}
.incentive-payment-note .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .incentive-payment-note .section-header {
    padding: 10px 0;
  }
}
.incentive-payment-note .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: "Roboto-Medium";
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
  margin: 0;
}
@media (max-width: 1024px) {
  .incentive-payment-note .section-header .section-title {
    font-size: 16px;
  }
}
.incentive-payment-note .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.incentive-payment-note .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.incentive-payment-note .section-below-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .incentive-payment-note .section-below-header {
    padding: 10px 0;
    flex-direction: column;
  }
}
.incentive-payment-note .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: "Roboto-Medium";
  font-weight: normal;
  text-align: left;
  width: 100%;
  margin: 0;
}
@media (max-width: 1024px) {
  .incentive-payment-note .section-below-header .section-title {
    font-size: 16px;
  }
}
.incentive-payment-note .apps-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #f2f2f2;
}
.incentive-payment-note .apps-list .app {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 10px;
  align-items: center;
  padding: 0 15px;
  height: 50px;
}
.incentive-payment-note .apps-list .app .column {
  width: 33%;
  font-size: 12px;
  font-weight: 'Roboto-Light';
}
.incentive-payment-note .apps-list .app .column.right {
  text-align: right;
}
.incentive-payment-note .container-box {
  width: 94%;
  overflow-y: auto;
  box-shadow: 0px 0px 0px #5a71d01b;
  border: 0.5px solid #98989a;
  padding: 2em;
  max-height: 450px;
  text-align: center;
}
@media (max-width: 1024px) {
  .incentive-payment-note .container-box {
    width: 80%;
  }
}
.incentive-payment-note .form-inner-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
}
@media (max-width: 1024px) {
  .incentive-payment-note .form-inner-section {
    flex-direction: column;
    width: 100%;
  }
}
.incentive-payment-note .form-inner-section .form-label,
.incentive-payment-note .form-inner-section .form-element {
  width: 30%;
  text-align: left;
}
@media (max-width: 1024px) {
  .incentive-payment-note .form-inner-section .form-label,
  .incentive-payment-note .form-inner-section .form-element {
    flex-direction: column;
    width: 100%;
    padding-right: 0;
  }
}
.incentive-payment-note .form-inner-section .form-label.full,
.incentive-payment-note .form-inner-section .form-element.full {
  width: 100% !important;
}
.incentive-payment-note .form-inner-section .form-label.bold,
.incentive-payment-note .form-inner-section .form-element.bold {
  font-weight: bold;
}
@media (max-width: 896px) {
  .incentive-payment-note .ReactModalPortal .modal-content .button-set {
    flex-direction: column !important;
  }
}
.incentive-payment-note .incentives-selected-for-action-container {
  width: 100%;
  padding: 1em;
  color: #001737;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table {
  display: flex;
  flex-direction: row;
  overflow: overlay;
  border: 1px solid #f2f2f2;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-left {
  display: flex;
  flex-direction: column;
  flex: 65% 0;
  min-width: 600px;
  overflow: overlay;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-left .incentives-selected-for-action-header {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Medium';
  text-align: left;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-left .incentives-selected-for-action-header .action-detail {
  display: flex;
  align-items: center;
  padding: 0 1em;
  text-align: left;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-left .incentives-selected-for-action-header .action-detail:nth-child(1) {
  width: 33%;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-left .incentives-selected-for-action-header .action-detail:nth-child(2) {
  width: 33%;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-left .incentives-selected-for-action-header .action-detail:nth-child(3) {
  width: 33%;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-left .incentives-selected-for-action-inner-container {
  max-height: 200px;
  overflow: overlay;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-left .incentives-selected-for-action-inner-container .incentives-selected-for-action-details {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Regular';
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-left .incentives-selected-for-action-inner-container .incentives-selected-for-action-details .action-detail {
  display: flex;
  align-items: center;
  padding: 0 1em;
  text-align: left;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-left .incentives-selected-for-action-inner-container .incentives-selected-for-action-details .action-detail:nth-child(1) {
  width: 33%;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-left .incentives-selected-for-action-inner-container .incentives-selected-for-action-details .action-detail:nth-child(2) {
  width: 33%;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-left .incentives-selected-for-action-inner-container .incentives-selected-for-action-details .action-detail:nth-child(3) {
  width: 33%;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-left .incentives-selected-for-action-inner-container .incentives-selected-for-action-details .action-status span {
  margin-right: 1em;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-right {
  display: flex;
  flex: 0 1 35%;
  align-items: center;
  font-size: 14px;
  text-align: left;
  color: #001737;
  flex-direction: column;
  justify-content: center;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-table-right span {
  font-family: 'Roboto-Regular';
  padding: 1em;
}
.incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .incentives-selected-for-action-details:nth-child(even) {
  background: #f8f7f6;
}
@media (max-width: 1024px) {
  .incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table {
    padding: 1em;
    flex-direction: column;
  }
  .incentive-payment-note .incentives-selected-for-action-container .incentives-selected-for-action-table .vertical-separator-box {
    display: none;
  }
}
.incentive-payment-note .incentives-selected-for-action-container .action-mode {
  flex: 100% 0;
  margin-bottom: 20px;
}
.incentive-payment-note .incentives-selected-for-action-container .action-mode .incentives-selected-for-action-table-left {
  flex: 100% 0;
}
.incentive-payment-note .incentives-selected-for-action-container .action-mode .incentives-selected-for-action-table-left .incentives-selected-for-action-inner-container .incentives-selected-for-action-details .action-detail:nth-child(1) {
  width: 33%;
}
.incentive-payment-note .incentives-selected-for-action-container .action-mode .incentives-selected-for-action-table-left .incentives-selected-for-action-inner-container .incentives-selected-for-action-details .action-detail:nth-child(2) {
  width: 33%;
}
.incentive-payment-note .incentives-selected-for-action-container .action-mode .incentives-selected-for-action-table-left .incentives-selected-for-action-inner-container .incentives-selected-for-action-details .action-detail:nth-child(3) {
  width: 33%;
}
.incentive-payment-note .incentives-selected-for-action-container .action-mode .vertical-separator-box {
  display: none;
}
.incentive-payment-note .incentives-selected-for-action-container .action-mode .incentives-selected-for-action-table-right {
  display: none;
}
.incentive-payment-note .incentives-selected-for-action-container .action-notes-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1em 0;
}
.incentive-payment-note .incentives-selected-for-action-container .action-notes-container label {
  font-size: 14px;
  font-family: 'Roboto-Medium';
  text-align: left;
  padding: 1em 0;
}
.incentive-payment-note .incentives-selected-for-action-container .action-notes-container textarea {
  width: auto;
  border: 1px solid #f2f2f2;
  padding: 1em;
}
.incentive-payment-note .incentives-selected-for-action-container .action-notes-container textarea:focus {
  border: 1px solid #048041;
  outline: none;
}
.incentive-payment-note .incentives-selected-for-action-container .action-notes-container .action-notes-text {
  display: flex;
  width: auto;
  padding: 1em 2em;
  font-size: 12px;
  border: 1px solid #ccc;
  min-height: 50px;
  color: #048041;
  background: #f8f7f6;
  text-align: left;
}
.incentive-payment-note .action-btn-container {
  display: flex;
  justify-content: flex-end;
  margin: 1em 0 0 0;
}
.incentive-payment-note .action-btn-container button {
  margin: 10px 0 10px 20px;
}
.incentive-payment-note .action-btn-container button.edit-review {
  background-color: #fff;
  color: #048041;
}
.incentive-payment-note .green-text {
  color: #048041;
  padding: 0 !important;
}
.incentive-payment-note .confirmation-msg {
  text-align: left;
  width: 90%;
}
.incentive-payment-note .section-summary {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1.5em 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .incentive-payment-note .section-summary {
    padding: 10px 0;
    flex-direction: column;
  }
}
.incentive-payment-note .section-summary .section-block {
  padding: 0 2%;
  text-align: left;
  font-family: "Roboto-Medium";
  font-size: 16px;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1024px) {
  .incentive-payment-note .section-summary .section-block {
    width: 100%;
    margin: 10px 0;
  }
}
.incentive-payment-note .section-summary .section-block .info {
  color: #333131;
  word-break: break-word;
}
.incentive-payment-note .section-summary .section-block .label {
  color: #333131;
  word-break: break-word;
  padding-right: 15px;
}
.incentive-payment-note .section-summary .section-block .label.right {
  text-align: right;
}
@media (max-width: 1024px) {
  .incentive-payment-note .section-summary .section-block .label.right {
    text-align: left;
  }
}
.incentive-payment-note .section-summary .section-block .totals {
  cursor: not-allowed;
  background-color: #e3ddda;
  border-radius: 6px;
  padding: 10px;
  color: #535765;
  min-width: 150px;
  word-break: break-word;
}
@media (max-width: 1024px) {
  .incentive-payment-note .section-summary .section-block .totals {
    width: 50%;
  }
}
