.customer-apps-container {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 16px;
  flex: 100%;
}
.customer-apps-container .MuiTableContainer-root {
  max-height: 215px;
}
@media (max-width: 1024px) {
  .customer-apps-container .MuiTableContainer-root {
    max-height: 160px;
  }
}
@media (max-width: 1024px) {
  .customer-apps-container {
    padding: 0;
    margin: 0;
    flex: 100%;
    min-height: 360px;
  }
}
.customer-apps-container .container-box {
  width: auto;
  overflow-y: auto;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #98989A;
  padding: 0em;
  max-height: 320px;
  text-align: center;
}
@media (max-width: 1024px) {
  .customer-apps-container .container-box .MuiTableContainer-root {
    max-height: 280px;
  }
}
@media (max-width: 321px) {
  .customer-apps-container .container-box .MuiTableContainer-root {
    max-height: 250px;
  }
}
.customer-apps-container .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .customer-apps-container .section-title {
    font-size: 16px;
  }
}
.customer-apps-container .section-header {
  display: flex;
  flex-direction: revert;
  justify-content: space-around;
  padding: 1em 0;
  align-items: center;
}
@media (max-width: 1024px) {
  .customer-apps-container .section-header {
    padding: 10px 0;
  }
}
.customer-apps-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.customer-apps-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.customer-apps-container .tabs {
  width: 100%;
}
@media (max-width: 1024px) {
  .customer-apps-container .tabs {
    margin: 10px auto;
  }
}
.customer-apps-container .tabs .tab-list {
  width: 100%;
  display: flex;
}
@media (max-width: 1024px) {
  .customer-apps-container .tabs .tab-list {
    margin: 0 0 10px 0;
  }
}
.customer-apps-container .section-summary {
  display: flex;
  flex-direction: row;
  justify-content: start;
  padding: 1.5em 0;
}
@media (max-width: 1024px) {
  .customer-apps-container .section-summary {
    padding: 10px 0;
    flex-direction: column;
  }
}
.customer-apps-container .section-summary .section-block {
  padding: 0 2%;
  text-align: left;
  font-family: 'Roboto-Medium';
  font-size: 16px;
  width: 50%;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}
@media (max-width: 1024px) {
  .customer-apps-container .section-summary .section-block {
    width: 100%;
    margin: 10px 0;
  }
}
@media (max-width: 896px) {
  .customer-apps-container .section-summary .section-block {
    font-size: 12px;
  }
}
@media (max-width: 321px) {
  .customer-apps-container .section-summary .section-block {
    font-size: 10px;
  }
}
.customer-apps-container .section-summary .section-block .label {
  color: #333131;
  width: 65%;
  word-break: break-word;
  padding-right: 10px;
}
@media (max-width: 1024px) {
  .customer-apps-container .section-summary .section-block .label {
    width: 35%;
  }
}
.customer-apps-container .section-summary .section-block .label.right {
  text-align: right;
}
@media (max-width: 1024px) {
  .customer-apps-container .section-summary .section-block .label.right {
    text-align: left;
  }
}
.customer-apps-container .section-summary .section-block .totals {
  cursor: not-allowed;
  background-color: #e3ddda;
  border-radius: 6px;
  padding: 10px;
  color: #535765;
  width: 35%;
  word-break: break-word;
}
@media (max-width: 1024px) {
  .customer-apps-container .section-summary .section-block .totals {
    width: 50%;
  }
}
