.button-selection-list {
  display: flex;
  flex-direction: row;
}
.button-selection-list .btn-selection {
  margin: 4px;
  background-color: #EFEFEF;
  border-radius: 4px;
  overflow: hidden;
  position: relative;
  min-width: 70px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.button-selection-list .btn-selection:hover {
  background: #C00000;
  color: #FFF;
}
.button-selection-list .btn-selection label {
  min-width: 70px;
  height: 35px;
}
.button-selection-list .btn-selection label span {
  text-align: center;
  min-width: 70px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
}
.button-selection-list .btn-selection label input {
  position: absolute;
  top: -20px;
}
.button-selection-list .btn-selection label input:checked + span {
  background-color: #C00000;
  color: #fff;
}
