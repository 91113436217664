.appinfoblock .section-header {
  padding-top: 1em;
  padding-bottom: 2em;
}
@media (max-width: 1024px) {
  .appinfoblock .section-header {
    padding: 10px 0;
  }
}
.appinfoblock .section-header .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media (max-width: 1024px) {
  .appinfoblock .section-header .section-title {
    font-size: 16px;
  }
}
.appinfoblock .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .appinfoblock .section-header {
    padding: 10px 0;
  }
}
.appinfoblock .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media (max-width: 1024px) {
  .appinfoblock .section-header .section-title {
    font-size: 16px;
  }
}
.appinfoblock .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.appinfoblock .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.appinfoblock .section-below-header {
  display: flex;
  padding: 1em 0;
}
@media (max-width: 1024px) {
  .appinfoblock .section-below-header {
    padding: 0.5em 0;
  }
}
.appinfoblock .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .appinfoblock .section-below-header .section-title {
    font-size: 16px;
  }
}
