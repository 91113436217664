.app-review-responses {
  width: 100%;
}
.app-review-responses .no-records-found {
  text-align: center;
  padding: 1em;
  background: #F8F7F6;
}
.app-review-responses .uploaded-documents-table {
  border: 1px solid #E3DDDA;
  padding: 20px;
  width: 92%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 60vh;
}
.app-review-responses .section-header-ret {
  padding-top: 2.5em;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
  flex-direction: column;
}
@media (max-width: 896px) {
  .app-review-responses .section-header-ret {
    padding-top: 5em;
    padding-bottom: 1em;
  }
}
.app-review-responses .section-header-ret .sub-heading {
  width: 100%;
  display: flex;
  justify-content: center;
  color: #048041;
  text-transform: uppercase;
  font-family: 'Roboto-Medium';
  margin-top: 10px;
}
.app-review-responses .section-header-ret .header-btn-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.app-review-responses .section-header-ret .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  max-width: 600px;
  margin: auto;
}
@media (max-width: 896px) {
  .app-review-responses .section-header-ret .section-title {
    font-size: 24px;
  }
}
.app-review-responses .application-summary {
  margin: 1.5em;
}
.app-review-responses .application-summary .step-header-container .step-header-container-title {
  text-align: left;
  color: #048041;
  text-transform: uppercase;
  font-size: 18px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
}
.app-review-responses .application-summary .form-section {
  display: flex;
  flex-direction: row;
}
@media (max-width: 896px) {
  .app-review-responses .application-summary .form-section {
    flex-direction: column;
  }
}
.app-review-responses .application-summary .form-section .details {
  width: 41%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
@media (max-width: 896px) {
  .app-review-responses .application-summary .form-section .details {
    width: 100%;
  }
}
.app-review-responses .application-summary .form-section .details .form-inner-elements {
  display: flex;
  width: 100%;
  margin: 25px 0;
  align-items: center;
}
@media (max-width: 896px) {
  .app-review-responses .application-summary .form-section .details .form-inner-elements {
    align-items: flex-start;
    margin: 10px 0;
  }
}
.app-review-responses .application-summary .form-section .details .form-inner-elements .application-form-label {
  font-weight: bold;
  flex: 50% 0;
  text-align: left;
  font-size: 16px;
  padding: 0 2em;
}
@media (max-width: 896px) {
  .app-review-responses .application-summary .form-section .details .form-inner-elements .application-form-label {
    margin: 10px 0;
    padding: 0;
  }
}
.app-review-responses .application-summary .form-section .details .form-inner-elements .application-form-element {
  flex: 0 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.app-review-responses .application-summary .form-section .details .form-inner-elements .application-form-element .info-icon {
  margin: 0 10px;
  width: 20px;
  position: absolute;
  right: 0;
}
@media (max-width: 896px) {
  .app-review-responses .application-summary .form-section .details .form-inner-elements .application-form-element .info-icon {
    margin: 0 5px;
    position: relative;
  }
}
.app-review-responses .application-summary .form-section .details .form-inner-elements .application-form-element .input-container {
  justify-content: flex-end;
}
.app-review-responses .application-summary .form-section .details .form-inner-elements .application-form-element .error-message {
  padding: 5px 0;
}
.app-review-responses .application-summary .form-section .details .form-inner-elements .application-form-element .input-box {
  width: 240px;
  height: 24px;
  font-size: 16px;
  font-family: 'Roboto-Regular';
}
.app-review-responses .application-summary .form-section .details .form-inner-elements .application-form-element .input-box.enerva-dropdown {
  height: 44px;
}
.app-review-responses .application-summary .form-section .details .form-inner-elements .application-form-element .input-box.textarea-box {
  width: 240px;
  height: 40px;
}
.app-review-responses .application-summary .form-section .details .form-inner-elements .application-form-element .date-picker {
  max-width: 170px;
}
.app-review-responses .application-summary .form-section .pre-project-details {
  width: 95%;
}
.app-review-responses .application-summary .form-section .vertical-separator-box {
  display: flex;
  opacity: 0.5;
  flex: 8%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.app-review-responses .application-summary .form-section .vertical-separator-box .separator {
  background-color: #048041;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32%;
  height: 85%;
}
@media (max-width: 896px) {
  .app-review-responses .application-summary .form-section .vertical-separator-box {
    display: none;
  }
}
.app-review-responses .responses .summary-tab .summary-container {
  max-width: 80vw;
  padding: 1%;
}
@media (max-width: 1024px) {
  .app-review-responses .responses .summary-tab .summary-container {
    max-width: none;
  }
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-New Assigned Pre-Project Application"] {
  background-color: #00629b;
  color: #ffffff;
  padding: 5px;
  text-align: center;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-New Assigned Post-Project Application"] {
  background-color: #00629b;
  color: #ffffff;
  padding: 5px;
  text-align: center;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-QA-QC - Mandatory"] {
  background-color: #87bc40;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-QA-QC - Random Selection"] {
  background-color: #FC427B;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Peer Review Assigned"] {
  background-color: #368B85;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Peer Review Rejected"] {
  background-color: #05C3DE;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Information Request (IR)"] {
  background-color: #7A2531;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Return Application for Edits (IR)"] {
  background-color: #7A2531;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Awaiting IESO Exception Approval"] {
  background-color: #662d91;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Enerva Guidance"] {
  background-color: #4169E1;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Peer Review Pending Assignment"] {
  background-color: #fed108;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Peer Review Approved"] {
  background-color: #048041;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Application Pre-Approved"] {
  background-color: #048041;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Reject Application"] {
  background-color: #ba131a;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Cancelled Application"] {
  background-color: #7C3600;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Withdrawn Application"] {
  background-color: #A47551;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Application Rejected as Ineligible"] {
  background-color: #e87722;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Pre-Project Application Cancelled"] {
  background-color: #e87722;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Pre-Project Application Withdrawn"] {
  background-color: #e87723;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Post-Project Application Cancelled"] {
  background-color: #e87722;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="flag-Post-Project Application Withdrawn"] {
  background-color: #e87723;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="New Assigned Pre-Project Application"] {
  border: 4px #00629b solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="New Assigned Post-Project Application"] {
  border: 4px #00629b solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="QA-QC - Mandatory"] {
  border: 4px #87bc40 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="QA-QC - Random Selection"] {
  border: 4px #FC427B solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Peer Review Assigned"] {
  border: 4px #368B85 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Peer Review Rejected"] {
  border: 4px #05C3DE solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Information Request (IR)"] {
  border: 4px #7A2531 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Return Application for Edits (IR)"] {
  border: 4px #7A2531 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Awaiting IESO Exception Approval"] {
  border: 4px #662d91 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Enerva Guidance"] {
  border: 4px #4169E1 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Peer Review Pending Assignment"] {
  border: 4px #fed108 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Peer Review Approved"] {
  border: 4px #048041 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Application Pre-Approved"] {
  border: 4px #048041 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Reject Application"] {
  border: 4px #ba131a solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Cancelled Application"] {
  border: 4px #7C3600 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Withdrawn Application"] {
  border: 4px #A47551 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Application Rejected as Ineligible"] {
  border: 4px #e87722 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Pre-Project Application Cancelled"] {
  border: 4px #e87722 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Pre-Project Application Withdrawn"] {
  border: 4px #e87723 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Post-Project Application Cancelled"] {
  border: 4px #e87722 solid;
}
.app-review-responses .responses .summary-tab .summary-container div[class*="Post-Project Application Withdrawn"] {
  border: 4px #e87723 solid;
}
.app-review-responses .responses .summary-tab .summary-container .container-head {
  display: flex;
}
.app-review-responses .responses .summary-tab .summary-container .container-head .section-title {
  font-size: 18px;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info {
  border: 1px solid #f0edeb;
  padding: 10px;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .q-header {
  text-transform: uppercase;
  font-size: 18px;
  color: #048041;
  padding-top: 10px;
  padding-bottom: 10px;
  font-family: 'Roboto-Medium';
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .q-header .show-details {
  text-decoration: underline;
  cursor: pointer;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .question-ans {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .question-ans .question {
  font-size: 16px;
  color: #686c78;
  flex: 0 70%;
  margin-right: 10%;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .question-ans .ans {
  font-size: 16px;
  color: #686c78;
  flex: 0 20%;
  display: flex;
  flex-direction: column;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .question-ans .ans .turncate-text {
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .question-ans .ans .expand {
  color: #00629b;
  text-decoration: underline;
  cursor: pointer;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .question-ans:nth-child(even) {
  background-color: #f4f3f3;
  padding: 5px;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .q-header {
  text-transform: uppercase;
  font-size: 18px;
  color: #048041;
  padding-top: 30px;
  padding-bottom: 10px;
  font-family: 'Roboto-Medium';
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .q-header .show-details {
  text-decoration: underline;
  cursor: pointer;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .question-ans {
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .question-ans .question {
  font-size: 16px;
  color: #686c78;
  flex: 0 70%;
  margin-right: 10%;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .question-ans .ans {
  font-size: 16px;
  color: #686c78;
  flex: 0 20%;
  display: flex;
  flex-direction: column;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .question-ans .ans .view-file-btn {
  border: 1px solid black;
  padding: 5px;
  width: fit-content;
  cursor: pointer;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .question-ans .ans .turncate-text {
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .question-ans .ans .expand {
  color: #00629b;
  text-decoration: underline;
  cursor: pointer;
}
.app-review-responses .responses .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .question-ans:nth-child(even) {
  background-color: #f4f3f3;
  padding: 5px;
}
