.participant-company-details-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
@media (max-width: 1024px) {
  .participant-company-details-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
  }
}
.participant-company-details-container .section-header {
  padding-top: 1em;
  padding-bottom: 2em;
}
@media (max-width: 1024px) {
  .participant-company-details-container .section-header {
    padding: 10px 0;
  }
}
.participant-company-details-container .section-header .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  margin: auto;
}
@media (max-width: 1024px) {
  .participant-company-details-container .section-header .section-title {
    font-size: 18px;
  }
}
.participant-company-details-container .container-box {
  width: 96%;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #CCC;
  padding: 2%;
  text-align: center;
}
@media (max-width: 896px) {
  .participant-company-details-container .container-box {
    max-height: 330px;
    overflow-y: auto;
  }
}
@media (max-width: 415px) {
  .participant-company-details-container .container-box {
    max-height: 290px;
  }
}
.participant-company-details-container .container-box .form-sections {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
@media (max-width: 1024px) {
  .participant-company-details-container .container-box .form-sections {
    flex-direction: column;
  }
}
.participant-company-details-container .container-box .form-sections .row-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  padding: 1em;
}
@media (max-width: 896px) {
  .participant-company-details-container .container-box .form-sections .row-section {
    flex-direction: column;
    font-size: 12px;
  }
}
.participant-company-details-container .container-box .form-sections .row-section .section-title {
  font-size: 18px;
  font-family: 'Roboto-Bold';
  color: #1D1D1D;
  text-transform: capitalize;
}
.participant-company-details-container .container-box .form-sections .row-section .section-subtitle {
  font-size: 16px;
  font-family: 'Roboto-Regular';
  color: #535765;
  text-transform: capitalize;
}
@media (max-width: 415px) {
  .participant-company-details-container .container-box .form-sections .row-section .section-subtitle {
    font-size: 12px;
  }
}
.participant-company-details-container .container-box .form-sections .row-section .section-left {
  width: 22%;
  text-align: left;
  padding-right: 1em;
}
@media (max-width: 896px) {
  .participant-company-details-container .container-box .form-sections .row-section .section-left {
    width: 100%;
    padding-right: 0px;
  }
}
.participant-company-details-container .container-box .form-sections .row-section .section-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
}
@media (max-width: 896px) {
  .participant-company-details-container .container-box .form-sections .row-section .section-right {
    width: 100%;
    padding-top: 1em;
  }
}
.participant-company-details-container .container-box .form-sections .row-section .section-right .option {
  background-color: #e3ddda;
  border-radius: 6px;
  padding: 0.5em;
  margin: 2px;
}
@media (max-width: 896px) {
  .participant-company-details-container .container-box .form-sections .first-row {
    display: none;
  }
}
.participant-company-details-container .signup-btn {
  width: 100%;
  margin-top: 25px;
  max-width: 250px;
}
@media (max-width: 1024px) {
  .participant-company-details-container .signup-btn {
    max-width: unset;
  }
}
.participant-company-details-container .confirm-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
@media (max-width: 1024px) {
  .participant-company-details-container .confirm-btn-container {
    flex-direction: column;
    min-width: 180px;
  }
}
.participant-company-details-container .confirm-btn-container .enerva-btn {
  margin-right: 0px;
}
