.appstate-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: hidden;
  overflow-x: none;
}
.appstate-container .MuiTableCell-body p {
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}
@media (max-width: 1024px) {
  .appstate-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
    padding-bottom: 2em;
  }
}
.appstate-container .button-set {
  width: 100%;
  padding-top: 22px;
}
.appstate-container .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .appstate-container .section-header {
    padding: 10px 0;
  }
}
.appstate-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media (max-width: 1024px) {
  .appstate-container .section-header .section-title {
    font-size: 16px;
  }
}
.appstate-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  font-family: 'Roboto-Medium';
  word-break: break-word;
  padding: 0 2%;
}
.appstate-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.appstate-container .header-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media (max-width: 896px) {
  .appstate-container .header-container {
    flex-direction: column;
  }
}
.appstate-container .header-container .section-below-header {
  display: flex;
  padding-top: 0.5em;
  padding-bottom: 1em;
  width: 50%;
  align-items: center;
}
@media (max-width: 896px) {
  .appstate-container .header-container .section-below-header {
    flex-direction: row;
    width: 100%;
    align-items: center;
  }
}
@media (max-width: 321px) {
  .appstate-container .header-container .section-below-header .section-title {
    font-size: 14px !important;
  }
}
.appstate-container .header-container .section-below-header .current-app-state {
  width: 50%;
  display: flex;
}
@media (max-width: 896px) {
  .appstate-container .header-container .section-below-header .current-app-state {
    width: 45%;
  }
}
.appstate-container .header-container .section-below-header .current-app-state .active-flag {
  background-image: url(../../../../images/AppStateActive.svg);
  background-repeat: no-repeat;
  width: 10%;
  height: 20px;
  background-position: center;
  margin: 23px 0px;
}
@media (max-width: 896px) {
  .appstate-container .header-container .section-below-header .current-app-state .active-flag {
    width: 12%;
    margin: 16px 0;
    background-size: 10px;
  }
}
.appstate-container .header-container .section-below-header .current-app-state .inactive-flag {
  background-image: url(../../../../images/StateVector.svg);
  background-repeat: no-repeat;
  background-position: center;
  width: 10%;
  height: 20px;
  margin: 23px 0px;
  background-size: 20px;
  background-position-x: 0;
}
@media (max-width: 896px) {
  .appstate-container .header-container .section-below-header .current-app-state .inactive-flag {
    width: 12%;
    margin: 16px 0;
    background-size: 15px;
  }
}
.appstate-container .header-container .section-below-header .current-app-state .currstate {
  margin: 25px 0px;
  font-size: 18px;
  color: #048041;
  width: 48%;
  text-align: start;
}
@media (max-width: 896px) {
  .appstate-container .header-container .section-below-header .current-app-state .currstate {
    width: 45%;
    margin: 16px 0;
    font-size: 16px;
  }
}
.appstate-container .header-container .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 50%;
}
@media (max-width: 1210px) {
  .appstate-container .header-container .section-below-header .section-title {
    width: 70%;
  }
}
@media (max-width: 896px) {
  .appstate-container .header-container .section-below-header .section-title {
    font-size: 18px;
    width: unset;
  }
}
@media (max-width: 450px) {
  .appstate-container .header-container .section-below-header .section-title {
    font-size: 18px;
    width: 60%;
  }
}
.appstate-container .header-container .state-change-btn {
  width: 50%;
  text-align: right;
  align-self: center;
}
@media (max-width: 896px) {
  .appstate-container .header-container .state-change-btn {
    width: 100%;
  }
}
.appstate-container .header-container .state-change-btn .change-btn {
  width: 220px;
  margin: 0;
}
@media (max-width: 896px) {
  .appstate-container .header-container .state-change-btn .change-btn {
    width: 100%;
    margin: 0px 0px 20px 0px;
  }
}
.appstate-container .logs-container-box {
  border: 0.5px solid #f2f2f2;
  width: 99%;
}
@media (max-width: 321px) {
  .appstate-container .logs-container-box .MuiTableContainer-root {
    max-height: 300px;
  }
}
.appstate-container .logs-container-box .empty-summary-container {
  margin: 0px;
  width: 100%;
  padding: 0;
}
@media (max-width: 896px) {
  .appstate-container .logs-container-box .empty-summary-container {
    font-size: 14px;
  }
}
.appstate-container .container-box {
  border: 0.5px solid #f2f2f2;
  width: 99%;
}
@media (max-width: 321px) {
  .appstate-container .container-box {
    max-height: 210px;
    overflow-y: auto;
    padding: 10px;
    width: 97%;
  }
  .appstate-container .container-box .MuiTableContainer-root {
    max-height: 300px;
  }
}
.appstate-container .container-box .empty-summary-container {
  margin: 0px;
  width: 100%;
  padding: 0;
}
@media (max-width: 896px) {
  .appstate-container .container-box .empty-summary-container {
    font-size: 14px;
  }
}
.appstate-container .container-box span {
  color: #048041;
}
.appstate-container .container-box .details p {
  font-size: 16px;
  text-align: left;
}
@media (max-width: 1024px) {
  .appstate-container .container-box .details p {
    text-align: center;
  }
}
.appstate-container .container-box .note-section {
  text-align: left;
}
@media (max-width: 415px) {
  .appstate-container .container-box .note-section .note-label {
    margin-left: 2px;
  }
}
