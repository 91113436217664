.flag-apps-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.flag-apps-container .back-button {
  width: 100%;
  text-align: left;
  cursor: pointer;
  appearance: none;
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-family: 'Roboto-Regular';
}
.flag-apps-container .back-button img {
  padding-right: 5px;
  width: 6px;
}
.flag-apps-container .button-block {
  display: flex;
  padding: 1em 0;
  width: 100%;
  flex-direction: column;
}
@media (max-width: 1024px) {
  .flag-apps-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
    padding-bottom: 2em;
  }
}
.flag-apps-container .content-container {
  width: 99%;
}
@media (max-width: 415px) {
  .flag-apps-container .content-container {
    overflow: hidden;
  }
}
.flag-apps-container .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .flag-apps-container .section-header {
    padding: 10px 0;
  }
}
.flag-apps-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media (max-width: 1024px) {
  .flag-apps-container .section-header .section-title {
    font-size: 16px;
  }
}
.flag-apps-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.flag-apps-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.flag-apps-container .section-below-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .flag-apps-container .section-below-header {
    padding: 10px 0;
    padding-top: 50px;
    flex-direction: column;
  }
}
.flag-apps-container .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .flag-apps-container .section-below-header .section-title {
    font-size: 16px;
  }
}
.flag-apps-container .container-box {
  width: 94%;
  overflow-y: auto;
  box-shadow: 0px 0px 0px #5a71d01b;
  border: 0.5px solid #f2f2f2;
  padding: 2em;
  max-height: 450px;
  text-align: center;
}
@media (max-width: 1024px) {
  .flag-apps-container .container-box {
    width: 92%;
  }
}
@media (max-width: 896px) {
  .flag-apps-container .container-box {
    max-height: 300px;
  }
}
@media (max-width: 415px) {
  .flag-apps-container .container-box {
    width: 80%;
    max-height: 250px;
    overflow: auto;
  }
}
@media (max-width: 321px) {
  .flag-apps-container .container-box {
    max-height: 160px;
  }
}
.flag-apps-container .new-form-sections {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1024px) {
  .flag-apps-container .new-form-sections {
    flex-direction: column;
  }
}
.flag-apps-container .new-form-sections .form-inner-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 1024px) {
  .flag-apps-container .new-form-sections .form-inner-section {
    flex-direction: column;
    width: 100%;
  }
}
.flag-apps-container .new-form-sections .form-inner-section .bold {
  margin: 20px 0 0 0;
}
.flag-apps-container .new-form-sections .form-label.info-text {
  border-left: 2px solid #048041;
  text-align: left;
  padding: 0 2em;
}
@media (max-width: 896px) {
  .flag-apps-container .new-form-sections .form-label.info-text {
    padding-top: 0.5em;
  }
}
.flag-apps-container .new-form-sections .form-label,
.flag-apps-container .new-form-sections .form-element {
  width: 30%;
  padding-right: 25px;
  text-align: left;
}
@media (max-width: 1024px) {
  .flag-apps-container .new-form-sections .form-label,
  .flag-apps-container .new-form-sections .form-element {
    flex-direction: column;
    width: 100%;
    padding-right: 0;
  }
}
.flag-apps-container .new-form-sections .form-label.full,
.flag-apps-container .new-form-sections .form-element.full {
  width: 100% !important;
}
.flag-apps-container .new-form-sections .form-label.bold,
.flag-apps-container .new-form-sections .form-element.bold {
  font-weight: bold;
}
@media (max-width: 896px) {
  .flag-apps-container .ReactModalPortal .modal-content .button-set {
    flex-direction: column !important;
  }
}
