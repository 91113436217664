/*******************************/
/********** Header CSS *********/
/*******************************/
.program-header {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: row;
  min-height: 80px;
}
.program-header .program-header-container {
  margin: 0 auto;
  width: calc(100vw - 20px);
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: fixed;
  box-shadow: 5px 5px 10px #CCCCB8;
  background: #FFF;
  z-index: 9;
}
@media (max-width: 1024px) {
  .program-header .program-header-container {
    width: calc(100% - 80px);
    padding-left: 80px;
  }
}
@media (max-width: 1000px) {
  .program-header .program-header-container {
    width: calc(100% - 55px);
    padding-left: 55px;
  }
}
.program-header .program-header-container .esb-logo {
  padding: 10px 0;
  display: flex;
  width: 184px;
  height: 59px;
  margin: 0;
  background-size: 184px;
  background-repeat: no-repeat;
  background-image: url('../../../images/ESBLogoVector.svg');
  background-position: center;
}
@media (max-width: 1000px) {
  .program-header .program-header-container .esb-logo {
    background-size: 85px;
    width: 25px;
    background-position: left;
    margin-left: 15px;
  }
}
.program-header .program-header-container .retrofit-logo {
  display: flex;
  width: 170px;
  height: 79px;
  margin: 0;
  background-size: 130px;
  background-repeat: no-repeat;
  background-image: url('../../../images/RetrofitIESOLogo.svg');
  background-position: center;
}
@media (max-width: 1000px) {
  .program-header .program-header-container .retrofit-logo {
    background-size: 85px;
    width: 90px;
    background-position: left;
    margin-left: 15px;
  }
}
@media (max-width: 600px) {
  .program-header .program-header-container .retrofit-logo {
    background-size: 50px;
  }
}
.program-header .program-header-container .speed-logo {
  display: flex;
  width: 330px;
  height: 79px;
  margin: 0;
  background-size: 330px;
  background-repeat: no-repeat;
  background-image: url('../../../images/SpeedLogo.svg');
  background-position: center;
}
@media (max-width: 1000px) {
  .program-header .program-header-container .speed-logo {
    background-size: 100%;
    width: 250px;
    background-position: left;
    margin-left: 15px;
  }
}
@media (max-width: 600px) {
  .program-header .program-header-container .speed-logo {
    background-size: 100%;
    width: 172px;
    height: 79px;
  }
}
.program-header .program-header-container .speed-dashboard-label {
  font-size: 24px;
  margin: 0 20px;
  color: #048041;
}
@media (max-width: 1000px) {
  .program-header .program-header-container .speed-dashboard-label {
    font-size: 14px;
    margin: 0 5px;
  }
}
@media (max-width: 600px) {
  .program-header .program-header-container .speed-dashboard-label {
    display: none ;
  }
}
.program-header .program-header-container .dashboard-label {
  font-size: 24px;
  margin: 0 20px;
  color: #048041;
}
@media (max-width: 1000px) {
  .program-header .program-header-container .dashboard-label {
    font-size: 14px;
    margin: 0 5px;
  }
}
.program-header .program-header-container .program-profile {
  display: flex;
  flex: 10%;
  justify-content: flex-end;
  padding: 0 20px;
}
@media (max-width: 1000px) {
  .program-header .program-header-container .program-profile {
    flex: none;
    justify-content: flex-end;
    padding: 0 10px;
    flex: 35%;
  }
}
.program-header .program-header-container .program-profile .profile-box {
  display: flex;
  max-width: 400px;
  padding: 0.4em 0.8em;
  background-color: rgba(227, 221, 218, 0.5);
  border-radius: 5px;
  position: relative;
  flex-direction: column;
  cursor: pointer;
}
@media (max-width: 1000px) {
  .program-header .program-header-container .program-profile .profile-box {
    width: 70px;
  }
}
.program-header .program-header-container .program-profile .profile-box .pic-and-name {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.program-header .program-header-container .program-profile .profile-box .pic-and-name .profile-image {
  background: #048041;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 16px;
  margin-right: 10px;
}
.program-header .program-header-container .program-profile .profile-box .pic-and-name .profile-details {
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 0;
  color: #048041;
  font-size: 14px;
}
@media (max-width: 1000px) {
  .program-header .program-header-container .program-profile .profile-box .pic-and-name .profile-details {
    display: none;
  }
}
.program-header .program-header-container .program-profile .profile-box .pic-and-name .profile-details .profile-name {
  width: 100px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.program-header .program-header-container .program-profile .profile-box .expand-profile-menu {
  display: none;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 53px;
  background: #f1eeec;
  width: 194px;
  left: 0;
  border: 1px solid #f1eeec;
  text-align: right;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 10px 10px 0px #CCCCB8;
  color: #001737;
  z-index: 1;
}
@media (max-width: 1000px) {
  .program-header .program-header-container .program-profile .profile-box .expand-profile-menu {
    width: 90px;
    top: 48px;
  }
}
.program-header .program-header-container .program-profile .profile-box .expand-profile-menu.profile-menu-open {
  display: block;
}
.program-header .program-header-container .program-profile .profile-box .expand-profile-menu .expanded-menu-panel div {
  padding: 0.5em 2em;
  font-size: 16px;
  border-top: 1px solid #e6e5e5;
}
@media (max-width: 1000px) {
  .program-header .program-header-container .program-profile .profile-box .expand-profile-menu .expanded-menu-panel div {
    padding: 0.5em 1em;
  }
}
.program-header .program-header-container .program-profile .profile-box .expand-profile-menu .expanded-menu-panel div:hover {
  opacity: 0.7;
}
