.change-req-logs-container {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 16px;
  flex: 100%;
  overflow: hidden;
}
@media (max-width: 1024px) {
  .change-req-logs-container {
    padding: 0;
    margin: 0;
    flex: 100%;
    min-height: 360px;
  }
}
.change-req-logs-container .back-button {
  width: 100%;
  text-align: left;
  cursor: pointer;
  appearance: none;
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-family: 'Roboto-Regular';
}
.change-req-logs-container .back-button img {
  padding-right: 5px;
  width: 6px;
}
.change-req-logs-container .content-container {
  width: 100%;
  border: 0.5px solid #f2f2f2;
}
@media (max-width: 321px) {
  .change-req-logs-container .content-container .MuiTableContainer-root {
    max-height: 345px;
  }
}
.change-req-logs-container .MuiTableCell-body p {
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}
.change-req-logs-container .container-box {
  width: auto;
  overflow-y: auto;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #f2f2f2;
  padding: 0em;
  max-height: 450px;
  text-align: center;
}
.change-req-logs-container .input-parent .date-picker {
  flex: 0 60%;
  border-bottom: 0px solid #048041;
}
.change-req-logs-container .input-parent .date-picker svg {
  fill: #048041;
}
.change-req-logs-container .form-inner-section {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  align-items: center;
}
@media (max-width: 896px) {
  .change-req-logs-container .form-inner-section {
    width: 100%;
  }
}
.change-req-logs-container .form-inner-section .section-header {
  width: 100%;
}
.change-req-logs-container .form-inner-section .step-header-title {
  width: 100%;
}
.change-req-logs-container .form-inner-section .form-inner-elements {
  display: flex;
  width: 100%;
  margin: 1em 0;
  align-items: center;
}
@media (max-width: 896px) {
  .change-req-logs-container .form-inner-section .form-inner-elements {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
  }
}
.change-req-logs-container .form-inner-section .form-inner-elements .application-form-label {
  flex: 50% 0;
  text-align: left;
  font-size: 16px;
  padding: 0 2em;
}
@media (max-width: 896px) {
  .change-req-logs-container .form-inner-section .form-inner-elements .application-form-label {
    margin: 10px 0;
    padding: 0;
  }
}
.change-req-logs-container .form-inner-section .form-inner-elements .application-form-element {
  flex: 0 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.change-req-logs-container .form-inner-section .form-inner-elements .application-form-element .info-icon {
  margin: 0 10px;
  width: 20px;
  position: absolute;
  right: 0;
}
@media (max-width: 896px) {
  .change-req-logs-container .form-inner-section .form-inner-elements .application-form-element .info-icon {
    margin: 0 5px;
    position: relative;
  }
}
.change-req-logs-container .form-inner-section .form-inner-elements .application-form-element .input-container {
  justify-content: flex-end;
}
.change-req-logs-container .form-inner-section .form-inner-elements .application-form-element .error-message {
  padding: 5px 0;
}
.change-req-logs-container .form-inner-section .form-inner-elements .application-form-element .input-box {
  width: 240px;
  height: 24px;
  font-size: 16px;
  font-family: 'Roboto-Regular';
}
.change-req-logs-container .form-inner-section .form-inner-elements .application-form-element .input-box.enerva-dropdown {
  height: 44px;
}
.change-req-logs-container .form-inner-section .form-inner-elements .application-form-element .input-box.textarea-box {
  width: 240px;
  height: 40px;
}
.change-req-logs-container .form-inner-section .form-inner-elements .application-form-element .date-picker {
  max-width: 170px;
}
.change-req-logs-container .form-inner-section .form-note-container {
  font-size: 16px;
  text-align: left;
  padding: 0;
  margin: 2em 0;
  line-height: 24px;
  width: 100%;
}
.change-req-logs-container .form-inner-section .form-note-container b {
  color: #048041;
}
.change-req-logs-container .form-inner-section .form-note-container ol {
  padding: 1em;
  margin: 0;
}
.change-req-logs-container .form-inner-section .empty-summary-container {
  display: flex;
  height: 240px;
  background: #E3DDDA;
  width: auto;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0 5em;
  text-align: left;
  margin: 1em 0;
  font-family: 'Roboto-Light';
  width: 80%;
}
@media (max-width: 896px) {
  .change-req-logs-container .form-inner-section .empty-summary-container {
    padding: 10px;
    width: 95%;
  }
}
.change-req-logs-container .form-inner-section .horizontal-bar {
  height: 0px;
  border-bottom: 1px solid #E3DDDA;
  margin: 3em 0;
  width: 50%;
}
.change-req-logs-container .form-inner-section.documents-section .input-parent {
  width: 100%;
}
.change-req-logs-container .form-inner-section.documents-section .input-parent .file-upload-container {
  width: 100%;
}
@media (max-width: 896px) {
  .change-req-logs-container .form-inner-section.documents-section .input-parent .file-upload-container {
    width: auto;
  }
}
.change-req-logs-container .form-inner-section.documents-section .empty-summary-container {
  background: none;
  border: 1px solid #E3DDDA;
}
.change-req-logs-container .form-inner-section.documents-section .summary-items-row .summary-item-details {
  width: 50%;
}
.change-req-logs-container .form-inner-section.documents-section b {
  color: #048041;
}
.change-req-logs-container .form-inner-section .summary-list-container {
  display: flex;
  width: 96%;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin: 1em 0;
  font-size: 16px;
}
.change-req-logs-container .form-inner-section .summary-list-container .list-heading {
  font-weight: bold;
}
.change-req-logs-container .form-inner-section .summary-list-container ol li {
  padding: 0.5em 1em;
  line-height: 24px;
}
.change-req-logs-container .form-inner-section .summary-list-container ol li::marker {
  font-weight: bold;
}
.change-req-logs-container .form-inner-section .summary-list-container ol li span {
  color: #048041;
  font-weight: bold;
  text-decoration: underline;
}
.change-req-logs-container .form-inner-section .terms-agree-check {
  display: flex;
  font-size: 16px;
  width: 92%;
  align-items: flex-end;
  color: #048041;
}
.change-req-logs-container .form-inner-section .terms-agree-check span {
  margin-right: 10px;
}
.change-req-logs-container .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .change-req-logs-container .section-header {
    padding: 10px 0;
  }
}
.change-req-logs-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media (max-width: 1024px) {
  .change-req-logs-container .section-header .section-title {
    font-size: 16px;
  }
}
.change-req-logs-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.change-req-logs-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.change-req-logs-container .section-below-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .change-req-logs-container .section-below-header {
    padding: 10px 0;
    padding-top: 50px;
    flex-direction: column;
  }
}
.change-req-logs-container .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .change-req-logs-container .section-below-header .section-title {
    font-size: 16px;
  }
}
.change-req-logs-container .section-below-header .review-status-selection {
  flex: 0 72%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .change-req-logs-container .section-below-header .review-status-selection {
    justify-content: flex-start;
  }
}
.change-req-logs-container .section-below-header .review-status-selection label {
  color: #686C6D;
}
.change-req-logs-container .section-below-header .review-status-selection .input-container {
  width: 400px;
}
@media (max-width: 1024px) {
  .change-req-logs-container .section-below-header .review-status-selection .input-container {
    margin: 0;
    width: 300px;
  }
}
.change-req-logs-container .section-below-header .review-status-selection .input-container .enerva-dropdown__control {
  font-size: 14px;
}
.change-req-logs-container .section-below-header .review-status-selection .input-container .enerva-dropdown__control .enerva-dropdown__single-value {
  font-size: 14px;
}
.change-req-logs-container .section-below-header .review-status-selection .input-container .enerva-dropdown__menu {
  font-size: 14px;
}
.change-req-logs-container .section-below-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
}
.change-req-logs-container .section-below-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
