.program-filter .check-box-checked {
  background-color: #048041 !important;
}
.fiar-tab {
  padding: 20px 20px;
  color: #001737;
  border: 1px solid #f2f2f2;
}
@media (max-width: 1024px) {
  .fiar-tab {
    padding: 1em;
  }
}
.fiar-tab .fiar-top-bar {
  display: flex;
  flex-direction: row;
  flex: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .fiar-tab .fiar-top-bar {
    flex-direction: row;
    padding-bottom: 1em;
  }
}
@media (max-width: 768px) {
  .fiar-tab .fiar-top-bar {
    flex-direction: column;
    padding-bottom: 1em;
  }
}
.fiar-tab .fiar-top-bar .fiar-search {
  max-width: 500px;
  display: flex;
  flex-direction: row;
  flex: 60% 0;
}
@media (max-width: 896px) {
  .fiar-tab .fiar-top-bar .fiar-search {
    max-width: unset;
    width: 100%;
  }
}
.fiar-tab .fiar-top-bar .fiar-search .search-box {
  width: 100%;
}
.fiar-tab .fiar-top-bar .enerva-react-date-picker-container {
  width: 100%;
}
.fiar-tab .fiar-top-bar .fiar-filter {
  display: flex;
  flex: 0 40%;
  max-width: 240px;
  margin: 0 20px 0 0;
  position: relative;
}
@media (max-width: 1024px) {
  .fiar-tab .fiar-top-bar .fiar-filter {
    max-width: 420px;
    margin: auto;
    width: 100%;
    justify-content: center;
  }
}
@media (max-width: 680px) {
  .fiar-tab .fiar-top-bar .fiar-filter {
    max-width: unset;
    width: 100%;
  }
}
.fiar-tab .fiar-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(360px, 1fr));
  grid-gap: 3em;
  margin: 15px 0;
}
@media (max-width: 950px) {
  .fiar-tab .fiar-card-container {
    display: flex;
    flex-direction: column;
    grid-gap: 1em;
  }
}
.fiar-tab .fiar-card-container .card {
  width: auto;
  font-size: 16px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-width: 500px;
  border-radius: 8px;
  box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);
}
@media (max-width: 950px) {
  .fiar-tab .fiar-card-container .card {
    margin: 1em 0;
    width: 90vw;
    max-width: 100%;
  }
}
.fiar-tab .fiar-card-container .card .header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  height: 60px;
  padding: 0 15px;
}
.fiar-tab .fiar-card-container .card .header .ipn-details {
  width: 80%;
  display: flex;
  flex-direction: COLUMN;
  justify-content: flex-start;
}
.fiar-tab .fiar-card-container .card .header .ipn-details .ipnno {
  color: #000;
  font-weight: 'Roboto-Medium';
  font-size: 16px;
  display: flex;
  align-items: center;
  padding: 5px 0;
}
.fiar-tab .fiar-card-container .card .header .ipn-details .ipnno .status {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  margin-left: 20px;
  background: #E3DDDA;
}
.fiar-tab .fiar-card-container .card .header .ipn-details .ipnno .status.pending {
  background: #E3DDDA;
}
.fiar-tab .fiar-card-container .card .header .ipn-details .ipnno .status.generated {
  background: #535765;
}
.fiar-tab .fiar-card-container .card .header .ipn-details .ipnno .status.received {
  background: #fed108;
}
.fiar-tab .fiar-card-container .card .header .ipn-details .ipnno .status.spot-check-completed {
  background: #FFA500;
}
.fiar-tab .fiar-card-container .card .header .ipn-details .ipnno .status.pvrr-approved {
  background: #66e100;
}
.fiar-tab .fiar-card-container .card .header .ipn-details .ipnno .status.transfered {
  background: #c5e86c;
}
.fiar-tab .fiar-card-container .card .header .ipn-details .ipnno .status.initiated {
  background: #146031;
}
.fiar-tab .fiar-card-container .card .header .ipn-details .info {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  font-weight: 'Roboto-Regular';
  font-size: 12px;
  padding: 5px 0;
}
.fiar-tab .fiar-card-container .card .header .ipn-details .info .date {
  border-right: 1px solid #048041;
  width: 30%;
}
@media (max-width: 480px) {
  .fiar-tab .fiar-card-container .card .header .ipn-details .info .date {
    padding-right: 10px;
  }
}
.fiar-tab .fiar-card-container .card .header .ipn-details .info .creator {
  width: 70%;
  padding: 0 15px;
}
.fiar-tab .fiar-card-container .card .header .ipn-action {
  cursor: pointer;
  width: 20%;
  display: flex;
  background-size: 18px;
  height: 19px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: url('../../../../images/ExportIcon.svg');
  position: relative;
}
.fiar-tab .fiar-card-container .card .apps-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 180px !important;
}
.fiar-tab .fiar-card-container .card .apps-list .app {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 10px;
  align-items: center;
  padding: 0 15px;
  height: 50px;
}
.fiar-tab .fiar-card-container .card .apps-list .app .column {
  width: 33%;
  font-size: 12px;
  font-weight: 'Roboto-Light';
}
.fiar-tab .fiar-card-container .card .apps-list .app .column.right {
  text-align: right;
}
.fiar-tab .fiar-card-container .card .apps-list .app .column.name {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 28%;
  padding-right: 10px;
}
.fiar-tab .fiar-card-container .card .footer {
  font-size: 12px;
  font-weight: 'Roboto-Medium';
  color: #048041;
  text-transform: uppercase;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 60px;
  padding: 0 15px;
}
.fiar-tab .fiar-card-container .card .footer .morelink {
  cursor: pointer;
}
.fiar-tab .fiar-card-container .expand-ipn-menu {
  display: none;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 125%;
  background: #f1eeec;
  border: 1px solid #f1eeec;
  text-align: right;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 10px 10px 0px #CCCCB8;
  color: #001737;
  z-index: 1;
  width: auto;
  min-width: 130px;
}
@media (max-width: 1000px) {
  .fiar-tab .fiar-card-container .expand-ipn-menu {
    width: 100%;
    left: 0px;
    min-width: unset;
  }
}
.fiar-tab .fiar-card-container .expand-ipn-menu.ipn-menu-open {
  display: block;
}
.fiar-tab .fiar-card-container .expand-ipn-menu .expanded-menu-panel div {
  padding: 1em;
  font-size: 16px;
  border-top: 1px solid #e6e5e5;
  text-align: left;
}
@media (max-width: 1000px) {
  .fiar-tab .fiar-card-container .expand-ipn-menu .expanded-menu-panel div {
    font-size: 12px;
  }
}
.fiar-tab .fiar-card-container .expand-ipn-menu .expanded-menu-panel div:hover {
  background: rgba(4, 128, 65, 0.25);
  color: #048041;
}
.fiar-tab .no-records-found {
  text-align: center;
  padding: 1em;
  background: #F8F7F6;
}
.filter-label-new {
  font-family: 'Roboto-Medium';
  color: #048041;
  font-size: 18px;
  margin: 10px 0px;
}
.applications-top-bar-filter-btn {
  display: flex;
  flex: 0 40%;
  margin: 0 20px 0 0;
  position: relative;
  width: 100%;
  max-width: 450px;
}
@media (max-width: 690px) {
  .applications-top-bar-filter-btn {
    margin: unset;
    margin-bottom: 20px;
  }
}
.applications-top-bar-filter-btn .filter-component {
  width: 100%;
}
.applications-top-bar-filter-btn .filter-btn {
  display: flex;
  width: 250px;
  justify-content: space-between;
}
@media (max-width: 896px) {
  .applications-top-bar-filter-btn .filter-btn {
    width: 100%;
    margin: 0px;
    font-family: 'Roboto-Regular';
    font-size: 16px !important;
  }
}
@media (max-width: 896px) {
  .secondRow {
    display: flex !important;
    width: 100%;
  }
}
@media (max-width: 550px) {
  .secondRow {
    display: flex !important;
    flex-direction: column  !important;
    align-items: baseline !important;
  }
}
.secondRow .filter-btn.incent-Btn {
  margin: 0px;
  margin-top: 10px;
}
