.notes-list-container {
  width: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.notes-list-container .container-box {
  width: 99%;
  box-shadow: 0px 0px 0px #5a71d01b;
  border: 0.5px solid #f2f2f2;
  text-align: center;
}
@media (max-width: 1024px) {
  .notes-list-container .container-box {
    width: 99%;
    height: 398px;
    overflow-y: auto;
  }
}
@media (max-width: 415px) {
  .notes-list-container .container-box {
    max-height: 330px;
    padding: 10px;
    width: 96%;
  }
}
@media (max-width: 321px) {
  .notes-list-container .container-box {
    max-height: 255px;
  }
}
.notes-list-container .container-box .empty-summary-container {
  width: 100%;
  margin: unset;
  padding: unset;
}
.notes-list-container .MuiTableCell-root {
  padding: 8px 15px;
}
.notes-list-container .MuiTableCell-body p {
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  padding: 0 !important;
}
.notes-list-container .section-below-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  align-content: center;
  justify-content: flex-start;
  margin: 0 !important;
}
@media (max-width: 1024px) {
  .notes-list-container .section-below-header {
    flex-direction: column;
  }
}
@media (max-width: 896px) {
  .notes-list-container .section-below-header {
    width: 98%;
  }
}
.notes-list-container .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  margin-right: 100px;
}
@media (max-width: 896px) {
  .notes-list-container .section-below-header .section-title {
    width: 100%;
    margin-right: 0;
  }
}
@media (max-width: 1024px) {
  .notes-list-container .section-below-header .section-title {
    font-size: 16px;
  }
}
.notes-list-container .section-below-header .button-set {
  width: 100%;
}
@media (max-width: 1024px) {
  .notes-list-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
    padding-bottom: 2em;
  }
}
.notes-list-container .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .notes-list-container .section-header {
    padding: 10px 0;
  }
}
.notes-list-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media (max-width: 1024px) {
  .notes-list-container .section-header .section-title {
    font-size: 16px;
  }
}
.notes-list-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.notes-list-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.notes-list-container .form-sections {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow-y: hidden;
}
@media (max-width: 1024px) {
  .notes-list-container .form-sections {
    flex-direction: column;
  }
}
.notes-list-container .form-sections .row-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 896px) {
  .notes-list-container .form-sections .row-section {
    flex-direction: column;
  }
}
.notes-list-container .form-sections .row-section:nth-child(1) {
  padding-bottom: 1em;
}
.notes-list-container .form-sections .row-section:nth-child(2n) {
  background-color: #e3ddda;
}
.notes-list-container .form-sections .row-section .section-title {
  font-size: 18px;
  font-family: 'Roboto-Bold';
  color: #1D1D1D;
  text-transform: capitalize;
}
.notes-list-container .form-sections .row-section .section-subtitle {
  font-size: 16px;
  font-family: 'Roboto-Regular';
  color: #535765;
  text-transform: capitalize;
}
.notes-list-container .form-sections .row-section .section-left {
  width: 80%;
  text-align: left;
  padding-right: 1em;
  padding-left: 0.5em;
}
@media (max-width: 896px) {
  .notes-list-container .form-sections .row-section .section-left {
    width: 100%;
    padding-right: 0px;
  }
}
.notes-list-container .form-sections .row-section .section-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
}
@media (max-width: 896px) {
  .notes-list-container .form-sections .row-section .section-right {
    width: 100%;
    padding-top: 0px;
  }
}
.notes-list-container .form-sections .row-section .section-right p {
  color: #535765;
}
@media (max-width: 896px) {
  .notes-list-container .form-sections .first-row {
    display: none;
  }
}
.notes-list-container .information-container-label {
  width: 100%;
  display: flex;
  font-weight: bold;
  padding: 5px 0px 0px 5px;
}
.notes-list-container .makeStyles-container-2 {
  max-height: 270px;
}
.notes-list-container .enerva-btn,
.notes-list-container .signup-btn {
  margin-top: 0 !important;
}
.notes-list-container .signup-btn {
  width: 100%;
  max-width: 250px;
}
@media (max-width: 1024px) {
  .notes-list-container .signup-btn {
    max-width: unset;
  }
}
.notes-list-container .confirm-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
@media (max-width: 1024px) {
  .notes-list-container .confirm-btn-container {
    flex-direction: column;
    min-width: 180px;
  }
}
.notes-list-container .confirm-btn-container .enerva-btn {
  margin-right: 0px;
}
.notes-list-container .button-set {
  float: right;
  display: flex;
  width: 100%;
  flex-direction: row ;
  justify-content: right !important;
  align-items: center;
  padding-bottom: 10px;
}
@media (max-width: 1024px) {
  .notes-list-container .button-set {
    margin-top: 0;
  }
}
@media (max-width: 896px) {
  .notes-list-container .button-set .section-title {
    margin-right: 0;
    margin-bottom: 10px;
  }
}
.notes-list-container .button-set .add-btn {
  width: 100%;
  max-width: 180px;
  text-align: left;
  margin-left: 3em;
  margin-top: 0;
  margin-bottom: 0;
}
@media (max-width: 1024px) {
  .notes-list-container .button-set .add-btn {
    max-width: unset;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 896px) {
  .notes-list-container .button-set .add-btn {
    margin-top: 10px !important;
  }
}
.notes-list-container .button-set .add-icon {
  background-image: url(../../../../images/PlusIcon.svg);
  background-repeat: no-repeat;
  background-position: 85% 50%;
  padding-right: 10px;
  position: relative;
}
@media (max-width: 1024px) {
  .notes-list-container .button-set .add-icon {
    background-position: 90% 50%;
    padding-right: 30px;
  }
}
.notes-list-container .signup-btn,
.notes-list-container .enerva-btn {
  width: 100%;
  margin-top: 25px;
  max-width: 250px;
}
@media (max-width: 1024px) {
  .notes-list-container .signup-btn,
  .notes-list-container .enerva-btn {
    max-width: unset;
  }
}
