/*******************************/
/********** Header CSS *********/
/*******************************/
.admin-sidebar-menu {
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 16%;
  z-index: 10;
}
@media (min-width: 897px) and (max-width: 1024px) {
  .admin-sidebar-menu {
    max-width: unset;
    position: fixed;
    top: 0;
    height: 80px;
    transition: width 0.5s ease-in-out;
  }
}
@media (max-width: 896px) {
  .admin-sidebar-menu {
    max-width: unset;
    width: 70%;
    position: fixed;
    top: 0;
    height: 80px;
    transition: height 0.5s ease-in-out;
  }
}
.admin-sidebar-menu.admin-sidebar-menu-close {
  width: 5%;
  max-width: 240px;
}
@media (max-width: 1024px) {
  .admin-sidebar-menu.admin-sidebar-menu-close {
    max-width: unset;
    width: 100%;
    height: 100%;
  }
}
.admin-sidebar-menu.admin-sidebar-menu-close nav {
  width: 5%;
  max-width: 240px;
}
@media (max-width: 1024px) {
  .admin-sidebar-menu.admin-sidebar-menu-close nav {
    max-width: unset;
    width: 100%;
    position: absolute;
  }
}
.admin-sidebar-menu.admin-sidebar-menu-close nav .admin-menu {
  left: 0;
}
.admin-sidebar-menu.admin-sidebar-menu-close nav .admin-menu .admin-menu-items {
  padding: 0;
  justify-content: center;
}
.admin-sidebar-menu.admin-sidebar-menu-close nav .admin-menu .admin-menu-items a {
  display: flex;
  flex-direction: row;
  flex: none;
  align-items: center;
  color: #C00000;
  padding: 15px 0;
}
@media (max-width: 1024px) {
  .admin-sidebar-menu.admin-sidebar-menu-close nav .admin-menu .admin-menu-items a {
    color: #FFF;
    flex: auto;
    padding: 15px 20px;
  }
}
@media (max-width: 1024px) {
  .admin-sidebar-menu.admin-sidebar-menu-close nav .admin-menu .admin-menu-items {
    justify-content: flex-start;
  }
}
@media (max-width: 896px) {
  .admin-sidebar-menu.admin-sidebar-menu-close nav .admin-menu .admin-menu-items {
    width: 100%;
  }
}
.admin-sidebar-menu.admin-sidebar-menu-close nav .admin-menu .admin-menu-items .right-arrow {
  flex: none;
}
@media (max-width: 1024px) {
  .admin-sidebar-menu.admin-sidebar-menu-close nav .admin-menu .admin-menu-items .right-arrow {
    color: #FFF;
    flex: auto;
  }
}
.admin-sidebar-menu.admin-sidebar-menu-close + .admin-dashboard {
  flex: 100%;
}
.admin-sidebar-menu nav {
  width: 15%;
  position: fixed;
  padding: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  top: 0;
}
@media (max-width: 1024px) {
  .admin-sidebar-menu nav {
    width: 70%;
    position: absolute;
  }
}
.admin-sidebar-menu nav .logo {
  padding: 10px 0;
  display: flex;
  width: 184px;
  height: 59px;
  margin: 0;
  background-size: 184px;
  background-repeat: no-repeat;
  background-image: url('../../../images/ESBLogoVector.svg');
  background-position: center;
}
@media (max-width: 1024px) {
  .admin-sidebar-menu nav .logo {
    background-size: 85px;
    width: 105px;
  }
}
.admin-sidebar-menu nav .admin-burger-menu {
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100px;
  min-height: 80px;
}
.admin-sidebar-menu nav .admin-burger-menu path {
  fill: #C00000;
}
@media (min-width: 1025px) {
  .admin-sidebar-menu nav .admin-burger-menu {
    display: none;
  }
}
@media (max-width: 1024px) {
  .admin-sidebar-menu nav .admin-burger-menu {
    max-width: unset;
    width: 100%;
    justify-content: flex-start;
    padding: 0 15px;
  }
}
.admin-sidebar-menu nav .admin-burger-menu.admin-burger-menu-collapsed {
  background: #C00000;
}
@media (min-width: 1024px) {
  .admin-sidebar-menu nav .admin-burger-menu.admin-burger-menu-collapsed {
    width: 100%;
    justify-content: flex-start;
    padding: 0 15px;
  }
}
.admin-sidebar-menu nav .admin-burger-menu.admin-burger-menu-collapsed svg g {
  transition: all 0.3s linear;
}
.admin-sidebar-menu nav .admin-burger-menu.admin-burger-menu-collapsed svg :nth-child(1) g {
  transform: rotate(45deg);
}
.admin-sidebar-menu nav .admin-burger-menu.admin-burger-menu-collapsed svg :nth-child(2) g {
  transform: translateX(20px);
  opacity: 0;
}
.admin-sidebar-menu nav .admin-burger-menu.admin-burger-menu-collapsed svg :nth-child(3) g {
  transform: rotate(-45deg);
}
.admin-sidebar-menu nav .admin-burger-menu.admin-burger-menu-collapsed svg path {
  fill: #FFF;
}
.admin-sidebar-menu nav .admin-burger-menu.admin-burger-menu-collapsed path {
  fill: #FFF;
}
.admin-sidebar-menu nav .dashboard-label {
  font-size: 24px;
  margin: 0 20px;
  color: #C00000;
}
.admin-sidebar-menu nav .admin-profile {
  display: flex;
  flex: 10%;
  justify-content: flex-end;
  padding: 0 20px;
}
.admin-sidebar-menu nav .profile-image {
  background: #C00000;
  width: 40px;
  height: 40px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-size: 16px;
}
.admin-sidebar-menu nav .profile-details {
  display: flex;
  flex-direction: column;
  padding: 5px 10px;
  color: #C00000;
  font-size: 14px;
}
.admin-sidebar-menu nav .expand-profile-menu {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.admin-sidebar-menu nav .admin-menu {
  margin-top: 80px;
  font-family: 'Roboto-Light';
  display: flex;
  flex-direction: column;
  color: #FFF;
  height: 100%;
  background: #C00000;
  z-index: 10;
}
@media (max-width: 1024px) {
  .admin-sidebar-menu nav .admin-menu {
    position: absolute;
    top: 80px;
    left: -100%;
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 896px) {
  .admin-sidebar-menu nav .admin-menu {
    width: 100%;
    margin: 0;
  }
}
.admin-sidebar-menu nav .admin-menu .admin-top-menu {
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  flex: 15% 0;
}
@media (max-width: 1024px) {
  .admin-sidebar-menu nav .admin-menu .admin-top-menu {
    flex: none;
  }
}
.admin-sidebar-menu nav .admin-menu .admin-bottom-menu {
  margin-bottom: 0;
  justify-content: flex-end;
  display: flex;
  flex-direction: column;
  flex: 0 85%;
  color: #FFF;
}
.admin-sidebar-menu nav .admin-menu .admin-bottom-menu .bottom-arrow {
  display: flex;
  justify-content: end;
  align-items: center;
  padding: 10px 20px;
  color: #FFF;
  fill: #FFF;
  opacity: 0.8;
  background-color: rgba(225, 225, 225, 0.3);
}
.admin-sidebar-menu nav .admin-menu .admin-bottom-menu .bottom-arrow span {
  cursor: pointer;
}
.admin-sidebar-menu nav .admin-menu .admin-bottom-menu .bottom-arrow svg path {
  stroke: #FFF;
  cursor: pointer;
}
.admin-sidebar-menu nav .admin-menu .admin-bottom-menu .bottom-arrow .right-arrow {
  transform: rotate(180deg);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 35%;
}
.admin-sidebar-menu nav .admin-menu .admin-bottom-menu .bottom-arrow .menu-items-collapsed {
  padding-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}
@media (max-width: 896px) {
  .admin-sidebar-menu nav .admin-menu .admin-bottom-menu {
    flex: none;
  }
}
.admin-sidebar-menu nav .admin-menu.admin-menu-collapsed {
  width: 100%;
  justify-content: space-between;
}
@media (min-width: 896px) {
  .admin-sidebar-menu nav .admin-menu.admin-menu-collapsed {
    margin-top: 80px;
  }
}
@media (max-width: 500px) {
  .admin-sidebar-menu nav .admin-menu.admin-menu-collapsed {
    width: 100vw;
  }
}
@media (min-width: 896px) and (max-width: 1024px) {
  .admin-sidebar-menu nav .admin-menu.admin-menu-collapsed {
    width: 100%;
    margin-top: 0;
    height: 100%;
    padding: 0;
  }
}
.admin-sidebar-menu nav .admin-menu.admin-menu-collapsed .admin-bottom-menu {
  width: 100%;
}
@media (max-width: 896px) {
  .admin-sidebar-menu nav .admin-menu.admin-menu-collapsed .admin-bottom-menu {
    width: 70%;
  }
}
.admin-sidebar-menu nav .admin-menu .admin-menu-items {
  padding: 0;
  display: flex;
  align-items: center;
  border-top: 1px solid rgba(142, 0, 0, 0.25);
  border-bottom: 1px solid rgba(142, 0, 0, 0.25);
  min-width: 50px;
  min-height: 20px;
  justify-content: flex-start;
}
.admin-sidebar-menu nav .admin-menu .admin-menu-items a {
  display: flex;
  flex-direction: row;
  flex: auto;
  align-items: center;
  color: #FFF;
  padding: 15px 20px;
}
.admin-sidebar-menu nav .admin-menu .admin-menu-items .menu-items-collapsed {
  display: none;
}
@media (max-width: 1024px) {
  .admin-sidebar-menu nav .admin-menu .admin-menu-items .menu-items-collapsed {
    display: flex;
    width: 50vh;
  }
}
.admin-sidebar-menu nav .admin-menu .admin-menu-items svg {
  padding: 0 10px 0 0;
}
.admin-sidebar-menu nav .admin-menu .admin-menu-items svg path {
  fill: #FFF;
}
.admin-sidebar-menu nav .admin-menu .admin-menu-items svg rect {
  fill: #FFF;
}
.admin-sidebar-menu nav .admin-menu .admin-menu-items .right-arrow {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  flex: auto;
}
.admin-sidebar-menu nav .admin-menu .admin-menu-items.active-menu {
  background: #FFF;
  color: #C00000;
  margin: 0;
  font-weight: bold;
}
.admin-sidebar-menu nav .admin-menu .admin-menu-items.active-menu a {
  color: #C00000;
}
.admin-sidebar-menu nav .admin-menu .admin-menu-items.active-menu svg path {
  fill: #C00000;
}
.admin-sidebar-menu nav .admin-menu .admin-menu-items.active-menu svg rect {
  fill: #C00000;
}
