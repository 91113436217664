.drawer-component {
  display: flex;
  flex-direction: row;
  flex: 100%;
  margin: 10px 0;
}
.program-panel .dynamic-drawer-container .drawer-form-container .drawer-input-container .input-parent .input-container .file-upload-container .file-dropzone .file-upload-loader {
  flex-direction: row;
}
.program-panel .dynamic-drawer-container .drawer-form-container .drawer-input-container .input-parent .input-container .file-upload-container .file-dropzone .file-upload-loader img {
  width: 40%;
}
.program-panel .dynamic-drawer-container .drawer-form-container .drawer-input-container .input-parent .input-container .file-upload-container .file-dropzone .file-upload-loader p {
  font-size: 14px;
}
.dynamic-drawer-container {
  width: 350px;
  min-width: 350px;
  padding: 0 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: overlay;
  height: calc(100% - 60px);
}
@media (max-width: 896px) {
  .dynamic-drawer-container {
    width: 260px;
    min-width: unset;
  }
}
.dynamic-drawer-container .drawer-heading {
  font-size: 20px;
  display: flex;
  text-align: center;
  font-family: 'Roboto-Medium';
  text-transform: uppercase;
  color: #048041;
  justify-content: center;
  padding: 1em;
  width: auto;
  border-bottom: 1px solid #048041;
}
.dynamic-drawer-container .drawer-form-container {
  display: flex;
  flex-direction: column;
  overflow: overlay;
  font-size: 16px;
  font-family: 'Roboto-Regular';
  width: 100%;
  padding: 1em 0;
}
.dynamic-drawer-container .drawer-form-container .drawer-input-container {
  margin: 15px 0 0 0;
}
.dynamic-drawer-container .drawer-form-container .drawer-input-container .input-container {
  flex-direction: column;
  align-items: flex-start;
}
.dynamic-drawer-container .drawer-form-container .drawer-input-container .input-container .input-label {
  flex: 100%;
  width: 88% !important;
  text-align: left;
  margin: 0;
  padding: 0;
}
.dynamic-drawer-container .drawer-form-container .drawer-input-container .input-container .input-box {
  width: 88% !important;
  margin: 5px 0 0 0;
}
.dynamic-drawer-container .drawer-form-container .drawer-input-container .input-container .input-box.enerva-dropdown {
  width: 97% !important;
}
.dynamic-drawer-container .drawer-form-container .drawer-input-container .input-container .multi-select-checkbox-component {
  width: 100%;
  margin: 5px 0 0 0;
  height: 30px;
}
.dynamic-drawer-container .drawer-form-container .drawer-input-container .input-container .date-picker {
  width: 200px !important;
}
.dynamic-drawer-container .drawer-form-container .drawer-input-container .input-container .file-upload-container {
  width: 88% !important;
  padding: 0 10px;
  margin: 10px 0;
}
.dynamic-drawer-container .drawer-form-container .drawer-input-container .input-container .file-upload-container .file-dropzone {
  flex-direction: row;
  padding: 1em;
}
.dynamic-drawer-container .drawer-form-container .drawer-input-container .input-container .file-upload-container .file-dropzone svg {
  margin: 0 10px 0 0;
}
.dynamic-drawer-container .drawer-form-container .drawer-input-container .input-container .file-upload-container .file-dropzone p {
  font-size: 12px;
}
.dynamic-drawer-container .drawer-form-container .drawer-input-container .error-message {
  padding: 5px 0;
}
.dynamic-drawer-container .enerva-btn {
  background-color: #C00000;
}
.dynamic-drawer-container .enerva-btn.inversed-btn {
  background: #FFFFFF;
  border: 1px solid #C00000;
  color: #C00000;
  text-align: center;
  float: right;
}
@media (max-width: 1024px) {
  .dynamic-drawer-container .enerva-btn.inversed-btn {
    max-width: unset;
  }
}
.dynamic-drawer-container .drawer-submit-btn {
  position: fixed;
  bottom: 0;
  width: 380px;
  margin: 0;
  background: #C00000;
  padding: 15px 0;
  border: none;
  border-radius: 0;
}
@media (max-width: 896px) {
  .dynamic-drawer-container .drawer-submit-btn {
    width: 290px;
  }
}
.dynamic-drawer-container .drawer-submit-btn.inversed-btn {
  bottom: 49px;
  background: #FFF;
  border: 1px solid #C00000;
  color: #C00000;
}
