.app-review-container {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 16px;
  min-height: 650px;
  max-height: 90vh;
}
@media (max-width: 1024px) {
  .app-review-container {
    padding: 0;
    margin: 0;
    flex: 100%;
  }
}
@media only screen and (max-width: 920px) and (min-width: 700px) {
  .app-review-container {
    height: 50vh;
  }
}
@media only screen and (max-width: 896px) and (min-width: 700px) {
  .app-review-container {
    height: 60vh;
  }
}
@media (max-width: 700px) {
  .app-review-container {
    height: 80vh;
    min-height: 360px;
  }
}
.app-review-container .form-inner-section {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: auto;
  align-items: center;
}
@media (max-width: 896px) {
  .app-review-container .form-inner-section {
    width: 100%;
  }
}
.app-review-container .form-inner-section .section-header {
  width: 100%;
}
.app-review-container .form-inner-section .step-header-title {
  width: 100%;
}
.app-review-container .form-inner-section .form-inner-elements {
  display: flex;
  width: 100%;
  margin: 1em 0;
  align-items: center;
}
@media (max-width: 896px) {
  .app-review-container .form-inner-section .form-inner-elements {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
  }
}
.app-review-container .form-inner-section .form-inner-elements .application-form-label {
  flex: 50% 0;
  text-align: left;
  font-size: 16px;
  padding: 0 2em;
}
@media (max-width: 896px) {
  .app-review-container .form-inner-section .form-inner-elements .application-form-label {
    margin: 10px 0;
    padding: 0;
  }
}
.app-review-container .form-inner-section .form-inner-elements .application-form-element {
  flex: 0 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.app-review-container .form-inner-section .form-inner-elements .application-form-element .info-icon {
  margin: 0 10px;
  width: 20px;
  position: absolute;
  right: 0;
}
@media (max-width: 896px) {
  .app-review-container .form-inner-section .form-inner-elements .application-form-element .info-icon {
    margin: 0 5px;
    position: relative;
  }
}
.app-review-container .form-inner-section .form-inner-elements .application-form-element .input-container {
  justify-content: flex-end;
}
.app-review-container .form-inner-section .form-inner-elements .application-form-element .error-message {
  padding: 5px 0;
}
.app-review-container .form-inner-section .form-inner-elements .application-form-element .input-box {
  width: 240px;
  height: 24px;
  font-size: 16px;
  font-family: 'Roboto-Regular';
}
.app-review-container .form-inner-section .form-inner-elements .application-form-element .input-box.enerva-dropdown {
  height: 44px;
}
.app-review-container .form-inner-section .form-inner-elements .application-form-element .input-box.textarea-box {
  width: 240px;
  height: 40px;
}
.app-review-container .form-inner-section .form-inner-elements .application-form-element .date-picker {
  max-width: 170px;
}
.app-review-container .form-inner-section .form-note-container {
  font-size: 16px;
  text-align: left;
  padding: 0;
  margin: 2em 0;
  line-height: 24px;
  width: 100%;
}
.app-review-container .form-inner-section .form-note-container b {
  color: #048041;
}
.app-review-container .form-inner-section .form-note-container ol {
  padding: 1em;
  margin: 0;
}
.app-review-container .form-inner-section .empty-summary-container {
  display: flex;
  height: 240px;
  background: #E3DDDA;
  width: auto;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  padding: 0 5em;
  text-align: left;
  margin: 1em 0;
  font-family: 'Roboto-Light';
  width: 80%;
}
@media (max-width: 896px) {
  .app-review-container .form-inner-section .empty-summary-container {
    padding: 10px;
    width: 95%;
  }
}
.app-review-container .form-inner-section .horizontal-bar {
  height: 0px;
  border-bottom: 1px solid #E3DDDA;
  margin: 3em 0;
  width: 50%;
}
.app-review-container .form-inner-section.documents-section .input-parent {
  width: 100%;
}
.app-review-container .form-inner-section.documents-section .input-parent .file-upload-container {
  width: 100%;
}
@media (max-width: 896px) {
  .app-review-container .form-inner-section.documents-section .input-parent .file-upload-container {
    width: auto;
  }
}
.app-review-container .form-inner-section.documents-section .empty-summary-container {
  background: none;
  border: 1px solid #E3DDDA;
}
.app-review-container .form-inner-section.documents-section .summary-items-row .summary-item-details {
  width: 50%;
}
.app-review-container .form-inner-section.documents-section b {
  color: #048041;
}
.app-review-container .form-inner-section .summary-list-container {
  display: flex;
  width: 96%;
  flex-direction: column;
  justify-content: flex-start;
  text-align: left;
  margin: 1em 0;
  font-size: 16px;
}
.app-review-container .form-inner-section .summary-list-container .list-heading {
  font-weight: bold;
}
.app-review-container .form-inner-section .summary-list-container ol li {
  padding: 0.5em 1em;
  line-height: 24px;
}
.app-review-container .form-inner-section .summary-list-container ol li::marker {
  font-weight: bold;
}
.app-review-container .form-inner-section .summary-list-container ol li span {
  color: #048041;
  font-weight: bold;
  text-decoration: underline;
}
.app-review-container .form-inner-section .terms-agree-check {
  display: flex;
  font-size: 16px;
  width: 92%;
  align-items: flex-end;
  color: #048041;
}
.app-review-container .form-inner-section .terms-agree-check span {
  margin-right: 10px;
}
.app-review-container .section-header {
  display: flex;
  padding: 1em 0;
  align-items: center;
}
@media (max-width: 1024px) {
  .app-review-container .section-header {
    padding: 10px 0;
  }
}
.app-review-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  flex: 30% 0;
}
@media (max-width: 1024px) {
  .app-review-container .section-header .section-title {
    font-size: 16px;
  }
}
.app-review-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.app-review-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.app-review-container .section-below-header {
  display: flex;
  padding: 1em 0;
  align-items: center;
}
@media (max-width: 896px) {
  .app-review-container .section-below-header {
    padding: 10px 0;
    flex-direction: column;
  }
}
.app-review-container .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 70%;
}
@media (max-width: 1024px) {
  .app-review-container .section-below-header .section-title {
    font-size: 16px;
  }
}
.app-review-container .section-below-header .qaQcType {
  width: 30%;
}
.app-review-container .section-below-header .qaQcType .input-parent .input-container {
  margin-top: 0;
}
@media (max-width: 896px) {
  .app-review-container .section-below-header .qaQcType {
    width: 100%;
  }
}
.app-review-container .section-below-header .review-status-selection {
  flex: 0 72%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .app-review-container .section-below-header .review-status-selection {
    justify-content: flex-start;
  }
}
.app-review-container .section-below-header .review-status-selection label {
  color: #686C6D;
}
.app-review-container .section-below-header .review-status-selection .input-container {
  width: 400px;
}
@media (max-width: 1024px) {
  .app-review-container .section-below-header .review-status-selection .input-container {
    margin: 0;
    width: 300px;
  }
}
.app-review-container .section-below-header .review-status-selection .input-container .enerva-dropdown__control {
  font-size: 14px;
}
.app-review-container .section-below-header .review-status-selection .input-container .enerva-dropdown__control .enerva-dropdown__single-value {
  font-size: 14px;
}
.app-review-container .section-below-header .review-status-selection .input-container .enerva-dropdown__menu {
  font-size: 14px;
}
.app-review-container .section-below-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
}
.app-review-container .section-below-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.app-review-container .ces-contractor-review-container {
  padding: 1em;
  overflow: auto;
  flex: 1;
  color: #001737;
  border: 1px solid #f2f2f2;
}
.app-review-container .ces-contractor-review-container .not-eligible {
  background-color: #E3DDDA;
}
.app-review-container .ces-contractor-review-container .horizontal-bar {
  height: 0px;
  border-bottom: 1px solid #E3DDDA;
  margin: 3em 0 3em 33%;
  width: 33%;
}
.app-review-container .ces-contractor-review-container .section-title {
  text-align: left;
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  flex: 30% 0;
}
@media (max-width: 1024px) {
  .app-review-container .ces-contractor-review-container .section-title {
    font-size: 16px;
  }
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container {
    padding: 0.3em;
    flex: 1;
    max-height: 515px;
  }
}
@media (max-width: 415px) {
  .app-review-container .ces-contractor-review-container {
    flex: 1;
    overflow-y: auto;
  }
}
.app-review-container .ces-contractor-review-container .bera .measures-application-review-container {
  width: 100%;
}
.app-review-container .ces-contractor-review-container .bera .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .post-project .review-detail:nth-child(2) .input-parent .input-container {
  margin-top: 4px;
}
.app-review-container .ces-contractor-review-container .bera .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .post-project .review-detail:nth-child(2) .input-parent .input-container .switch-container {
  margin: 0px;
}
.app-review-container .ces-contractor-review-container .contractor-review-table {
  display: flex;
  flex-direction: row;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container {
  width: 100%;
  overflow-y: hidden;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container {
    overflow-x: hidden;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Medium';
  text-align: left;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header {
    display: none;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header .review-detail {
  display: flex;
  align-items: center;
  padding: 0 1em;
  text-align: left;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header .review-detail:nth-child(1) {
  width: 20%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header .review-detail:nth-child(2) {
  width: 15%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header .review-detail:nth-child(3) {
  width: 15%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header .review-detail:nth-child(4) {
  width: 15%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header .review-detail:nth-child(5) {
  width: 15%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header .review-detail:nth-child(6) {
  width: 20%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header.post-project .review-detail:nth-child(1) {
  width: 15%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header.post-project .review-detail:nth-child(2) {
  width: 15%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header.post-project .review-detail:nth-child(3) {
  width: 10%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header.post-project .review-detail:nth-child(4) {
  width: 15%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header.post-project .review-detail:nth-child(5) {
  width: 15%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header.post-project .review-detail:nth-child(6) {
  width: 15%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header.post-project .review-detail:nth-child(7) {
  width: 15%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header.additional-review .review-detail:nth-child(1) {
  width: 25%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header.additional-review .review-detail:nth-child(2) {
  width: 20%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header.additional-review .review-detail:nth-child(3) {
  width: 20%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-header.additional-review .review-detail:nth-child(4) {
  width: 20%;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left {
  display: flex;
  flex-direction: column;
  flex: 65% 0;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left {
    overflow-y: visible;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.post-project .review-detail:nth-child(1) {
  width: 15%;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.post-project .review-detail:nth-child(1) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.post-project .review-detail:nth-child(2) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.post-project .review-detail:nth-child(2) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.post-project .review-detail:nth-child(3) {
  width: 10% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.post-project .review-detail:nth-child(3) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.post-project .review-detail:nth-child(4) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.post-project .review-detail:nth-child(4) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.post-project .review-detail:nth-child(5) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.post-project .review-detail:nth-child(5) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.post-project .review-detail:nth-child(6) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.post-project .review-detail:nth-child(6) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.post-project .review-detail:nth-child(7) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.post-project .review-detail:nth-child(7) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.additional-review .review-detail:nth-child(1) {
  width: 25%;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.additional-review .review-detail:nth-child(1) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.additional-review .review-detail:nth-child(2) {
  width: 20% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.additional-review .review-detail:nth-child(2) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.additional-review .review-detail:nth-child(3) {
  width: 20% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.additional-review .review-detail:nth-child(3) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.additional-review .review-detail:nth-child(4) {
  width: 20% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details.additional-review .review-detail:nth-child(4) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Regular';
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details {
    flex-direction: column;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail {
  display: flex;
  align-items: center;
  padding: 1em;
  text-align: left;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail {
    width: 100%;
  }
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail .input-container {
    max-width: 120px;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail label {
  display: none;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail label {
    display: inline-block;
    width: 100%;
    max-width: 150px;
    margin-right: 0;
    font-family: 'Roboto-Medium';
    text-align: left;
  }
}
@media (max-width: 1024px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail span {
    width: 100%;
    padding-top: 0.5em;
    text-align: left;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .explanation-text {
  width: 69% !important;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .explanation-text {
    width: 100% !important;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .explanation-text .input-parent .input-container {
  max-width: none;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .explanation-text .input-parent .input-container span {
  width: unset;
  padding-top: 0;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .explanation-text .review-notes-text {
  display: flex;
  width: 100%;
  padding: 1em 2em;
  font-size: 12px;
  border: 1px solid #CCC;
  min-height: 50px;
  color: #048041;
  background: #F8F7F6;
  text-align: left;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(1) {
  width: 20%;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(1) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(2) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(2) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(3) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(3) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(4) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(4) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(5) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(5) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(6) {
  width: 20% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(6) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-status span {
  margin-right: 1em;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container {
  padding-top: 0.2em;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Regular';
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals {
    flex-direction: column;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .mobile-total {
  font-family: 'Roboto-Bold';
  color: #048041;
  font-size: 16px;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail {
  display: flex;
  align-items: center;
  padding: 1em;
  text-align: left;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail label {
  display: none;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail label {
    display: inline-block;
    width: 100%;
    max-width: 150px;
    margin-right: 0;
    font-family: 'Roboto-Medium';
    text-align: left;
  }
}
@media (max-width: 1024px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail span {
    width: 100%;
    padding-top: 0.5em;
    text-align: left;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(1) {
  width: 15%;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(1) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(2) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(2) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(3) {
  width: 10% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(3) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(4) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(4) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(5) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(5) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(6) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(6) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(7) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(7) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(1) {
  width: 20%;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(1) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(2) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(2) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(3) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(3) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(4) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(4) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(5) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(5) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(6) {
  width: 20% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table .measures-application-review-container .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(6) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .contractor-review-table .contractor-review-table-right {
  display: flex;
  flex: 0 1 35%;
  align-items: center;
  font-size: 14px;
  text-align: left;
  color: #001737;
  flex-direction: column;
  justify-content: center;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .contractor-review-table-right p {
  text-align: left;
  color: #001737;
}
.app-review-container .ces-contractor-review-container .contractor-review-table .contractor-review-details:nth-child(even) {
  background: #F8F7F6;
}
@media (max-width: 1024px) {
  .app-review-container .ces-contractor-review-container .contractor-review-table {
    flex-direction: column;
  }
  .app-review-container .ces-contractor-review-container .contractor-review-table .vertical-separator-box {
    display: none;
  }
}
.app-review-container .ces-contractor-review-container .review-mode {
  flex: 100% 0;
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left {
  flex: 100% 0;
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(1) {
  width: 20%;
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(2) {
  width: 15%;
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(3) {
  width: 15%;
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(4) {
  width: 15%;
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(5) {
  width: 15%;
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .contractor-review-inner-container .contractor-review-details .review-detail:nth-child(6) {
  width: 20%;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container {
    overflow-x: hidden;
  }
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals {
    overflow-x: hidden;
  }
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(1) {
  width: 15%;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(1) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(2) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(2) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(3) {
  width: 10% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(3) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(4) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(4) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(5) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(5) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(6) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(6) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(7) {
  width: 15% ;
}
@media (max-width: 896px) {
  .app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals.post-project .review-detail:nth-child(7) {
    width: 100%;
  }
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals .review-detail {
  display: flex;
  align-items: center;
  padding: 1em;
  text-align: left;
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(1) {
  width: 25%;
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(2) {
  width: 15% ;
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(3) {
  width: 20% ;
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(4) {
  width: 20% ;
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-left .app-review-totals-inner-container .app-review-totals .review-detail:nth-child(5) {
  width: 20% ;
}
.app-review-container .ces-contractor-review-container .review-mode .vertical-separator-box {
  display: none;
}
.app-review-container .ces-contractor-review-container .review-mode .contractor-review-table-right {
  display: none;
}
.app-review-container .ces-contractor-review-container .review-notes-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1em 0;
}
.app-review-container .ces-contractor-review-container .review-notes-container .notice-message {
  font-size: 12px;
  color: #333131;
  text-align: left;
}
.app-review-container .ces-contractor-review-container .review-notes-container label {
  font-size: 14px;
  font-family: 'Roboto-Medium';
  text-align: left;
  padding: 1em 0;
}
.app-review-container .ces-contractor-review-container .review-notes-container textarea {
  width: auto;
  border: 1px solid #f2f2f2;
  padding: 1em;
}
.app-review-container .ces-contractor-review-container .review-notes-container textarea:focus {
  border: 1px solid #048041;
  outline: none;
}
.app-review-container .ces-contractor-review-container .review-notes-container .review-notes-text {
  display: flex;
  width: auto;
  padding: 1em 2em;
  font-size: 12px;
  border: 1px solid #CCC;
  min-height: 50px;
  color: #048041;
  background: #F8F7F6;
  text-align: left;
}
.app-review-container .review-btn-container {
  display: flex;
  justify-content: flex-end;
  margin: 1em 0 0 0;
}
@media (max-width: 570px) {
  .app-review-container .review-btn-container {
    display: grid;
    grid-template-columns: 100%;
    margin: 1em 0 1em 0;
  }
}
.app-review-container .review-btn-container button {
  width: 220px;
  margin: 10px 0 10px 20px;
}
.app-review-container .review-btn-container button.edit-review {
  background-color: #FFF;
  color: #048041;
}
.app-review-container .review-btn-container button.cal {
  width: 250px;
}
@media (max-width: 570px) {
  .app-review-container .review-btn-container button.cal {
    width: 100%;
  }
}
@media (max-width: 570px) {
  .app-review-container .review-btn-container button {
    width: 100%;
    margin: 10px 0 0 0;
  }
}
