.admin-blocked-modal .enerva-btn {
  background-color: #C00000;
}
.admin-login-container {
  width: 100%;
  max-width: 1024px;
  margin: auto;
  max-width: 670px;
  min-height: 85vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.admin-login-container .logo {
  width: 210px;
  height: 50px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../../images/EnervaVectorLogo.svg');
}
@media (max-width: 768px) {
  .admin-login-container .logo {
    width: 260px;
    height: 80px;
  }
}
.admin-login-container .section-header {
  padding-top: 4.5em;
  padding-bottom: 1.5em;
}
@media (max-width: 768px) {
  .admin-login-container .section-header {
    padding-top: 2.5em;
    padding-bottom: 1em;
  }
}
.admin-login-container .section-header .section-title {
  text-align: center;
  color: #1D1D1D;
  text-transform: uppercase;
  font-size: 32px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  margin: auto;
}
@media (max-width: 768px) {
  .admin-login-container .section-header .section-title {
    font-size: 24px;
  }
}
.admin-login-container .container-box {
  padding: 1.5em;
  display: flex;
  justify-content: space-between;
  width: 70%;
}
@media (max-width: 768px) {
  .admin-login-container .container-box {
    max-width: 350px;
    padding: 0;
  }
}
.admin-login-container .container-box .form-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex: 50%;
}
.admin-login-container .container-box .form-section .input-parent .error-message {
  padding-left: 37% !important;
}
@media (max-width: 768px) {
  .admin-login-container .container-box .form-section .input-parent .error-message {
    padding-left: 0 !important;
  }
}
.admin-login-container .container-box .form-section .input-parent .input-container {
  text-align: right;
  margin-bottom: 0px;
  margin-top: 15px;
}
.admin-login-container .container-box .form-section .input-parent .input-container .input-box {
  border: 1px solid #686C6D;
  color: #1D1D1D;
}
.admin-login-container .container-box .form-section .input-parent .input-container .input-box input {
  color: #1D1D1D;
}
.admin-login-container .container-box .form-section .input-parent .input-container .input-box:hover,
.admin-login-container .container-box .form-section .input-parent .input-container .input-box:focus {
  border: 1px solid #686C6D;
  outline: none;
  text-decoration: none;
  box-shadow: 0px 0px 5px #1D1D1D;
}
.admin-login-container .container-box .form-section .input-parent .input-container .input-box-success {
  border: 1px solid #048041;
  outline: none;
  text-decoration: none;
  box-shadow: 0px 0px 5px #1D1D1D;
}
@media (max-width: 768px) {
  .admin-login-container .container-box .form-section .input-parent .input-container {
    text-align: left;
    flex-direction: column;
  }
}
.admin-login-container .container-box .form-section .input-parent .input-label {
  font-size: 24px;
  color: #1D1D1D;
  width: 120px;
}
@media (max-width: 768px) {
  .admin-login-container .container-box .form-section .input-parent .input-label {
    width: unset;
  }
}
.admin-login-container .container-box .form-section .login-btn {
  width: 100%;
  margin: 10px auto;
  max-width: 300px;
  background-size: 22px;
  background-color: #C00000;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 75%;
  background-image: url('../../../images/LoginWhite.svg');
}
@media (max-width: 896px) {
  .admin-login-container .container-box .form-section .login-btn {
    background-size: 16px;
  }
}
.admin-login-container .container-box .login-links {
  color: #1D1D1D;
  font-size: 16px;
  display: flex;
  justify-content: center;
  margin: 5px 0;
  cursor: pointer;
}
.admin-login-container .container-box .login-links a {
  margin-left: 5px;
  font-weight: bold;
  cursor: pointer;
}
.admin-login-container .container-box .login-links a:hover {
  opacity: 0.8;
}
