.program-filter .check-box-checked {
  background-color: #048041 !important;
}
.payment-tab {
  padding: 0 0 20px 40px;
  color: #001737;
  border: 1px solid #f2f2f2;
}
@media (max-width: 1024px) {
  .payment-tab {
    padding: 1em;
  }
}
.payment-tab .payment-details-row:nth-child(odd) {
  background: #F8F7F6;
}
.payment-tab .payment-details-list {
  max-height: 60vh;
  overflow: auto;
  position: relative;
}
.payment-tab .payment-details-row {
  display: flex;
  flex-direction: column;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}
.payment-tab .payment-details-row.payment-details-header {
  display: flex;
  min-height: 50px;
  align-items: center;
  background: none;
}
@media (max-width: 1024px) {
  .payment-tab .payment-details-row.payment-details-header {
    display: none;
  }
}
.payment-tab .payment-details-row.payment-details-header .payment-details {
  font-family: 'Roboto-Medium';
  font-size: 16px;
}
.payment-tab .payment-details-row.payment-details-header .payment-details div {
  background-image: url('../../../../images/SortIcon.svg');
  background-repeat: no-repeat;
  background-position: right;
}
.payment-tab .payment-details-row.payment-details-header .payment-details div:hover {
  opacity: 0.7;
  cursor: pointer;
}
.payment-tab .payment-details-row.payment-details-header .payment-details div span {
  width: 90%;
  margin: auto;
}
.payment-tab .payment-details-row.active-row {
  background: #e3ddda !important;
  border-bottom: 1px solid #FFF;
  border-radius: 0 0 5px 5px;
  transition: background 1s ease-out;
}
.payment-tab .payment-details-row.active-row .payment-details {
  font-family: 'Roboto-Regular';
  border-bottom: 1px solid #D3E3F7;
}
@media (max-width: 1024px) {
  .payment-tab .payment-details-row.active-row .payment-details {
    font-weight: normal;
  }
}
.payment-tab .payment-details-row.active-row .payment-details .show-more-btn {
  background-image: url('../../../../images/MoreButtonDark.svg');
}
.payment-tab .payment-details-row.active-row .payment-more-details {
  display: flex;
  transition: all 0.3s ease;
}
.payment-tab .payment-details-row .payment-details {
  display: flex;
  width: 100%;
  min-height: 50px;
  align-items: center;
  font-family: 'Roboto-Light';
  font-size: 14px;
  word-break: break-word;
}
.payment-tab .payment-details-row .payment-details.linked-app {
  background: #C79C9C;
}
.payment-tab .payment-details-row .payment-details label {
  display: none;
  margin-right: 1em;
  width: 100px;
}
@media (max-width: 1024px) {
  .payment-tab .payment-details-row .payment-details label {
    display: inline-block;
    width: 100%;
    margin-right: 0;
    font-family: 'Roboto-Medium';
  }
}
.payment-tab .payment-details-row .payment-details span {
  width: 90%;
  margin: auto;
}
@media (max-width: 1024px) {
  .payment-tab .payment-details-row .payment-details span {
    width: 100%;
    padding-top: 0.5em;
    text-align: left;
  }
}
@media (max-width: 1024px) {
  .payment-tab .payment-details-row .payment-details {
    flex-direction: column;
    padding: 1em;
    width: auto;
  }
}
.payment-tab .payment-details-row .payment-details div {
  width: 10%;
  padding: 0 15px;
  display: flex;
  align-items: center;
}
@media (max-width: 1024px) {
  .payment-tab .payment-details-row .payment-details div {
    width: 100% !important;
    padding: 5px 0;
  }
}
@media (max-width: 500px) {
  .payment-tab .payment-details-row .payment-details div {
    padding: 2px 0;
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .payment-tab .payment-details-row .payment-details div {
    flex-direction: column;
  }
}
.payment-tab .payment-details-row .payment-details .show-more-btn {
  min-height: 50px;
  background-repeat: no-repeat;
  background-image: url('../../../../images/MoreButton.svg');
  background-position: center;
}
@media (max-width: 1024px) {
  .payment-tab .payment-details-row .payment-details .show-more-btn {
    min-height: 15px;
    background-position: right;
  }
}
.payment-tab .payment-details-row .payment-more-details {
  padding: 10px;
  display: none;
  flex-direction: column;
}
.payment-tab .payment-details-row .payment-more-details div {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 400px) {
  .payment-tab .payment-details-row .payment-more-details div {
    flex-direction: column;
    align-items: flex-start;
  }
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links {
  display: flex;
  padding: 15px 10px 15px 40px;
  min-width: 15%;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 10px;
  background-size: 18px;
  font-size: 12px;
  border-radius: 0 5px 5px 0;
  font-family: 'Roboto-Light';
  margin-right: 4%;
  cursor: pointer;
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links:hover {
  background-color: rgba(4, 128, 65, 0.25);
  font-family: 'Roboto-Regular';
  color: #048041;
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.edit-icon {
  background-image: url('../../../../images/EditDocVector.svg');
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.review-icon {
  background-image: url('../../../../images/ReviewVector.svg');
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.status-log-icon {
  background-image: url('../../../../images/StatusLog.svg');
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.change-request-icon {
  background-image: url('../../../../images/ChangeRequest.svg');
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.assign-icon {
  background-image: url('../../../../images/ReviewAssign.svg');
  background-size: 36px;
  background-position-x: 0;
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.reject-app-icon {
  background-image: url('../../../../images/RejectApplication.svg');
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.view-icon {
  background-image: url('../../../../images/ApplicationVector.svg');
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.doc-icon {
  background-image: url('../../../../images/DocumentVector.svg');
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.flag-icon {
  background-image: url('../../../../images/FlagVector.svg');
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.suspend-icon {
  background-image: url('../../../../images/SuspendUserVector.svg');
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.reinstate-icon {
  background-image: url('../../../../images/ReinstateUserVector.svg');
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.mail-icon {
  background-image: url('../../../../images/MailVector.svg');
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.upload-icon {
  background-image: url('../../../../images/FileUploadVector.svg');
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.change-invoice {
  background-image: url('../../../../images/ChangeInvoiceReviewer.svg');
}
.payment-tab .payment-details-row .payment-more-details div .more-details-links.assign-invoice {
  background-image: url('../../../../images/AssignInvoice.svg');
}
.payment-tab .no-records-found {
  text-align: center;
  padding: 1em;
  background: #F8F7F6;
}
