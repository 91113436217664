.status-logs-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: hidden;
}
@media (max-width: 1024px) {
  .status-logs-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
    padding-bottom: 2em;
  }
}
@media (max-width: 896px) {
  .status-logs-container {
    overflow-x: hidden;
    max-width: 90vw;
  }
}
.status-logs-container .MuiTableContainer-root {
  max-height: 380px;
}
.status-logs-container .container-box {
  width: 99%;
  overflow-y: auto;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #98989A;
  padding: 0em;
  max-height: 450px;
  text-align: center;
}
.status-logs-container .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .status-logs-container .section-header {
    padding: 10px 0;
  }
}
.status-logs-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media (max-width: 1024px) {
  .status-logs-container .section-header .section-title {
    font-size: 16px;
  }
}
.status-logs-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.status-logs-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.status-logs-container .section-below-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .status-logs-container .section-below-header {
    padding: 10px 0;
    flex-direction: column;
  }
}
.status-logs-container .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .status-logs-container .section-below-header .section-title {
    font-size: 16px;
  }
}
.status-logs-container .section-below-header .review-status-selection {
  flex: 0 72%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .status-logs-container .section-below-header .review-status-selection {
    justify-content: flex-start;
  }
}
.status-logs-container .section-below-header .review-status-selection label {
  color: #98989A;
}
.status-logs-container .section-below-header .review-status-selection .input-container {
  width: 400px;
}
@media (max-width: 1024px) {
  .status-logs-container .section-below-header .review-status-selection .input-container {
    margin: 0;
    width: 300px;
  }
}
.status-logs-container .section-below-header .review-status-selection .input-container .enerva-dropdown__control {
  font-size: 14px;
}
.status-logs-container .section-below-header .review-status-selection .input-container .enerva-dropdown__control .enerva-dropdown__single-value {
  font-size: 14px;
}
.status-logs-container .section-below-header .review-status-selection .input-container .enerva-dropdown__menu {
  font-size: 14px;
}
.status-logs-container .section-below-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
}
.status-logs-container .section-below-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.status-logs-container .form-sections {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow-y: hidden;
}
@media (max-width: 1024px) {
  .status-logs-container .form-sections {
    flex-direction: column;
  }
}
.status-logs-container .form-sections .row-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 896px) {
  .status-logs-container .form-sections .row-section {
    flex-direction: column;
  }
}
.status-logs-container .form-sections .row-section:nth-child(1) {
  padding-bottom: 1em;
}
.status-logs-container .form-sections .row-section:nth-child(2n) {
  background-color: #e3ddda;
}
.status-logs-container .form-sections .row-section .section-title {
  font-size: 18px;
  font-family: 'Roboto-Bold';
  color: #1D1D1D;
  text-transform: capitalize;
}
.status-logs-container .form-sections .row-section .section-subtitle {
  font-size: 16px;
  font-family: 'Roboto-Regular';
  color: #535765;
  text-transform: capitalize;
}
.status-logs-container .form-sections .row-section .section-left {
  width: 80%;
  text-align: left;
  padding-right: 1em;
  padding-left: 0.5em;
}
@media (max-width: 896px) {
  .status-logs-container .form-sections .row-section .section-left {
    width: 100%;
    padding-right: 0px;
  }
}
.status-logs-container .form-sections .row-section .section-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
}
@media (max-width: 896px) {
  .status-logs-container .form-sections .row-section .section-right {
    width: 100%;
    padding-top: 0px;
  }
}
.status-logs-container .form-sections .row-section .section-right p {
  color: #535765;
}
@media (max-width: 896px) {
  .status-logs-container .form-sections .first-row {
    display: none;
  }
}
.status-logs-container .signup-btn {
  width: 100%;
  margin-top: 25px;
  max-width: 250px;
}
@media (max-width: 1024px) {
  .status-logs-container .signup-btn {
    max-width: unset;
  }
}
.status-logs-container .confirm-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
@media (max-width: 1024px) {
  .status-logs-container .confirm-btn-container {
    flex-direction: column;
    min-width: 180px;
  }
}
.status-logs-container .confirm-btn-container .enerva-btn {
  margin-right: 0px;
}
