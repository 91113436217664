.review-delete h3 {
  color: #048041;
}
.review-list-container {
  width: auto;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow: hidden;
  width: 94% !important;
  overflow-y: auto;
  text-align: center;
  min-height: 400px;
}
.review-list-container .responsive-table-container {
  width: auto !important;
}
.review-list-container .MuiTableCell-body p {
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
  margin: 0;
  padding: 0 !important;
}
.review-list-container .section-below-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  align-content: center;
  justify-content: flex-start;
  margin: 0 !important;
  flex-direction: column;
}
.review-list-container .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Bold';
  font-weight: normal;
  text-align: left;
  width: 80%;
}
@media (width: 1024px) {
  .review-list-container .section-below-header .section-title {
    width: 65%;
  }
}
.review-list-container .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  padding: 10px 0;
  align-items: center;
}
.review-list-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
  font-size: 16px;
}
.review-list-container .section-header .section-top-details {
  font-family: 'Roboto-Medium';
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
}
.review-list-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.review-list-container .form-sections {
  display: flex;
  justify-content: space-around;
  overflow-y: hidden;
  flex-direction: column;
}
.review-list-container .form-sections .row-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
.review-list-container .form-sections .row-section:nth-child(1) {
  padding-bottom: 1em;
}
.review-list-container .form-sections .row-section:nth-child(2n) {
  background-color: #e3ddda;
}
.review-list-container .form-sections .row-section .section-title {
  font-size: 18px;
  font-family: 'Roboto-Bold';
  color: #1D1D1D;
  text-transform: capitalize;
}
.review-list-container .form-sections .row-section .section-subtitle {
  font-size: 16px;
  font-family: 'Roboto-Regular';
  color: #535765;
  text-transform: capitalize;
}
.review-list-container .form-sections .row-section .section-left {
  width: 80%;
  text-align: left;
  padding-right: 1em;
  padding-left: 0.5em;
}
.review-list-container .form-sections .row-section .section-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
}
.review-list-container .form-sections .row-section .section-right p {
  color: #535765;
}
.review-list-container .information-container-label {
  width: 100%;
  display: flex;
  font-weight: bold;
}
.review-list-container .makeStyles-container-2 {
  max-height: 270px;
}
.review-list-container .enerva-btn {
  margin-top: 0 !important;
  width: 100%;
  margin-top: 25px;
  max-width: 250px;
  max-width: unset;
}
.review-list-container .signup-btn {
  margin-top: 0 !important;
  width: 100%;
  margin-top: 25px;
  max-width: 250px;
  max-width: unset;
}
.review-list-container .confirm-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  flex-direction: column;
  min-width: 180px;
}
.review-list-container .confirm-btn-container .enerva-btn {
  margin-right: 0px;
}
.review-list-container .button-set {
  float: right;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
  margin-top: 0;
  margin-bottom: 0;
}
.review-list-container .button-set .upload-btn {
  width: 100%;
  max-width: 180px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 0;
  max-width: unset;
  margin-left: 0;
}
.review-list-container .button-set .close-btn {
  background-color: #f4f4f4;
  color: #686c6d;
  max-width: 180px;
  text-align: left;
  margin: 0px;
  width: 100%;
}
.review-list-container .button-set .review-upload-icon {
  background-image: url(../../../../images/FileUploadIcon20x20.svg);
  background-repeat: no-repeat;
  background-position: 85% 50%;
  padding-right: 10px;
  position: relative;
}
.review-list-container .button-set .review-close-icon {
  background-image: url(../../../../images/fileCloseIcon.svg);
  background-repeat: no-repeat;
  background-position: 85% 50%;
  padding-right: 10px;
  position: relative;
}
.review-list-container .button-set .add-icon {
  background-position: 90% 50%;
  padding-right: 30px;
}
.review-list-container .button-set.header {
  justify-content: flex-start !important;
}
.review-list-container .ReactModalPortal .modal-content h3 {
  color: #048041;
}
.container-box {
  width: 100%;
}
@media (max-width: 896px) {
  .container-box .MuiTableContainer-root {
    max-height: 300px;
  }
}
@media (max-width: 321px) {
  .container-box .MuiTableContainer-root {
    max-height: 250px;
  }
}
.container-box1 {
  width: 98%;
  max-height: 500px;
  overflow: auto;
  padding: 10px;
}
@media (max-width: 415px) {
  .container-box1 {
    max-height: calc(100vh - 60vh);
    overflow: auto;
  }
}
@media (max-width: 321px) {
  .container-box1 {
    max-height: 200px;
  }
}
.container-box1 .file-upload-prompt {
  text-align: center !important;
  color: #535765 !important;
  font-family: 'Roboto-Light';
  font-size: 16px !important;
}
.container-box1 .file-upload-prompt span {
  color: #535765;
  font-family: 'Roboto-Medium';
}
.container-box1 .infected-file {
  text-align: center !important;
  color: #DB1900 !important;
  font-family: 'Roboto-Light';
  font-size: 16px !important;
}
.container-box1 .empty-summary-container {
  width: auto;
  margin-right: 0px;
}
@media (max-width: 896px) {
  .container-box1 .empty-summary-container {
    height: 200px;
    font-size: 14px;
  }
}
@media (max-width: 321px) {
  .container-box1 .empty-summary-container {
    height: 150px;
    font-size: 12px;
  }
}
.view-eye-icon {
  cursor: pointer;
}
.view-eye-icon:hover {
  opacity: 0.7;
}
.section-below-header .button-set .button-options {
  align-items: center;
}
.section-below-header .button-set .ReactModalPortal .modal-content .input-container {
  margin-top: 0px;
}
.file-upload-container {
  cursor: pointer;
}
.file-upload-container .file-dropzone p b {
  color: #048041 !important;
}
.input-parent .file-upload-container {
  flex: 0 1 100%;
  margin-bottom: 20px;
}
.responsive-table-container .responsive-table-row .responsive-table-details .document-name-input {
  width: 47px;
  align-items: center;
}
.responsive-table-container .responsive-table-row .responsive-table-details span {
  color: #333131;
}
.responsive-table-container .responsive-table-row .responsive-table-details span .more-actions .view-eye-icon {
  margin-right: 14px;
}
.section-title {
  width: 65%;
}
.logs-btn {
  background-image: url(../../../../images/LogsFolder.svg);
  background-repeat: no-repeat;
  height: 36px;
  width: 38px;
  background-position: center;
  cursor: pointer;
  margin-right: 10px;
}
.logs-btn:hover {
  opacity: 0.7;
}
.logs-folder-btn {
  background-color: white;
  background-image: url(../../../../images/Log.svg);
  background-repeat: no-repeat;
  height: 33px;
  width: 33px;
  margin-top: 17px;
  margin-bottom: 17px;
  margin-right: 10px;
  background-position: inherit;
  cursor: pointer;
}
.logs-folder-btn:hover {
  opacity: 0.7;
}
.save-icon {
  width: 30px;
  height: 25px;
  position: absolute;
  padding-left: 5px;
  padding-top: 5px;
  cursor: pointer;
}
.save-icon:hover {
  opacity: 0.7;
}
.no-records-found {
  text-align: center;
  padding: 1em;
  background: #F8F7F6;
}
@media (max-width: 1024px) {
  .review-list-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
    padding-bottom: 2em;
  }
  .review-list-container .button-set .upload-btn {
    max-width: unset;
    margin: 0;
  }
  .review-list-container .button-set .close-btn {
    max-width: unset;
    margin: 0;
  }
  .view-eye-icon {
    cursor: pointer;
  }
  .view-eye-icon:hover {
    opacity: 0.7;
  }
  .file-upload-container .file-dropzone p b {
    color: #048041 !important;
  }
  .input-parent .file-upload-container {
    cursor: pointer;
  }
  .container-box1 .file-upload-prompt {
    text-align: center !important;
    color: #686c6d !important;
    font-family: 'Roboto-Light' !important;
    font-size: 18px !important;
  }
  .container-box1 .file-upload-prompt span {
    color: #686c6d;
    font-family: 'Roboto-Medium';
  }
  .container-box1 .empty-summary-container {
    text-align: center;
  }
  .responsive-table-container .responsive-table-row .responsive-table-details .document-name-input {
    width: 47px;
  }
  .logs-folder-btn {
    width: 55% !important;
    margin-top: 15px;
    margin-bottom: 15px;
    height: 20px;
  }
  .doc-name {
    display: grid;
    grid-template-columns: 75% 25%;
  }
  .doc-name .tooltiptext {
    visibility: hidden;
  }
}
@media (max-width: 1024px) and (max-width: 896px) {
  .search-box {
    margin: 0 0 20px 0;
    width: 100%;
  }
}
@media (max-width: 415px) {
  .review-list-container .button-set .button-options:nth-child(1) {
    grid-template-columns: 90% 10% !important;
  }
}
@media (max-width: 896px) {
  .search-box {
    width: 100%;
  }
  .search-box .search-btn {
    flex: 0 1 24%;
  }
  .review-list-container .form-sections .row-section {
    flex-direction: column;
  }
  .review-list-container .form-sections .row-section .section-left {
    width: 100%;
    padding-right: 0px;
  }
  .review-list-container .form-sections .row-section .section-right {
    width: 100%;
    padding-top: 0px;
  }
  .review-list-container .form-sections .first-row {
    display: none;
  }
  .file-upload-container .file-dropzone p {
    font-size: 14px !important;
  }
  .file-upload-container .file-dropzone svg {
    height: 40px;
  }
  .file-upload-container .file-dropzone {
    padding: 0.5em 1em !important;
  }
  .review-list-container .button-set .button-options:nth-child(1) {
    display: grid;
    width: 100%;
    grid-template-columns: 95% 5%;
    margin: 10px 0;
    align-items: center;
  }
  .review-list-container .button-set .button-options:nth-child(1) .section-title {
    font-size: 14px;
  }
  .review-list-container .button-set .button-options:nth-child(1) .logs-btn {
    height: 18px;
    margin: 0;
    width: 18px;
  }
  .review-list-container .button-set .button-options:nth-child(2) {
    width: 100%;
  }
  .review-list-container .button-set .button-options:nth-child(3) {
    width: 100%;
  }
}
@media (width: 1024px) {
  .review-list-container .button-set .button-options:nth-child(1) {
    align-items: center;
  }
  .review-list-container .button-set .button-options:nth-child(1) .section-title {
    font-size: 16px;
  }
  .review-list-container .button-set .button-options:nth-child(1) .logs-btn {
    height: 20px;
    margin: 0;
    width: 20px;
  }
}
@media (min-width: 896px) {
  .doc-name {
    position: relative;
    display: grid;
    grid-template-columns: 80% 20%;
  }
  .doc-name .turncate-string {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .doc-name .turncate-string:hover .tooltiptext {
    visibility: visible;
  }
  .tooltiptext {
    visibility: hidden;
    width: max-content !important;
    background-color: #e3ddda;
    color: #333131 !important;
    text-align: center;
    padding: 5px 5px;
    position: absolute;
    z-index: 1;
    bottom: 100%;
    left: 50%;
    margin-left: -60px;
  }
  .container-box1 .responsive-table-container .responsive-table-row .responsive-table-details:nth-child(1) {
    width: 10%;
  }
  .container-box1 .responsive-table-container .responsive-table-row .responsive-table-details:nth-child(3) {
    width: 17%;
  }
  .container-box1 .responsive-table-container .responsive-table-row .responsive-table-details:nth-child(6) {
    width: 20%;
  }
  .review-list-container .button-set .button-options:nth-child(1) {
    display: flex;
    width: 35%;
  }
  .review-list-container .button-set .button-options:nth-child(2) {
    width: 44%;
  }
  .review-list-container .button-set .button-options:nth-child(3) {
    width: 21%;
  }
}
