.main-pdf-container {
  color-adjust: exact !important;
  padding-top: 2em;
  padding-bottom: 2em;
}
.main-pdf-container .ipn-pdf-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  min-height: 900px;
  margin: auto;
  width: 100%;
  border: 2px solid #e3ddda;
  max-width: 1024px;
}
.main-pdf-container .ipn-pdf-container > div {
  width: 100%;
}
@media (max-width: 640px) {
  .main-pdf-container .ipn-pdf-container > div {
    padding-bottom: 2em;
  }
}
@media (max-width: 640px) {
  .main-pdf-container .ipn-pdf-container {
    border: none;
  }
}
@media print {
  .main-pdf-container .ipn-pdf-container {
    border: none;
  }
}
.main-pdf-container .ipn-pdf-container hr {
  width: 95%;
  background-color: #535765;
  height: 1px;
  border-radius: 2px;
}
.main-pdf-container .ipn-pdf-container .container-pdf-head {
  margin-top: 2em;
  margin-bottom: 2em;
}
.main-pdf-container .ipn-pdf-container .container-pdf-head h3 {
  color: #048041;
  font-family: 'Roboto-Medium';
  font-size: 24px;
  text-align: center;
}
.main-pdf-container .ipn-pdf-container .container-logoAndDetails {
  display: flex;
  width: 90%;
  margin: auto;
  justify-content: space-between;
}
@media (max-width: 640px) {
  .main-pdf-container .ipn-pdf-container .container-logoAndDetails {
    flex-direction: column;
  }
}
@media print {
  .main-pdf-container .ipn-pdf-container .container-logoAndDetails {
    flex-direction: row;
  }
}
.main-pdf-container .ipn-pdf-container .container-logoAndDetails .logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 55%;
}
.main-pdf-container .ipn-pdf-container .container-logoAndDetails .logo-container .ESB-logo {
  width: 100%;
  max-width: 200px;
}
@media (max-width: 640px) {
  .main-pdf-container .ipn-pdf-container .container-logoAndDetails .logo-container {
    width: 100%;
    justify-content: center;
  }
}
@media print {
  .main-pdf-container .ipn-pdf-container .container-logoAndDetails .logo-container {
    width: 40%;
    justify-content: flex-start;
    align-items: flex-start;
  }
}
.main-pdf-container .ipn-pdf-container .container-logoAndDetails .application-details {
  display: grid;
  grid-template-columns: auto auto;
  width: 45%;
  line-height: 1.8em;
}
@media (max-width: 640px) {
  .main-pdf-container .ipn-pdf-container .container-logoAndDetails .application-details {
    margin-top: 1em;
    width: 100%;
    justify-content: center;
  }
}
@media print {
  .main-pdf-container .ipn-pdf-container .container-logoAndDetails .application-details {
    width: 60%;
    margin-top: 0;
    justify-content: center;
  }
}
.main-pdf-container .ipn-pdf-container .container-logoAndDetails .application-details .application-details-title {
  text-align: right;
  font-family: 'Roboto-Bold';
  font-size: 16px;
  font-weight: bold;
}
@media (max-width: 640px) {
  .main-pdf-container .ipn-pdf-container .container-logoAndDetails .application-details .application-details-title {
    text-align: left;
  }
}
.main-pdf-container .ipn-pdf-container .container-logoAndDetails .application-details .application-details-title span {
  color: #333131;
}
.main-pdf-container .ipn-pdf-container .container-logoAndDetails .application-details .application-data {
  padding-left: 8px;
  text-align: left;
  font-family: 'Roboto-Regular';
  font-size: 16px;
  color: #535765;
}
.main-pdf-container .ipn-pdf-container .container-IPNDetails {
  width: 90%;
  text-align: left;
  margin: auto;
}
.main-pdf-container .ipn-pdf-container .container-IPNDetails .IPNDetails {
  text-align: left;
  font-family: 'Roboto-Bold';
  font-size: 16px;
  color: #333131;
  font-weight: bold;
}
.main-pdf-container .ipn-pdf-container .pdf-table-container {
  width: 90%;
  margin: auto;
  display: table;
  border: 1px solid #e3ddda;
  margin-top: 1em;
  text-align: left;
  font-size: 14px;
}
.main-pdf-container .ipn-pdf-container .pdf-table-container .pdf-table-header {
  display: table-header-group;
}
.main-pdf-container .ipn-pdf-container .pdf-table-container .pdf-table-header .head-details-cell {
  display: table-cell;
  padding: 1em;
  font-family: 'Roboto-Medium';
  color: #333131;
}
.main-pdf-container .ipn-pdf-container .pdf-table-container .pdf-table-row {
  display: table-row-group;
}
.main-pdf-container .ipn-pdf-container .pdf-table-container .pdf-table-row:nth-child(2n) {
  background-color: #f4f4f4;
}
.main-pdf-container .ipn-pdf-container .pdf-table-container .pdf-table-row .details-cell {
  display: table-cell;
  font-family: 'Roboto-Regular';
  padding: 1em;
}
.main-pdf-container .ipn-pdf-container .pdf-table-container .pdf-table-total-row {
  color: #333131;
}
.main-pdf-container .ipn-pdf-container .container-approversNote {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.main-pdf-container .ipn-pdf-container .container-approversNote .approversNote-title {
  text-align: left;
  font-family: 'Roboto-Bold';
  color: #333131;
  font-size: 16px;
  width: 90%;
}
.main-pdf-container .ipn-pdf-container .container-approversNote .approversNote {
  width: 90%;
  border: 1px solid #e3ddda;
  background-color: #f4f4f4;
  margin-top: 10px;
  max-width: 100%;
  height: 100%;
  text-align: justify;
}
.main-pdf-container .ipn-pdf-container .container-approversNote .approversNote p {
  font-family: 'Roboto-Regular';
  padding: 1em;
}
.main-pdf-container .ipn-pdf-container .container-total {
  border: 1px solid black;
}
.main-pdf-container .ipn-pdf-container .container-notes {
  width: 90%;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 2em;
}
.main-pdf-container .ipn-pdf-container .container-notes .notes-title {
  text-align: left;
  font-family: 'Roboto-Bold';
  color: #333131;
}
.main-pdf-container .ipn-pdf-container .container-notes .notes {
  width: auto;
  font-family: 'Roboto-Light';
  text-align: left;
  padding-top: 0.5em;
  margin: auto;
  color: #333131;
}
.main-pdf-container .ipn-pdf-container .container-notes .notes li {
  line-height: 24px;
  font-size: 14px;
}
.main-pdf-container .ipn-pdf-container .container-logo-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}
.main-pdf-container .ipn-pdf-container .footer-logo-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #333131;
  width: 200px;
}
.main-pdf-container .ipn-pdf-container .footer-logo-container span {
  font-family: 'Roboto-Medium';
}
.main-pdf-container .ipn-pdf-container .autoGeneratedText {
  margin-top: 2em;
  font-family: 'Roboto-Regular';
  font-size: 14px;
  text-align: center;
}
.main-pdf-container .ipn-pdf-container .pageNumber {
  text-align: right;
  margin-right: 10px;
  margin-bottom: 10px;
  font-family: 'Roboto-Regular';
  font-size: 16px;
}
.main-pdf-container .ipn-pdf-container .footer-logo {
  max-width: 100px;
  margin: auto;
}
.main-pdf-container .ipn-pdf-container .total-title {
  font-family: 'Roboto-Medium';
}
.main-pdf-container .ipn-pdf-container .pdf-footer {
  padding-bottom: 2em;
}
