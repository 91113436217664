.program-list-container {
  padding: 0 0 20px 40px;
  color: #001737;
  border: 1px solid #f2f2f2;
}
@media (max-width: 1024px) {
  .program-list-container {
    padding: 1em;
  }
}
.program-list-container .program-details-row:nth-child(odd) {
  background: #F8F7F6;
}
.program-list-container .program-details-list {
  max-height: 60vh;
  overflow: overlay;
  position: relative;
}
.program-list-container .program-details-row {
  display: flex;
  flex-direction: column;
}
.program-list-container .program-details-row.program-details-header {
  display: flex;
  min-height: 50px;
  align-items: center;
  background: none;
}
@media (max-width: 1024px) {
  .program-list-container .program-details-row.program-details-header {
    display: none;
  }
}
.program-list-container .program-details-row.program-details-header .program-details {
  font-family: 'Roboto-Medium';
  font-size: 16px;
}
.program-list-container .program-details-row .program-details {
  display: flex;
  width: 100%;
  min-height: 50px;
  align-items: center;
  font-family: 'Roboto-Light';
  font-size: 14px;
  word-wrap: break-word;
}
.program-list-container .program-details-row .program-details label {
  display: none;
  font-weight: bold;
  margin-right: 1em;
  width: 100px;
}
@media (max-width: 1024px) {
  .program-list-container .program-details-row .program-details label {
    display: inline-block;
  }
}
@media (max-width: 1024px) {
  .program-list-container .program-details-row .program-details {
    flex-direction: column;
    padding: 1em;
    width: auto;
  }
}
.program-list-container .program-details-row .program-details div {
  padding: 0 15px;
  display: flex;
  width: 16.66%;
}
@media (max-width: 1024px) {
  .program-list-container .program-details-row .program-details div {
    width: 100% !important;
    padding: 5px 0;
  }
}
@media (max-width: 500px) {
  .program-list-container .program-details-row .program-details div {
    padding: 2px 0;
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .program-list-container .program-details-row .program-details div {
    flex-direction: column;
  }
}
.program-list-container .program-details-row .program-details .program-navigation-link {
  background: #C00000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  padding: 0.5em 1em;
  font-size: 10px;
  color: #FFF;
  border-radius: 5px;
}
.program-list-container .program-details-row .program-details .program-navigation-link.disable-navigation {
  color: #686C6D;
  background: #E3DDDA;
}
.program-list-container .program-details-row .program-details .program-navigation-link.disable-navigation svg path {
  fill: #686C6D;
}
.program-list-container .program-details-row .program-details .program-navigation-link:hover {
  opacity: 0.7;
}
