.admin-verify-container {
  width: 90%;
  max-width: 1024px;
  margin: auto;
  min-height: 82vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.admin-verify-container .logo {
  width: 430px;
  height: 135px;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url('../../../images/ESBVectorLogoWithSlogan.svg');
}
@media (max-width: 768px) {
  .admin-verify-container .logo {
    width: 260px;
    height: 80px;
  }
}
.admin-verify-container .section-header {
  padding-top: 4.5em;
  padding-bottom: 1.5em;
}
@media (max-width: 768px) {
  .admin-verify-container .section-header {
    padding-top: 2.5em;
    padding-bottom: 1em;
  }
}
.admin-verify-container .section-header .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 36px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  margin: auto;
}
@media (max-width: 768px) {
  .admin-verify-container .section-header .section-title {
    font-size: 24px;
  }
}
.admin-verify-container .container-box {
  display: flex;
  width: 100%;
  flex-direction: column;
}
@media (max-width: 768px) {
  .admin-verify-container .container-box {
    max-width: 350px;
    padding: 0;
  }
}
.admin-verify-container .container-box .section-overview {
  font-size: 20px;
  color: #048041;
}
@media (max-width: 768px) {
  .admin-verify-container .container-box .section-overview {
    text-align: center;
  }
}
.admin-verify-container .container-box .section-overview h3 {
  font-size: 24px;
  margin: 10px 0;
}
@media (max-width: 768px) {
  .admin-verify-container .container-box .section-overview h3 {
    text-align: center;
  }
}
.admin-verify-container .container-box .form-section {
  display: flex;
  flex-direction: row;
  flex: 50%;
  margin: 2em 0;
}
@media (max-width: 768px) {
  .admin-verify-container .container-box .form-section {
    flex-direction: column;
    align-items: center;
  }
}
.admin-verify-container .container-box .form-section .input-container {
  text-align: right;
}
@media (max-width: 768px) {
  .admin-verify-container .container-box .form-section .input-container {
    text-align: left;
    flex-direction: column;
  }
}
.admin-verify-container .container-box .form-section .input-container .input-label {
  display: none;
}
.admin-verify-container .container-box .form-section .input-container .input-box-pass {
  width: 300px;
  height: 30px;
}
@media (max-width: 768px) {
  .admin-verify-container .container-box .form-section .input-container .input-box-pass {
    width: 240px;
  }
}
.admin-verify-container .container-box .verify-btn {
  width: 100%;
  margin: 10px auto;
  max-width: 300px;
  background-size: 22px;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 75%;
  background-image: url('../../../images/LoginWhite.svg');
}
.admin-verify-container .container-box .resend-links {
  color: #048041;
  font-size: 16px;
  display: flex;
  justify-content: center;
  margin: 10px 20px;
  cursor: pointer;
  align-items: center;
}
@media (max-width: 768px) {
  .admin-verify-container .container-box .resend-links {
    flex-wrap: wrap;
  }
}
.admin-verify-container .container-box .resend-links span {
  text-decoration: underline;
  font-weight: bold;
  margin: 0 5px;
}
