.flag-filter {
  background-size: 18px !important;
  background-image: url('../../../images/FlagFilterIcon.svg') !important;
}
.port-customer-icon {
  background-image: url('../../../images/ReinstateUserVector.svg');
}
.edit-icon {
  background-image: url('../../../images/EditDocVector.svg');
}
.review-icon {
  background-image: url('../../../images/ReviewVector.svg');
}
.status-log-icon {
  background-image: url('../../../images/StatusLog.svg');
}
.assign-icon {
  background-image: url('../../../images/ReviewAssign.svg');
  background-size: 36px;
  background-position-x: 0;
}
.dollar-icon {
  background-image: url('../../../images/DollarIcon.svg');
  background-size: 36px;
  background-position-x: 0;
}
.view-icon {
  background-image: url('../../../images/ApplicationVector.svg');
}
.doc-icon {
  background-image: url('../../../images/DocumentVector.svg');
}
.notes-icon {
  background-image: url('../../../images/NotesIcon.svg');
}
.flag-icon {
  background-image: url('../../../images/FlagVector.svg');
}
.suspend-icon {
  background-image: url('../../../images/SuspendUserVector.svg');
}
.reinstate-icon {
  background-image: url('../../../images/ReinstateUserVector.svg');
}
.mail-icon {
  background-image: url('../../../images/MailVector.svg');
}
.applications-top-bar {
  display: flex;
  flex-direction: row;
  flex: 100%;
}
@media (max-width: 1250px) {
  .applications-top-bar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.applications-top-bar .appstate-details {
  display: grid;
  width: 300px;
  grid-template-columns: 35% 65%;
  align-items: center;
}
@media (max-width: 1024px) {
  .applications-top-bar .appstate-details {
    width: 100%;
    grid-template-columns: 15% 35%;
    justify-content: center;
  }
}
@media (max-width: 690px) {
  .applications-top-bar .appstate-details {
    width: 100%;
    grid-template-columns: 18% 82%;
    justify-content: center;
  }
}
.applications-top-bar .appstate-details .appstate-text {
  font-size: 14px;
  margin-left: 8px;
  color: #048041;
}
@media (max-width: 896px) {
  .applications-top-bar .appstate-details .appstate-text {
    font-size: 18px;
  }
}
.applications-top-bar .appstate-details .input-parent {
  margin: 15px 0px;
}
@media (max-width: 896px) {
  .applications-top-bar .appstate-details .input-parent .switch-container {
    margin: 0;
  }
}
.applications-top-bar .appstate-details .input-parent .switch-container .switch-box .switch-value {
  display: none;
}
.applications-top-bar .applications-top-bar-search {
  display: flex;
  flex-direction: row;
  flex: 60% 0;
  width: 100%;
  max-width: 450px;
}
@media (max-width: 690px) {
  .applications-top-bar .applications-top-bar-search {
    margin: unset;
    max-width: unset;
  }
}
@media (max-width: 890px) {
  .applications-top-bar .applications-top-bar-search {
    margin: auto;
    max-width: 420px;
    width: 100%;
  }
}
.applications-top-bar .add-participant {
  width: 23px;
  height: 20px;
  background-color: #048041;
  background-repeat: no-repeat;
  padding: 10px 20px;
  margin: 10px 0;
  background-position: center;
  border-radius: 4px;
  cursor: pointer;
  background-image: url('../../../images/AddUserVector.svg');
}
.applications-top-bar .add-participant:hover {
  opacity: 0.7;
}
.applications-top-bar .applications-top-bar-filter {
  justify-content: center;
  display: flex;
  flex: 0 40%;
  margin: 0 20px 0 0;
  position: relative;
  width: 100%;
  max-width: 450px;
}
@media (max-width: 690px) {
  .applications-top-bar .applications-top-bar-filter {
    margin: unset;
  }
}
.applications-top-bar .applications-top-bar-filter .filter-component {
  width: 100%;
}
.applications-top-bar .applications-top-bar-filter .filter-btn {
  display: flex;
  width: 250px;
  justify-content: space-between;
}
@media (max-width: 1024px) {
  .applications-top-bar .applications-top-bar-filter .filter-btn {
    justify-content: center;
  }
}
.applications-top-bar .applications-top-bar-filter-btn {
  display: flex;
  flex: 0 40%;
  margin: 0 20px 0 0;
  position: relative;
  width: 100%;
  max-width: 450px;
}
@media (max-width: 690px) {
  .applications-top-bar .applications-top-bar-filter-btn {
    margin: unset;
    margin-bottom: 20px;
  }
}
.applications-top-bar .applications-top-bar-filter-btn .filter-component {
  width: 100%;
}
.applications-top-bar .applications-top-bar-filter-btn .filter-btn {
  display: flex;
  width: 250px;
  justify-content: space-between;
}
@media (max-width: 896px) {
  .applications-top-bar .applications-top-bar-filter-btn .filter-btn {
    width: 100%;
    margin: 0px;
    font-family: 'Roboto-Regular';
    font-size: 16px !important;
  }
}
.assign-reviewer-modal {
  height: auto;
  padding: 0;
}
.assign-reviewer-modal h3 {
  color: #048041;
  font-size: 20px;
  border-bottom: 1px solid #048041;
  padding-bottom: 10px;
  text-align: center;
}
.assign-reviewer-modal p {
  font-size: 18px;
  font-family: 'Roboto-Light';
}
.assign-reviewer-modal p span {
  color: #048041;
  font-family: 'Roboto-Medium';
}
.assign-reviewer-modal .input-container {
  margin: 0;
  flex-direction: column;
  padding: 0;
}
.assign-reviewer-modal .input-container .input-label {
  text-align: left;
  width: 100%;
  padding: 10px 0;
}
.assign-reviewer-modal .input-container .input-box {
  width: auto;
}
.assign-reviewer-modal .input-container .disabled-input input {
  cursor: not-allowed;
}
.assign-reviewer-modal .button-set {
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
  flex-direction: column;
}
.assign-reviewer-modal .button-set .enerva-btn {
  margin: 10px 0;
  display: flex;
  flex: 50%;
  justify-content: center;
  margin-right: 0px;
  background: #048041;
}
.assign-reviewer-modal .button-set .enerva-btn.inversed-btn {
  background: #ffffff;
  margin-right: 10px;
  color: #048041;
  border: 1px solid #048041;
}
.filter-drawer {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.filter-drawer h3 {
  color: #048041;
  font-size: 20px;
  border-bottom: 1px solid #048041;
  padding-bottom: 10px;
  text-align: center;
}
.filter-drawer .all-filters {
  flex: 1;
  max-height: 75vh;
  overflow-y: auto;
}
.filter-drawer .all-filters .filter {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.filter-drawer .all-filters .filter .appstate-details {
  display: grid;
  width: 300px;
  grid-template-columns: 35% 65%;
  align-items: center;
}
@media (max-width: 1024px) {
  .filter-drawer .all-filters .filter .appstate-details {
    width: 100%;
    grid-template-columns: 15% 35%;
    justify-content: center;
  }
}
@media (max-width: 690px) {
  .filter-drawer .all-filters .filter .appstate-details {
    width: 100%;
    grid-template-columns: 18% 82%;
    justify-content: center;
  }
}
.filter-drawer .all-filters .filter .appstate-details .appstate-text {
  font-size: 14px;
  margin-left: 8px;
  color: #048041;
}
@media (max-width: 896px) {
  .filter-drawer .all-filters .filter .appstate-details .appstate-text {
    font-size: 18px;
  }
}
.filter-drawer .all-filters .filter .appstate-details .input-parent {
  margin: 15px 0px;
}
@media (max-width: 896px) {
  .filter-drawer .all-filters .filter .appstate-details .input-parent .switch-container {
    margin: 0;
  }
}
.filter-drawer .all-filters .filter .appstate-details .input-parent .switch-container .switch-box .switch-value {
  display: none;
}
.filter-drawer .all-filters .filter .filter-label {
  font-family: 'Roboto-Medium';
  color: #048041;
  font-size: 18px;
  margin: 10px 0px;
}
.filter-drawer .all-filters .filter .filter-options {
  max-height: 25vh;
  overflow-y: auto;
}
.filter-drawer .all-filters .filter .filter-options label {
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filter-drawer .all-filters .filter .filter-options label span svg {
  width: 10px;
}
.filter-drawer .all-filters .filter .filter-options label input {
  accent-color: #048041;
  height: 16px;
  width: 16px;
  margin-right: 10px;
}
.filter-drawer .all-filters .filter .filter-options label input:hover {
  accent-color: #048041;
  opacity: 0.7;
}
@media (max-width: 896px) {
  .filter-drawer .all-filters .filter .filter-options label input:hover {
    opacity: none;
  }
}
.program-dashboard-container .program-dashboard {
  flex-direction: column;
  max-width: 81%;
}
@media (max-width: 1024px) {
  .program-dashboard-container .program-dashboard {
    max-width: 100%;
  }
}
.program-dashboard-container .program-dashboard .app-report {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 99%;
}
@media (max-width: 769px) {
  .program-dashboard-container .program-dashboard .app-report {
    margin-top: 20px;
  }
}
.program-dashboard-container .program-dashboard .app-report .report-head {
  display: flex;
  flex-direction: row;
  gap: 30px;
  margin: 0 5px;
  align-items: center;
}
.program-dashboard-container .program-dashboard .app-report .report-head .head {
  font-size: 16px;
  color: #048041;
  font-family: 'Roboto-Medium';
}
@media (max-width: 769px) {
  .program-dashboard-container .program-dashboard .app-report .report-head .head {
    margin-left: 20px;
  }
}
.program-dashboard-container .program-dashboard .app-report .report-head .program-select {
  display: flex;
  width: 130px;
  border-radius: 5px;
  position: relative;
  flex-direction: column;
  cursor: pointer;
}
@media (max-width: 1000px) {
  .program-dashboard-container .program-dashboard .app-report .report-head .program-select {
    width: 70px;
  }
}
.program-dashboard-container .program-dashboard .app-report .report-head .program-select .select-box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.program-dashboard-container .program-dashboard .app-report .report-head .program-select .select-box .selected-opt {
  display: flex;
  flex-direction: column;
  padding: 5px 10px 5px 0;
  color: #048041;
  font-family: 'Roboto-Medium';
  font-size: 14px;
}
.program-dashboard-container .program-dashboard .app-report .report-head .program-select .expand-profile-menu {
  display: none;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 25px;
  background: #f1eeec;
  left: 0;
  border: 1px solid #f1eeec;
  text-align: right;
  border-radius: 0 0 5px 5px;
  box-shadow: 0px 10px 10px 0px #CCCCB8;
  color: #001737;
  z-index: 1;
}
.program-dashboard-container .program-dashboard .app-report .report-head .program-select .expand-profile-menu.profile-menu-open {
  display: block;
}
.program-dashboard-container .program-dashboard .app-report .report-head .program-select .expand-profile-menu .expanded-menu-panel div {
  padding: 5px 20px;
  font-size: 14px;
  text-align: center;
  font-family: 'Roboto-Medium';
  border-top: 1px solid #e6e5e5;
}
@media (max-width: 1000px) {
  .program-dashboard-container .program-dashboard .app-report .report-head .program-select .expand-profile-menu .expanded-menu-panel div {
    padding: 0.5em 1em;
  }
}
.program-dashboard-container .program-dashboard .app-report .report-head .program-select .expand-profile-menu .expanded-menu-panel div:hover {
  opacity: 0.7;
}
.program-dashboard-container .program-dashboard .app-report .swiper .swiper-wrapper {
  display: flex;
  max-width: 100%;
}
.program-dashboard-container .program-dashboard .app-report .swiper .swiper-wrapper .swiper-slide {
  width: 225px !important;
  height: 175px;
}
.program-dashboard-container .program-dashboard .horizontal-bar {
  height: 0px;
  border-bottom: 1px solid #e3ddda;
  margin: 2em 0em;
}
