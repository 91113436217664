.new-window {
  padding: 20px;
}
.new-window h3 {
  color: #048041;
  text-align: center;
}
.new-window .new-window-container .MuiPaper-root {
  max-height: none;
}
