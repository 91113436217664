.view-requests-inside-apps-container .back-button {
  width: 100%;
  position: absolute;
  cursor: pointer;
  left: -48%;
  top: 8%;
  appearance: none;
  background: transparent;
  border: none;
  display: block;
  font-size: 16px;
  font-weight: normal;
  font-family: 'Roboto-Regular';
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5;
}
.view-requests-inside-apps-container .back-button img {
  padding-right: 5px;
  width: 6px;
}
.applicant-projects-container {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 16px;
  flex: 100%;
}
@media (max-width: 1024px) {
  .applicant-projects-container {
    padding: 0;
    margin: 0;
    flex: 100%;
    min-height: 360px;
  }
}
.applicant-projects-container .container-box {
  width: auto;
  overflow-x: hidden;
  overflow-y: unset;
  border: 0.5px solid #98989A;
  padding: 0em;
  max-height: 500px;
  text-align: center;
}
@media (max-width: 415px) {
  .applicant-projects-container .container-box {
    max-height: 350px;
    overflow-y: auto;
  }
}
@media (max-width: 1024px) {
  .applicant-projects-container .container-box {
    width: 100%;
    border: none;
  }
  .applicant-projects-container .container-box::-webkit-scrollbar {
    display: none;
  }
}
.applicant-projects-container .container-box .responsive-table-container {
  border: none;
}
.applicant-projects-container .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .applicant-projects-container .section-title {
    font-size: 16px;
  }
}
.applicant-projects-container .section-header {
  display: flex;
  flex-direction: revert;
  justify-content: space-around;
  padding: 1em 0;
  align-items: center;
}
@media (max-width: 1024px) {
  .applicant-projects-container .section-header {
    padding: 10px 0;
  }
}
.applicant-projects-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.applicant-projects-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.applicant-projects-container .tabs {
  width: 100%;
}
@media (max-width: 1024px) {
  .applicant-projects-container .tabs {
    margin: 10px auto;
  }
}
.applicant-projects-container .tabs .tab-list {
  width: 100%;
  display: flex;
}
@media (max-width: 1024px) {
  .applicant-projects-container .tabs .tab-list {
    margin: 0 0 10px 0;
  }
}
