@media (max-width: 896px) {
  .modal-content h3 {
    font-size: 18px;
  }
}
.add-user-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.add-user-container h3 {
  color: #C00000;
}
@media (max-width: 1024px) {
  .add-user-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
  }
}
.add-user-container .section-header {
  padding-bottom: 1em;
}
@media (max-width: 1024px) {
  .add-user-container .section-header {
    padding: 10px 0;
  }
}
.add-user-container .section-header .section-title {
  text-align: center;
  color: #C00000;
  text-transform: uppercase;
  font-size: 20px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  margin: auto;
}
@media (max-width: 1024px) {
  .add-user-container .section-header .section-title {
    font-size: 18px;
  }
}
.add-user-container .container-box {
  width: 96%;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #CCC;
  padding: 2%;
  text-align: center;
}
@media (max-width: 1024px) {
  .add-user-container .container-box {
    max-height: 320px;
    overflow: auto;
  }
}
@media (max-width: 896px) {
  .add-user-container .container-box {
    border: 0.5px solid #f2f2f2;
    max-height: 340px;
  }
}
@media (max-width: 321px) {
  .add-user-container .container-box {
    max-height: 280px;
  }
}
.add-user-container .container-box .vertical-separator-box {
  display: flex;
  opacity: 0.5;
  flex: 2%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.add-user-container .container-box .vertical-separator-box .separator {
  background-color: #C00000;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32%;
  height: 77%;
  border: none;
}
@media (max-width: 896px) {
  .add-user-container .container-box .vertical-separator-box {
    display: none;
  }
}
.add-user-container .container-box .form-sections {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
@media (max-width: 896px) {
  .add-user-container .container-box .form-sections {
    flex-direction: column;
  }
}
.add-user-container .container-box .form-sections .form-section {
  display: flex;
  flex-direction: column;
  flex: 44%;
}
@media (max-width: 896px) {
  .add-user-container .container-box .form-sections .form-section {
    flex: unset;
  }
}
.add-user-container .container-box .form-sections .form-section .input-container {
  margin-top: 10px;
  align-items: center;
}
@media (max-width: 415px) {
  .add-user-container .container-box .form-sections .form-section .input-container .button-selection-container .button-selection-list {
    flex-direction: column;
  }
}
@media (max-width: 415px) {
  .add-user-container .container-box .form-sections .form-section .input-container .button-selection-container .button-selection-list .btn-selection {
    min-width: 230px;
  }
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box {
  width: 56% !important;
  font-size: 14px !important;
  height: 26px;
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box.enerva-multiselect {
  height: auto;
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box.enerva-multiselect .enerva-multiselect__placeholder {
  color: #C00000;
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box.enerva-multiselect .enerva-multiselect__indicator svg {
  color: #C00000;
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box.enerva-multiselect .enerva-multiselect__menu {
  border: #C00000;
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box.enerva-multiselect .enerva-multiselect__control {
  border: #C00000;
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box.enerva-multiselect .enerva-multiselect__multi-value {
  background: #C00000;
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box.enerva-multiselect .enerva-multiselect__option {
  color: #C00000;
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box.enerva-multiselect .enerva-multiselect__option--is-focused {
  background-color: rgba(142, 0, 0, 0.25);
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box.enerva-multiselect .enerva-multiselect__option--is-selected {
  background-color: rgba(142, 0, 0, 0.25);
  color: #C00000;
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box.react-tel-input,
.add-user-container .container-box .form-sections .form-section .input-container .input-box.location-dropdown {
  width: 61.5% !important;
  height: 40px;
}
@media (max-width: 1024px) {
  .add-user-container .container-box .form-sections .form-section .input-container .input-box.react-tel-input,
  .add-user-container .container-box .form-sections .form-section .input-container .input-box.location-dropdown {
    width: 96% !important;
    padding: 0;
  }
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box.enerva-dropdown {
  width: 62.5% !important;
  height: 40px;
}
@media (max-width: 1024px) {
  .add-user-container .container-box .form-sections .form-section .input-container .input-box.enerva-dropdown {
    width: 98% !important;
    padding: 0;
  }
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box .enerva-dropdown__control {
  background: #C00000;
  border: 1px solid #C00000;
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box .enerva-dropdown__option--is-focused {
  background-color: rgba(142, 0, 0, 0.25);
}
.add-user-container .container-box .form-sections .form-section .input-container .input-box .enerva-dropdown__option--is-selected {
  background-color: rgba(142, 0, 0, 0.25);
  color: #C00000;
}
@media (max-width: 1024px) {
  .add-user-container .container-box .form-sections .form-section .input-container .input-box {
    width: 92% !important;
    height: 30px;
    padding: 2%;
    margin: 2%;
  }
}
.add-user-container .container-box .form-sections .form-section .input-container .input-label {
  flex: 44% 0;
  padding: 0 5px;
  color: #98989A;
}
@media (max-width: 1024px) {
  .add-user-container .container-box .form-sections .form-section .input-container .input-label {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .add-user-container .container-box .form-sections .form-section .input-container {
    margin-top: 5px;
  }
}
.add-user-container .container-box .form-sections .form-section .switch-container {
  margin: 10px 0 0 0;
  width: 100%;
}
.add-user-container .container-box .form-sections .form-section .switch-container .label-container {
  display: flex;
  flex: 46% 0;
  padding: 0 5px;
  margin: 0 0.1em 0 0;
}
.add-user-container .container-box .form-sections .form-section .switch-container .label-container p {
  font-size: 18px;
  font-family: 'Roboto-Regular';
  padding: 0;
  margin: 0;
}
.add-user-container .container-box .form-sections .form-section .switch-container .switch-box {
  flex: 0 64%;
}
.add-user-container .container-box .form-sections .form-section .switch-container .switch-box .react-switch-bg {
  background-color: #C00000 !important;
}
.add-user-container .container-box .user-details-container {
  display: flex;
  flex-direction: row;
  color: #333131;
}
@media (max-width: 1024px) {
  .add-user-container .container-box .user-details-container {
    flex-direction: column;
  }
}
.add-user-container .container-box .user-details-container .user-details-container-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  flex: 48% 0;
  font-size: 16px;
}
.add-user-container .container-box .user-details-container .user-details-container-left .user-details-row {
  display: flex;
  flex-direction: row;
  flex: 100%;
  padding: 0.5em 1em;
  width: 92%;
}
@media (max-width: 1024px) {
  .add-user-container .container-box .user-details-container .user-details-container-left .user-details-row {
    flex-direction: column;
  }
}
.add-user-container .container-box .user-details-container .user-details-container-left .user-details-row label {
  min-width: 180px;
  text-align: left;
  font-family: 'Roboto-Medium';
}
.add-user-container .container-box .user-details-container .user-details-container-left .user-details-row span {
  text-align: left;
}
.add-user-container .container-box .user-details-container .user-details-container-left .user-details-row:nth-child(odd) {
  background: #F8F7F6;
}
.add-user-container .container-box .user-details-container .user-details-container-right {
  display: flex;
  flex: 0 1 48%;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.add-user-container .container-box .user-details-container .user-details-container-right p {
  font-size: 16px;
  font-family: 'Roboto-Regular';
  padding: 0 2em;
  color: #333131;
  text-align: left;
  margin: 10px 0;
}
.add-user-container .container-box .user-details-container .user-details-container-right p b {
  color: #C00000;
}
.add-user-container .enerva-btn {
  background-color: #C00000;
}
.add-user-container .enerva-btn.inversed-btn {
  background: #FFFFFF;
  border: 1px solid #C00000;
  color: #C00000;
  width: 100%;
  margin-top: 25px;
  max-width: 250px;
  margin-left: auto;
  margin-right: 25px;
  text-align: center;
  float: right;
}
@media (max-width: 1024px) {
  .add-user-container .enerva-btn.inversed-btn {
    max-width: unset;
  }
}
.add-user-container .signup-btn {
  width: 100%;
  margin-top: 25px;
  max-width: 250px;
  margin-left: auto;
  margin-right: 25px;
  text-align: center;
  float: right;
}
@media (max-width: 1024px) {
  .add-user-container .signup-btn {
    max-width: unset;
  }
}
.add-user-container .confirm-btn-container {
  display: flex;
  width: 50%;
  margin: 0 auto;
}
@media (max-width: 1024px) {
  .add-user-container .confirm-btn-container {
    flex-direction: column;
    min-width: 180px;
  }
}
