.file-upload-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1em;
  cursor: pointer;
}
.file-upload-container .error-message {
  width: 100%;
  font-size: 14px;
  display: flex;
  justify-content: center;
  text-align: center;
  padding: 0;
}
.file-upload-container .file-upload-loader {
  flex-direction: column;
  height: 100%;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
.file-upload-container .file-upload-loader img {
  width: 70%;
  max-width: 300px;
  animation: spin 3s linear infinite;
  margin: 20px 0;
}
.file-upload-container .file-dropzone {
  width: 100%;
  background: #E3DDDA;
  padding: 2em 1em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  outline: none;
}
.file-upload-container .file-dropzone input {
  outline: #048041;
}
.file-upload-container .file-dropzone p {
  font-family: 'Roboto-Light';
  font-size: 16px;
  color: #001737;
}
.file-upload-container .file-dropzone p b {
  color: #048041;
}
.disabled-file-upload-container {
  opacity: 0.5;
  pointer-events: none;
}
