.input-parent {
  width: 100%;
}
.input-parent .search-box {
  text-align: left;
}
.input-parent .passwordReqs {
  padding-left: 46%;
  list-style: none;
  font-size: 12px;
}
@media (max-width: 896px) {
  .input-parent .passwordReqs {
    padding-left: 0;
  }
}
.input-parent .passwordReqs li {
  text-align: left;
  list-style: none;
}
.input-parent .passwordReqs li .success-icon {
  display: inline-block;
}
.input-parent .passwordReqs li .success-icon img {
  width: 8px;
}
.input-parent .passwordReqs .error-line {
  color: #DB1900;
}
.input-parent .passwordReqs .success-line {
  color: #048041;
}
.input-parent .disable-input-container .quill .ql-editor {
  cursor: not-allowed;
  background-color: #DBDBDB;
}
.input-parent .input-container {
  display: flex;
  position: relative;
  width: 100%;
}
.input-parent .input-container .disabled-input {
  background-color: #E3DDDA;
  box-shadow: none;
  color: #1D1D1D;
  display: flex;
}
.input-parent .input-container .disabled-input input {
  background-color: rgba(0, 0, 0, 0) !important;
  width: 100%;
}
.input-parent .input-container .quill {
  width: 100%;
  margin-bottom: 4em;
  max-height: 250px;
}
@media (max-width: 1024px) {
  .input-parent .input-container .quill {
    margin-bottom: 1em;
  }
}
.input-parent .input-container .quill .ql-picker-label {
  padding-right: 1.7em;
}
@media (max-width: 480px) {
  .input-parent .input-container .quill .ql-picker-label {
    padding-right: 0;
  }
}
.input-parent .input-container .quill .ql-editor {
  text-align: left;
}
.input-parent .input-container .quill .ql-editor strong {
  font-family: 'Roboto-Medium';
}
.input-parent .input-container .quill .ql-editor h1,
.input-parent .input-container .quill .ql-editor h2,
.input-parent .input-container .quill .ql-editor h3,
.input-parent .input-container .quill .ql-editor h4,
.input-parent .input-container .quill .ql-editor h5 {
  text-align: left;
  font-family: 'Roboto-Regular';
  color: #686C6D;
}
.input-parent .input-container .quill .ql-editor p {
  text-align: left;
  font-size: 16px;
  color: #686C6D;
}
.input-parent .input-container .input-label {
  font-size: 18px;
  margin: 0 0.1em 0 0;
  width: 44%;
  text-align: right;
  padding: 0.5em 1em;
}
@media (max-width: 896px) {
  .input-parent .input-container .input-label {
    width: 100%;
    text-align: left;
    padding-left: 0px;
    font-size: 16px;
  }
}
.input-parent .input-container .input-label .info-icon {
  margin-right: 10px;
}
@media (max-width: 896px) {
  .input-parent .input-container {
    flex-direction: column;
  }
}
.input-parent .input-container .no-border {
  border-color: rgba(0, 0, 0, 0) !important;
}
.input-parent .input-container input[type='password'] {
  padding-right: 2em;
}
.input-parent .input-container .input-box {
  position: relative;
  width: 56%;
  border: 1px solid #686C6D;
  padding: 0.5em 1em;
  border-radius: 6px;
  font-size: 14px;
  color: #1D1D1D;
}
@media (max-width: 896px) {
  .input-parent .input-container .input-box {
    width: unset;
    text-align: left;
  }
}
.input-parent .input-container .input-box:hover,
.input-parent .input-container .input-box:focus {
  border: 1px solid #686C6D;
  outline: none;
  text-decoration: none;
  box-shadow: 0px 0px 5px #1D1D1D;
}
.input-parent .input-container .input-box[disabled] {
  background-color: #E3DDDA;
  box-shadow: none;
  color: #1D1D1D;
}
.input-parent .input-container .input-box .form-control {
  border: none !important;
  width: 100%;
  height: auto;
  border-radius: 6px;
  height: 100%;
  font-size: 16px;
}
@media (max-width: 896px) {
  .input-parent .input-container .input-box .form-control {
    width: unset;
  }
}
.input-parent .input-container .input-box-pass {
  display: flex;
}
@media (max-width: 896px) {
  .input-parent .input-container .input-box-pass {
    width: unset;
    text-align: left;
  }
}
.input-parent .input-container .input-box-error {
  border: 1px solid #DB1900;
  outline: none;
  text-decoration: none;
  box-shadow: 0px 0px 5px #DB1900;
}
.input-parent .input-container .input-box-success {
  border: 1px solid #048041;
  outline: none;
  text-decoration: none;
  box-shadow: 0px 0px 5px #048041;
}
.input-parent .input-container .react-tel-input {
  padding: 0px !important;
  width: 65.5% !important;
}
.input-parent .input-container .react-tel-input:disabled {
  background-color: #E3DDDA;
  box-shadow: none;
  color: #048041;
}
.input-parent .input-container .react-tel-input.disable-input {
  box-shadow: none;
  border-color: rgba(0, 0, 0, 0) !important;
  color: #048041;
}
.input-parent .input-container .react-tel-input.disable-input .form-control {
  background-color: #E3DDDA;
  color: #048041;
}
.input-parent .input-container .react-tel-input.disable-input .form-control:hover,
.input-parent .input-container .react-tel-input.disable-input .form-control:active,
.input-parent .input-container .react-tel-input.disable-input .form-control:focus {
  box-shadow: none;
  cursor: auto;
}
.input-parent .input-container .react-tel-input.disable-input .flag-dropdown {
  background-color: #E3DDDA;
}
@media (max-width: 896px) {
  .input-parent .input-container .react-tel-input {
    width: 100% !important;
    height: 100%;
    min-height: 36px;
  }
}
@media (max-width: 896px) {
  .input-parent .input-container .react-tel-input input {
    width: 100% !important;
    height: 100%;
    min-height: 36px;
  }
}
.input-parent .input-container .react-tel-input .flag-dropdown {
  border-radius: 6px 0px 0px 6px !important;
  border-color: rgba(0, 0, 0, 0) !important;
}
.input-parent .input-container .location-dropdown {
  width: 65.5%;
  cursor: text;
  padding: 0px;
}
@media (max-width: 896px) {
  .input-parent .input-container .location-dropdown {
    width: 100%;
  }
}
.input-parent .input-container .location-dropdown.disable-input {
  border-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none;
  color: #1D1D1D;
}
.input-parent .input-container .location-dropdown.disable-input img {
  display: none;
}
.input-parent .input-container .location-dropdown.disable-input .location-dropdown__control {
  border-color: rgba(0, 0, 0, 0) !important;
  color: #1D1D1D;
  background-color: #E3DDDA;
}
.input-parent .input-container .location-dropdown.disable-input .location-dropdown__control .location-dropdown__single-value {
  color: #1D1D1D;
}
.input-parent .input-container .location-dropdown .location-dropdown__single-value {
  color: #1D1D1D;
}
.input-parent .input-container .location-dropdown .location-dropdown__control {
  cursor: text;
  height: 100%;
  background: #FFFFFF;
  color: #000000;
  border-radius: 5px;
  border-color: rgba(0, 0, 0, 0);
}
.input-parent .input-container .location-dropdown .location-dropdown__control .location-dropdown__indicators .location-dropdown__indicator-separator {
  display: none;
}
.input-parent .input-container .location-dropdown .location-dropdown__control .location-dropdown__indicators .location-dropdown__indicator svg {
  color: red;
}
.input-parent .input-container .location-dropdown .location-dropdown__control .location-dropdown__value-container {
  padding: 0 1em;
}
.input-parent .input-container .location-dropdown .location-dropdown__control--is-focused {
  outline: none;
  text-decoration: none;
  box-shadow: none;
}
.input-parent .input-container .location-dropdown .location-dropdown__placeholder {
  white-space: nowrap;
  color: #c1c1c4;
}
.input-parent .input-container .location-dropdown .location-dropdown__input {
  caret-color: #1D1D1D;
  color: #1D1D1D;
}
.input-parent .input-container .location-dropdown .location-dropdown__input input {
  height: 100%;
}
.input-parent .input-container .location-dropdown .location-dropdown__input input:focus {
  box-shadow: none;
}
.input-parent .input-container .enerva-radio {
  width: 65.2%;
}
@media (max-width: 896px) {
  .input-parent .input-container .enerva-radio {
    width: auto;
  }
}
.input-parent .input-container .enerva-dropdown {
  width: 68.5%;
  padding: 0px;
}
@media (max-width: 896px) {
  .input-parent .input-container .enerva-dropdown {
    width: 100%;
  }
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__single-value {
  text-align: left;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__input {
  color: #FFFFFF !important;
}
.input-parent .input-container .enerva-dropdown.disable-input {
  border-color: rgba(0, 0, 0, 0) !important;
  box-shadow: none;
  color: #1D1D1D;
}
.input-parent .input-container .enerva-dropdown.disable-input .enerva-dropdown__control {
  border-color: rgba(0, 0, 0, 0) !important;
  background-color: #E3DDDA;
}
.input-parent .input-container .enerva-dropdown.disable-input .enerva-dropdown__control .enerva-dropdown__single-value {
  color: #1D1D1D;
}
.input-parent .input-container .enerva-dropdown.disable-input .enerva-dropdown__control .enerva-dropdown__placeholder {
  color: #686C6D;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__control {
  height: 100%;
  background: #048041;
  border: 1px solid #048041;
  border-radius: 5px;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__control .enerva-dropdown__indicators .enerva-dropdown__indicator-separator {
  display: none;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__control .enerva-dropdown__indicators .enerva-dropdown__indicator svg {
  color: #FFF;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__control .enerva-dropdown__value-container {
  padding: 0 1em;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__control .enerva-dropdown__single-value {
  color: #FFF;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__control--is-focused {
  outline: none;
  text-decoration: none;
  box-shadow: none;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__placeholder {
  white-space: nowrap;
  color: #FFF;
  text-align: left;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__input {
  caret-color: #FFF;
  color: #FFF;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__menu {
  background: #FFF;
  border: 1px solid #b1e4e3;
  margin: 0  !important;
  padding: 0 !important;
  border-radius: 0 0 5px 5px;
  box-shadow: none;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__menu .enerva-dropdown__menu-list {
  padding: 0 !important;
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__menu .enerva-dropdown__option {
  text-align: left;
  padding: 10px 12px !important;
  margin: 0;
  background-color: #FFFFFF;
  font-weight: normal;
  color: #333131;
  font-family: 'Roboto-Medium';
  font-size: 14px;
  cursor: 'default';
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__menu .enerva-dropdown__option--is-focused {
  background-color: rgba(4, 128, 65, 0.25);
}
.input-parent .input-container .enerva-dropdown .enerva-dropdown__menu .enerva-dropdown__option--is-selected {
  background-color: rgba(4, 128, 65, 0.25);
  color: #048041;
}
.input-parent .input-container .enerva-multiselect {
  width: 68.5%;
  padding: 0px;
}
@media (max-width: 896px) {
  .input-parent .input-container .enerva-multiselect {
    width: 100%;
  }
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control {
  height: 100%;
  background: #FFFFFF;
  border: none;
  border-radius: 6px;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__indicators .enerva-multiselect__indicator-separator {
  display: none;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__indicators .enerva-multiselect__indicator svg {
  color: #048041;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__multi-value {
  background-color: #048041;
  padding: 0.3em;
  border-radius: 6px;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__multi-value .enerva-multiselect__multi-value__label {
  color: #FFFFFF;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__multi-value .enerva-multiselect__multi-value__remove {
  color: #FFFFFF;
  margin-left: 0.5em;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__multi-value .enerva-multiselect__multi-value__remove:hover {
  color: #FFFFFF;
  cursor: pointer;
  color: #DBDBDB;
  background-color: rgba(0, 0, 0, 0);
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__value-container {
  padding: 5px;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control .enerva-multiselect__single-value {
  color: #FFF;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__control--is-focused {
  outline: none;
  text-decoration: none;
  box-shadow: none;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__placeholder {
  white-space: nowrap;
  color: #048041;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__input {
  caret-color: #048041;
  color: #048041;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__input input {
  height: 100%;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__input input:focus {
  box-shadow: none;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__menu {
  background: #FFF;
  border: 1px solid #b1e4e3;
  margin: -2px 0 0 0;
  padding: 0;
  border-radius: 0 0 5px 5px;
  box-shadow: none;
  text-align: left;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__menu .react-select__option:first-child {
  padding: 0;
  margin: 0;
}
.input-parent .input-container .enerva-multiselect .enerva-multiselect__menu .react-select__option:last-child {
  padding: 0;
  margin: 0;
}
.input-parent .error-message {
  font-family: 'Roboto-Light';
  font-size: 12px;
  display: flex;
  padding-left: 46%;
  padding-top: 5px;
  margin-top: 0.15em;
  color: #DB1900;
  text-align: left;
  line-height: 12px;
}
@media (max-width: 896px) {
  .input-parent .error-message {
    padding-left: 0;
  }
}
.input-parent .error-message .error-icon {
  position: relative;
  opacity: 1;
  margin-right: 5px;
}
.input-parent .error-message .error-icon img {
  width: 8px;
}
.input-parent .success-message {
  font-size: 12px;
  display: flex;
  padding-left: 46%;
  padding-top: 5px;
  margin-top: 0.15em;
  color: #048041;
  text-align: left;
  line-height: 12px;
}
@media (max-width: 896px) {
  .input-parent .success-message {
    padding-left: 0;
  }
}
.input-parent .success-message .success-icon {
  position: relative;
  opacity: 1;
  margin-right: 5px;
}
.input-parent .success-message .success-icon img {
  width: 8px;
}
.input-parent .button-selection-container {
  display: flex;
  flex: 0 62%;
}
.input-parent .multi-select-checkbox-component {
  display: flex;
  flex: 0 62%;
}
.input-parent .date-picker {
  flex: 0 60%;
  border-bottom: 1px solid #C00000;
}
.input-parent .date-picker svg {
  fill: #C00000;
}
.input-parent .file-upload-container {
  flex: 0 56%;
}
.input-parent .file-upload-container .file-dropzone svg path {
  fill: #686C6D;
}
.input-parent .file-upload-container .file-dropzone p b {
  fill: #686C6D;
}
.input-parent .switch-container {
  display: flex;
  flex: 0 62%;
}
