.assign-labels-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: hidden;
}
.assign-labels-container .color-picker {
  padding-top: 1em;
}
@media (max-width: 1024px) {
  .assign-labels-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
  }
}
@media (max-width: 896px) {
  .assign-labels-container {
    overflow-x: hidden;
    max-width: 90vw;
  }
}
.assign-labels-container .box-subtitle {
  font-family: 'Roboto-Medium' !important;
  font-size: 14px;
  text-align: left;
  padding-left: 10px;
  color: #535765 !important;
}
.assign-labels-container .label-input input {
  font-family: 'Roboto-Medium';
  border: none !important;
}
.assign-labels-container .label-input input::placeholder {
  color: #DBDBDB;
}
.assign-labels-container .label-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
@media (max-width: 1024px) {
  .assign-labels-container .label-list {
    width: 100%;
    justify-content: center;
  }
}
.assign-labels-container .label-tag {
  color: #FFFFFF;
  padding: 0.8em;
  margin: 0.6em;
  text-align: left;
  border-radius: 6px;
  flex: 22%;
  max-width: 250px;
}
@media (max-width: 1024px) {
  .assign-labels-container .label-tag {
    flex: 100%;
    max-width: unset;
  }
}
.assign-labels-container .label-tag img {
  float: right;
  width: 16px;
}
.assign-labels-container .label-tag:hover {
  opacity: 0.7;
  cursor: pointer;
}
.assign-labels-container .button-block {
  display: flex;
  width: 93%;
  flex-direction: row;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .assign-labels-container .button-block {
    width: 100%;
  }
}
.assign-labels-container .button-block .button-set {
  display: flex;
  justify-content: flex-end;
  min-width: 280px;
  margin: 0px;
}
@media (max-width: 1024px) {
  .assign-labels-container .button-block .button-set {
    width: 100%;
  }
}
.assign-labels-container .button-block .button-set .enerva-btn {
  width: auto;
  flex: unset;
  margin-left: 10px;
  margin-right: 0px;
}
@media (max-width: 1024px) {
  .assign-labels-container .button-block .button-set .enerva-btn {
    margin-left: auto;
    margin-right: auto;
  }
}
@media (max-width: 1024px) {
  .assign-labels-container .button-block .button-set .enerva-btn {
    width: 100%;
  }
}
.assign-labels-container .back-button {
  width: 100%;
  text-align: left;
  cursor: pointer;
  appearance: none;
  background: transparent;
  border: none;
  font-size: 16px;
  font-weight: normal;
  font-family: 'Roboto-Regular';
}
.assign-labels-container .back-button img {
  padding-right: 5px;
  width: 6px;
}
.assign-labels-container .selected-labels-container {
  width: 90%;
  padding: 1em;
  overflow-y: auto;
  color: #001737;
  border: 1px solid #f2f2f2;
}
@media (max-width: 1024px) {
  .assign-labels-container .selected-labels-container {
    max-height: 300px;
  }
}
@media (max-width: 415px) {
  .assign-labels-container .selected-labels-container {
    max-height: 280px;
  }
}
@media (max-width: 321px) {
  .assign-labels-container .selected-labels-container {
    max-height: 180px;
  }
}
.assign-labels-container .selected-labels-container .selected-labels-table {
  display: flex;
  flex-direction: row;
  overflow: overlay;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-left {
  display: flex;
  flex-direction: column;
  flex: 65% 0;
  overflow: overlay;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-left .selected-labels-header {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Medium';
  text-align: left;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-left .selected-labels-header .action-detail {
  display: flex;
  align-items: center;
  padding: 0 1em;
  text-align: left;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-left .selected-labels-header .action-detail:nth-child(1) {
  width: 33%;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-left .selected-labels-header .action-detail:nth-child(2) {
  width: 33%;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-left .selected-labels-header .action-detail:nth-child(3) {
  width: 33%;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-left .selected-labels-inner-container {
  max-height: 200px;
  overflow: overlay;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-left .selected-labels-inner-container .selected-labels-details {
  display: flex;
  min-height: 40px;
  justify-content: flex-start;
  align-items: center;
  font-size: 14px;
  font-family: 'Roboto-Regular';
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-left .selected-labels-inner-container .selected-labels-details .action-detail {
  display: flex;
  align-items: center;
  padding: 0 1em;
  text-align: left;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-left .selected-labels-inner-container .selected-labels-details .action-detail:nth-child(1) {
  width: 33%;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-left .selected-labels-inner-container .selected-labels-details .action-detail:nth-child(2) {
  width: 33%;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-left .selected-labels-inner-container .selected-labels-details .action-detail:nth-child(3) {
  width: 33%;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-left .selected-labels-inner-container .selected-labels-details .action-status span {
  margin-right: 1em;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-right {
  display: flex;
  flex: 0 1 35%;
  align-items: center;
  font-size: 14px;
  text-align: left;
  color: #001737;
  flex-direction: column;
  justify-content: center;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-right p {
  text-align: left;
  width: 100%;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-table-right span {
  font-family: 'Roboto-Regular';
  padding: 1em;
}
.assign-labels-container .selected-labels-container .selected-labels-table .selected-labels-details:nth-child(even) {
  background: #f8f7f6;
}
@media (max-width: 1024px) {
  .assign-labels-container .selected-labels-container .selected-labels-table {
    padding: 1em;
    flex-direction: column;
  }
  .assign-labels-container .selected-labels-container .selected-labels-table .vertical-separator-box {
    display: none;
  }
}
.assign-labels-container .selected-labels-container .action-mode {
  flex: 100% 0;
}
.assign-labels-container .selected-labels-container .action-mode .selected-labels-table-left {
  flex: 100% 0;
}
.assign-labels-container .selected-labels-container .action-mode .selected-labels-table-left .selected-labels-inner-container .selected-labels-details .action-detail:nth-child(1) {
  width: 33%;
}
.assign-labels-container .selected-labels-container .action-mode .selected-labels-table-left .selected-labels-inner-container .selected-labels-details .action-detail:nth-child(2) {
  width: 33%;
}
.assign-labels-container .selected-labels-container .action-mode .selected-labels-table-left .selected-labels-inner-container .selected-labels-details .action-detail:nth-child(3) {
  width: 33%;
}
.assign-labels-container .selected-labels-container .action-mode .vertical-separator-box {
  display: none;
}
.assign-labels-container .selected-labels-container .action-mode .selected-labels-table-right {
  display: none;
}
.assign-labels-container .selected-labels-container .action-notes-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 1em 0;
}
.assign-labels-container .selected-labels-container .action-notes-container label {
  font-size: 14px;
  font-family: 'Roboto-Medium';
  text-align: left;
  padding: 1em 0;
}
.assign-labels-container .selected-labels-container .action-notes-container textarea {
  width: auto;
  border: 1px solid #f2f2f2;
  padding: 1em;
}
.assign-labels-container .selected-labels-container .action-notes-container textarea:focus {
  border: 1px solid #048041;
  outline: none;
}
.assign-labels-container .selected-labels-container .action-notes-container .action-notes-text {
  display: flex;
  width: auto;
  padding: 1em 2em;
  font-size: 12px;
  border: 1px solid #ccc;
  min-height: 50px;
  color: #048041;
  background: #f8f7f6;
  text-align: left;
}
.assign-labels-container .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .assign-labels-container .section-header {
    padding: 10px 0;
  }
}
.assign-labels-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media (max-width: 1024px) {
  .assign-labels-container .section-header .section-title {
    font-size: 16px;
  }
}
.assign-labels-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.assign-labels-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.assign-labels-container .section-below-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  justify-content: center;
  align-items: center;
}
@media (max-width: 1024px) {
  .assign-labels-container .section-below-header {
    padding: 10px 0;
    flex-direction: column;
  }
}
@media (max-width: 415px) {
  .assign-labels-container .section-below-header .enerva-btn {
    font-size: 12px;
  }
}
.assign-labels-container .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .assign-labels-container .section-below-header .section-title {
    font-size: 16px;
  }
}
.assign-labels-container .section-below-header .review-status-selection {
  flex: 0 72%;
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 1024px) {
  .assign-labels-container .section-below-header .review-status-selection {
    justify-content: flex-start;
  }
}
.assign-labels-container .section-below-header .review-status-selection label {
  color: #686C6D;
}
.assign-labels-container .section-below-header .review-status-selection .input-container {
  width: 400px;
}
@media (max-width: 1024px) {
  .assign-labels-container .section-below-header .review-status-selection .input-container {
    margin: 0;
    width: 300px;
  }
}
.assign-labels-container .section-below-header .review-status-selection .input-container .enerva-dropdown__control {
  font-size: 14px;
}
.assign-labels-container .section-below-header .review-status-selection .input-container .enerva-dropdown__control .enerva-dropdown__single-value {
  font-size: 14px;
}
.assign-labels-container .section-below-header .review-status-selection .input-container .enerva-dropdown__menu {
  font-size: 14px;
}
.assign-labels-container .section-below-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
}
.assign-labels-container .section-below-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.assign-labels-container .new-form-sections {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 200px;
}
@media (max-width: 1024px) {
  .assign-labels-container .new-form-sections {
    flex-direction: column;
  }
}
.assign-labels-container .new-form-sections .form-inner-section {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
}
@media (max-width: 1024px) {
  .assign-labels-container .new-form-sections .form-inner-section {
    flex-direction: column;
    width: 100%;
    margin-top: 1em;
  }
}
.assign-labels-container .new-form-sections .form-label.info-text {
  border-left: 2px solid #048041;
  text-align: left;
  padding: 0 2em;
}
@media (max-width: 896px) {
  .assign-labels-container .new-form-sections .form-label.info-text {
    padding-top: 0.5em;
  }
}
.assign-labels-container .new-form-sections .form-label,
.assign-labels-container .new-form-sections .form-element {
  padding-left: 1em;
  padding-right: 1em;
  text-align: left;
  width: 70%;
  font-size: 1.4em;
}
@media (max-width: 1024px) {
  .assign-labels-container .new-form-sections .form-label,
  .assign-labels-container .new-form-sections .form-element {
    display: flex;
    flex-direction: column;
    width: auto;
    padding-left: 0;
    padding-right: 0;
    width: 90%;
    justify-content: center;
    align-items: center;
  }
}
.assign-labels-container .new-form-sections .form-label.full,
.assign-labels-container .new-form-sections .form-element.full {
  width: 100% !important;
}
.assign-labels-container .new-form-sections .form-label.bold,
.assign-labels-container .new-form-sections .form-element.bold {
  font-weight: bold;
}
.assign-labels-container .signup-btn {
  width: 100%;
  max-width: 250px;
}
@media (max-width: 1024px) {
  .assign-labels-container .signup-btn {
    max-width: unset;
  }
}
.assign-labels-container .confirm-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
@media (max-width: 1024px) {
  .assign-labels-container .confirm-btn-container {
    flex-direction: column;
    min-width: 180px;
  }
}
.assign-labels-container .confirm-btn-container .enerva-btn {
  margin-right: 0px;
}
