/* Information Requested Modal CSS */
.information-container {
  display: flex;
  flex-direction: column;
}
.information-container .information-container-section {
  display: flex;
  flex-direction: row;
  margin: 10px 0;
}
.information-container .information-container-section textarea {
  display: flex;
  flex: 100% 1;
  padding: 0.5em;
  min-height: 80px;
  font-family: 'Roboto-Regular';
  font-size: 18px;
}
.ri-container {
  display: flex;
  flex-direction: column;
  width: 97%;
  overflow-y: auto;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #98989A;
  padding: 10px;
  min-height: 100px;
  max-height: 370px;
}
@media (max-width: 896px) {
  .ri-container {
    width: 92%;
    max-height: 260px;
  }
}
.modal-content .final-message-container {
  display: flex;
  width: 97%;
  overflow-y: auto;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #98989A;
  padding: 10px;
  min-height: 100px;
  max-height: 370px;
}
@media (max-width: 896px) {
  .modal-content .final-message-container {
    width: 92%;
    max-height: 260px;
  }
}
.modal-content .final-message-container p span {
  color: #048041;
}
.reviews-top-bar {
  display: flex;
  flex-direction: row;
  flex: 100%;
}
@media (max-width: 1250px) {
  .reviews-top-bar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.reviews-top-bar .appstate-details {
  display: grid;
  width: 300px;
  grid-template-columns: 35% 65%;
  align-items: center;
}
@media (max-width: 1024px) {
  .reviews-top-bar .appstate-details {
    width: 100%;
    grid-template-columns: 15% 35%;
    justify-content: center;
  }
}
@media (max-width: 690px) {
  .reviews-top-bar .appstate-details {
    width: 100%;
    grid-template-columns: 18% 82%;
    justify-content: center;
  }
}
.reviews-top-bar .appstate-details .appstate-text {
  font-size: 14px;
  margin-left: 8px;
  color: #048041;
}
@media (max-width: 896px) {
  .reviews-top-bar .appstate-details .appstate-text {
    font-size: 18px;
  }
}
.reviews-top-bar .appstate-details .input-parent {
  margin: 15px 0px;
}
@media (max-width: 896px) {
  .reviews-top-bar .appstate-details .input-parent .switch-container {
    margin: 0;
  }
}
.reviews-top-bar .appstate-details .input-parent .switch-container .switch-box .switch-value {
  display: none;
}
.reviews-top-bar .reviews-top-bar-search {
  display: flex;
  flex-direction: row;
  flex: 60% 0;
  width: 100%;
  max-width: 450px;
}
@media (max-width: 690px) {
  .reviews-top-bar .reviews-top-bar-search {
    margin: unset;
    max-width: unset;
  }
}
@media (max-width: 890px) {
  .reviews-top-bar .reviews-top-bar-search {
    margin: auto;
    max-width: 420px;
    width: 100%;
  }
}
.reviews-top-bar .add-participant {
  width: 23px;
  height: 20px;
  background-color: #048041;
  background-repeat: no-repeat;
  padding: 10px 20px;
  margin: 10px 0;
  background-position: center;
  border-radius: 4px;
  cursor: pointer;
  background-image: url('../../../images/AddUserVector.svg');
}
.reviews-top-bar .add-participant:hover {
  opacity: 0.7;
}
.reviews-top-bar .reviews-top-bar-filter {
  justify-content: center;
  display: flex;
  flex: 0 40%;
  margin: 0 20px 0 0;
  position: relative;
  width: 100%;
  max-width: 450px;
}
@media (max-width: 690px) {
  .reviews-top-bar .reviews-top-bar-filter {
    margin: unset;
  }
}
.reviews-top-bar .reviews-top-bar-filter .filter-component {
  width: 100%;
}
.reviews-top-bar .reviews-top-bar-filter-btn {
  display: flex;
  flex: 0 40%;
  margin: 0 20px 0 0;
  position: relative;
  width: 100%;
  max-width: 450px;
}
@media (max-width: 768px) {
  .reviews-top-bar .reviews-top-bar-filter-btn {
    justify-content: center !important;
  }
}
@media (max-width: 690px) {
  .reviews-top-bar .reviews-top-bar-filter-btn {
    margin: unset;
    margin-bottom: 20px;
  }
}
.reviews-top-bar .reviews-top-bar-filter-btn .filter-component {
  width: 100%;
}
.reviews-top-bar .reviews-top-bar-filter-btn .filter-btn {
  display: flex;
  width: 250px;
  justify-content: space-between;
}
@media (max-width: 896px) {
  .reviews-top-bar .reviews-top-bar-filter-btn .filter-btn {
    width: 100%;
    margin: 0px;
    font-family: 'Roboto-Regular';
    font-size: 16px !important;
  }
}
.rejection-date {
  display: flex;
  flex-direction: row;
  width: auto;
}
.rejection-date .rejection-date-label {
  font-size: 14px;
  width: auto;
}
@media (max-width: 600px) {
  .rejection-date .rejection-date-label {
    font-size: 12px;
  }
}
.rejection-date .rejection-date-picker {
  width: auto;
  margin-left: 0;
}
.rejection-date .rejection-date-picker .input-parent .input-container .input-label {
  padding-left: 0;
  text-align: left;
}
.rejection-date .rejection-date-picker .date-picker {
  border-bottom: 1px solid #048041;
}
.rejection-date .rejection-date-picker .date-picker svg {
  fill: #048041;
}
.filter-drawer {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.filter-drawer .btn-set {
  display: flex;
  justify-content: space-around;
  margin: 15px 0;
  flex-direction: column;
}
.filter-drawer .btn-set .enerva-btn {
  margin: 10px 0;
  display: flex;
  flex: 50%;
  justify-content: center;
  margin-right: 0px;
  background: #048041;
}
.filter-drawer .btn-set .enerva-btn.inversed-btn {
  background: #ffffff;
  color: #048041;
  border: 1px solid #048041;
}
.filter-drawer h3 {
  color: #048041;
  font-size: 20px;
  border-bottom: 1px solid #048041;
  padding-bottom: 10px;
  text-align: center;
}
.filter-drawer .all-filters {
  flex: 1;
  max-height: 70vh;
  overflow-y: auto;
}
.filter-drawer .all-filters .filter {
  margin-right: 10px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}
.filter-drawer .all-filters .filter .appstate-details {
  display: grid;
  width: 300px;
  grid-template-columns: 35% 65%;
  align-items: center;
}
@media (max-width: 1024px) {
  .filter-drawer .all-filters .filter .appstate-details {
    width: 100%;
    grid-template-columns: 15% 35%;
    justify-content: center;
  }
}
@media (max-width: 690px) {
  .filter-drawer .all-filters .filter .appstate-details {
    width: 100%;
    grid-template-columns: 18% 82%;
    justify-content: center;
  }
}
.filter-drawer .all-filters .filter .appstate-details .appstate-text {
  font-size: 14px;
  margin-left: 8px;
  color: #048041;
}
@media (max-width: 896px) {
  .filter-drawer .all-filters .filter .appstate-details .appstate-text {
    font-size: 18px;
  }
}
.filter-drawer .all-filters .filter .appstate-details .input-parent {
  margin: 15px 0px;
}
@media (max-width: 896px) {
  .filter-drawer .all-filters .filter .appstate-details .input-parent .switch-container {
    margin: 0;
  }
}
.filter-drawer .all-filters .filter .appstate-details .input-parent .switch-container .switch-box .switch-value {
  display: none;
}
.filter-drawer .all-filters .filter .filter-label {
  font-family: 'Roboto-Medium';
  color: #048041;
  font-size: 18px;
  margin: 10px 0px;
}
.filter-drawer .all-filters .filter .filter-options {
  max-height: 25vh;
  overflow-y: auto;
}
.filter-drawer .all-filters .filter .filter-options label {
  accent-color: #048041;
  padding: 5px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.filter-drawer .all-filters .filter .filter-options label span svg {
  width: 10px;
}
.filter-drawer .all-filters .filter .filter-options label input {
  height: 16px;
  width: 16px;
  margin-right: 10px;
}
