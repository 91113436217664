.spot-check-drawer .drawer-container {
  width: 50vw;
  height: auto;
}
@media (max-width: 1024px) {
  .spot-check-drawer .drawer-container {
    width: 80vw;
  }
}
.spot-check-drawer .drawer-container .enerva-btn {
  color: #FFF;
  border: 1px solid #048041;
  background-color: #048041;
}
.spot-check-drawer .drawer-container .inversed-btn {
  color: #048041;
  background-color: #FFF;
}
.modal-content .section-summary {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1.5em 0;
  width: 100%;
}
@media (max-width: 896px) {
  .modal-content .section-summary {
    padding: 10px 0;
    flex-direction: column;
  }
}
.modal-content .section-summary .section-block {
  padding: 0 2% 0 0;
  text-align: left;
  font-family: "Roboto-Medium";
  font-size: 16px;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1024px) {
  .modal-content .section-summary .section-block {
    width: 100%;
    margin: 10px 0;
  }
}
.modal-content .section-summary .section-block .info {
  color: #333131;
  word-break: break-word;
  padding: 10px;
  border-radius: 6px;
  background-color: #E3DDDA;
}
.modal-content .section-summary .section-block .label {
  color: #333131;
  word-break: break-word;
  padding-right: 15px;
}
.modal-content .section-summary .section-block .label.right {
  text-align: right;
}
@media (max-width: 1024px) {
  .modal-content .section-summary .section-block .label.right {
    text-align: left;
  }
}
.modal-content .section-summary .section-block .totals {
  cursor: not-allowed;
  background-color: #e3ddda;
  border-radius: 6px;
  padding: 10px;
  color: #535765;
  min-width: 150px;
  word-break: break-word;
}
@media (max-width: 1024px) {
  .modal-content .section-summary .section-block .totals {
    width: 50%;
  }
}
@media (max-width: 321px) {
  .modal-content .section-summary .section-block .totals {
    width: 40%;
    min-width: 0;
  }
}
.modal-content .section-summary :nth-child(2) {
  justify-content: flex-end;
}
@media (max-width: 896px) {
  .modal-content .section-summary :nth-child(2) {
    justify-content: flex-start;
  }
}
.modal-content .section-below-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  margin-bottom: 10px;
}
@media (max-width: 1024px) {
  .modal-content .section-below-header {
    padding: 10px 0;
    flex-direction: column;
  }
}
.modal-content .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: "Roboto-Medium";
  font-weight: normal;
  text-align: left;
  width: 100%;
  margin: 0;
}
@media (max-width: 1024px) {
  .modal-content .section-below-header .section-title {
    font-size: 16px;
    margin: 0 0 0 10px;
  }
}
@media (max-width: 320px) {
  .modal-content .section-below-header .section-title {
    margin: 0 0 0 0px;
    font-size: 14px;
  }
}
.modal-content .main-container {
  width: 94%;
  overflow-y: auto;
  box-shadow: 0px 0px 0px #5a71d01b;
  border: 0.5px solid #98989a;
  padding: 2em;
  max-height: 400px;
  text-align: center;
  margin-bottom: 10px;
}
@media (max-width: 896px) {
  .modal-content .main-container {
    width: 80%;
    max-height: 200px;
  }
}
@media (max-width: 321px) {
  .modal-content .main-container {
    max-height: 165px;
  }
}
.modal-content .main-container p {
  text-align: left;
}
.modal-content .main-container .content-container {
  display: flex;
  flex-direction: row;
}
@media (max-width: 896px) {
  .modal-content .main-container .content-container {
    flex-direction: column;
  }
}
.modal-content .main-container .content-container .pvrr-details {
  width: 56%;
}
@media (max-width: 896px) {
  .modal-content .main-container .content-container .pvrr-details {
    width: 100%;
  }
}
.modal-content .main-container .content-container .pvrr-details .uploaded-file {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
  width: 100%;
}
@media (max-width: 896px) {
  .modal-content .main-container .content-container .pvrr-details .uploaded-file {
    flex-direction: column;
  }
}
.modal-content .main-container .content-container .pvrr-details .uploaded-file .file-label {
  font-size: 16px;
  text-align: left;
  color: #048041;
  padding: 0.5em 1em;
  flex: 63% 1;
  width: 44%;
}
@media (max-width: 896px) {
  .modal-content .main-container .content-container .pvrr-details .uploaded-file .file-label {
    padding: 0.5em 0em;
  }
}
.modal-content .main-container .content-container .pvrr-details .uploaded-file .disabled-file-details {
  width: 100%;
  font-size: 16px;
  padding: 0.5em 1em;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 75% 25%;
  background-color: #E3DDDA;
}
@media (max-width: 896px) {
  .modal-content .main-container .content-container .pvrr-details .uploaded-file .disabled-file-details {
    width: auto;
    height: 20px;
  }
}
.modal-content .main-container .content-container .pvrr-details .uploaded-file .file-details {
  width: 100%;
  font-size: 16px;
  padding: 0.5em 1em;
  border: 1px solid #686C6D;
  border-radius: 6px;
  display: grid;
  grid-template-columns: 75% 25%;
  background-color: #e3ddda;
}
@media (max-width: 896px) {
  .modal-content .main-container .content-container .pvrr-details .uploaded-file .file-details {
    width: auto;
    height: 20px;
  }
}
.modal-content .main-container .content-container .pvrr-details .uploaded-file .file-name {
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.modal-content .main-container .content-container .pvrr-details .uploaded-file .more .view-eye-icon {
  cursor: pointer;
}
.modal-content .main-container .content-container .pvrr-details .uploaded-file .more .view-eye-icon:hover {
  opacity: 0.7;
}
.modal-content .main-container .content-container .pvrr-details .uploaded-file .more .download-icon {
  cursor: pointer;
}
.modal-content .main-container .content-container .pvrr-details .uploaded-file .more .download-icon:hover {
  opacity: 0.7;
}
.modal-content .main-container .content-container .pvrr-details .uploaded-file .more .delete-icon {
  cursor: pointer;
}
.modal-content .main-container .content-container .pvrr-details .uploaded-file .more .delete-icon:hover {
  opacity: 0.7;
}
.modal-content .main-container .content-container .pvrr-details .input-parent .input-label {
  font-size: 16px;
  text-align: left;
}
.modal-content .main-container .content-container .pvrr-details .input-parent .date-picker {
  flex: 100% 1;
  border-bottom: 1px solid #048041;
}
.modal-content .main-container .content-container .pvrr-details .input-parent .date-picker svg {
  fill: #048041;
}
.modal-content .main-container .content-container .pvrr-details .input-parent .file-upload-container {
  margin-bottom: 0px;
}
.modal-content .main-container .content-container .pvrr-details .input-parent .file-upload-container .file-dropzone {
  flex-direction: row;
  text-align: left;
  font-size: 12px;
  padding: 1em;
}
.modal-content .main-container .content-container .pvrr-details .input-parent .file-upload-container .file-dropzone svg {
  height: 34px;
}
.modal-content .main-container .content-container .separator-hidden {
  display: none;
}
.modal-content .main-container .content-container .vertical-separator {
  width: 4%;
  display: flex;
  align-items: center;
}
.modal-content .main-container .content-container .vertical-separator .separator {
  background-color: #E3DDDA;
  width: 1px;
}
@media (max-width: 896px) {
  .modal-content .main-container .content-container .vertical-separator {
    display: none;
  }
}
.modal-content .main-container .content-container .message-block {
  width: 40%;
}
@media (max-width: 896px) {
  .modal-content .main-container .content-container .message-block {
    width: auto;
    border: 0.5px solid #E3DDDA;
    margin-top: 20px;
    padding: 1em;
  }
}
.modal-content .main-container .note {
  margin-top: 30px;
}
.modal-content .main-container .note .note-label {
  width: 100%;
  text-align: left;
  color: #333131;
}
.modal-content .pvrr-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}
@media (max-width: 896px) {
  .modal-content .pvrr-buttons {
    flex-direction: column;
    width: 100%;
  }
}
.modal-content .pvrr-buttons .enerva-btn {
  width: 300px;
}
@media (max-width: 896px) {
  .modal-content .pvrr-buttons .enerva-btn {
    width: auto;
  }
}
.label-filter {
  background-size: 18px !important;
  background-image: url('../../../images/LabelIcon.svg') !important;
}
.incentive-total-validation-mdl .button-set {
  justify-content: space-around !important;
}
.incentive-total-validation-mdl .button-set button {
  width: 50%;
  flex: none !important;
}
@media (max-width: 1024px) {
  .incentive-total-validation-mdl .button-set button {
    width: 100%;
  }
}
