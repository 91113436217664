@media (max-width: 896px) {
  .modal-content h3 {
    font-size: 14px;
  }
  .modal-content .button-set .enerva-btn {
    font-size: 12px;
  }
}
.view-applicant {
  display: flex;
  flex-direction: column;
  position: relative;
  font-size: 16px;
  flex: 100%;
  height: 100%;
}
@media (max-width: 1024px) {
  .view-applicant {
    padding: 0;
    margin: 0;
    flex: 100%;
  }
}
@media (max-width: 600px) {
  .view-applicant .applicant-details .MuiTableContainer-root {
    max-height: 350px;
  }
}
.view-applicant .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
}
@media (max-width: 1024px) {
  .view-applicant .section-title {
    font-size: 16px;
  }
}
.view-applicant .section-below-header {
  display: flex;
  padding: 1em 0;
}
@media (max-width: 1024px) {
  .view-applicant .section-below-header {
    padding: 0.5em 0;
  }
}
.view-applicant .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .view-applicant .section-below-header .section-title {
    font-size: 16px;
  }
}
.view-applicant .section-header {
  display: flex;
  flex-direction: revert;
  justify-content: space-around;
  padding: 1em 0;
  align-items: center;
}
@media (max-width: 1024px) {
  .view-applicant .section-header {
    padding: 10px 0;
  }
}
.view-applicant .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.view-applicant .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.check-box-checked {
  background-color: #048041;
}
.program-dashboard-container .no-records-found {
  text-align: center;
  padding: 1em;
  background: #F8F7F6;
}
.program-dashboard-container .tabs {
  width: 100%;
}
@media (max-width: 359px) {
  .program-dashboard-container .tabs .tab-list-item {
    font-size: 14px;
    padding: 12px 20px;
  }
}
@media (max-width: 321px) {
  .program-dashboard-container .tabs .tab-list-item {
    padding: 6px 12px;
    font-size: 10px;
  }
}
.program-dashboard-container .customer-tab {
  padding: 0 0 20px 40px;
  color: #001737;
  border: 1px solid #f2f2f2;
}
@media (max-width: 1024px) {
  .program-dashboard-container .customer-tab {
    padding: 1em;
  }
}
.program-dashboard-container .customer-tab .participant-top-bar {
  display: flex;
  flex-direction: row;
  flex: 100%;
}
@media (max-width: 1250px) {
  .program-dashboard-container .customer-tab .participant-top-bar {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.program-dashboard-container .customer-tab .participant-top-bar .participant-top-bar-search {
  display: flex;
  flex-direction: row;
  flex: 60% 0;
  width: 100%;
  max-width: 450px;
}
@media (max-width: 690px) {
  .program-dashboard-container .customer-tab .participant-top-bar .participant-top-bar-search {
    margin: unset;
    max-width: unset;
  }
}
@media (max-width: 890px) {
  .program-dashboard-container .customer-tab .participant-top-bar .participant-top-bar-search {
    margin: auto;
    max-width: 420px;
    width: 100%;
  }
}
.program-dashboard-container .customer-tab .participant-top-bar .add-participant {
  width: 23px;
  height: 20px;
  background-color: #048041;
  background-repeat: no-repeat;
  padding: 10px 20px;
  margin: 10px 0;
  background-position: center;
  border-radius: 4px;
  cursor: pointer;
  background-image: url('../../../images/AddUserVector.svg');
}
.program-dashboard-container .customer-tab .participant-top-bar .add-participant:hover {
  opacity: 0.7;
}
.program-dashboard-container .customer-tab .participant-top-bar .participant-top-bar-filter {
  justify-content: center;
  display: flex;
  flex: 0 40%;
  margin: 0 20px 0 0;
  position: relative;
  width: 100%;
  max-width: 450px;
}
@media (max-width: 690px) {
  .program-dashboard-container .customer-tab .participant-top-bar .participant-top-bar-filter {
    margin: unset;
  }
}
.program-dashboard-container .customer-tab .participant-top-bar .participant-top-bar-filter .filter-component {
  width: 100%;
}
.program-dashboard-container .customer-tab .blocked {
  background-color: #ebb0a7 !important;
}
.program-dashboard-container .customer-tab .customer-details-row:nth-child(odd) {
  background: #F8F7F6;
}
.program-dashboard-container .customer-tab .customer-details-list {
  max-height: 60vh;
  overflow: auto;
  position: relative;
}
.program-dashboard-container .customer-tab .customer-details-row {
  display: flex;
  flex-direction: column;
}
.program-dashboard-container .customer-tab .customer-details-row.customer-details-header {
  display: flex;
  min-height: 50px;
  align-items: center;
  background: none;
}
@media (max-width: 1024px) {
  .program-dashboard-container .customer-tab .customer-details-row.customer-details-header {
    display: none;
  }
}
.program-dashboard-container .customer-tab .customer-details-row.customer-details-header .customer-details {
  font-family: 'Roboto-Medium';
  font-size: 16px;
}
.program-dashboard-container .customer-tab .customer-details-row.customer-details-header .customer-details div {
  background-image: url('../../../images/SortIcon.svg');
  background-repeat: no-repeat;
  background-position: right;
}
.program-dashboard-container .customer-tab .customer-details-row.active-row {
  background: #e3ddda !important;
  border-bottom: 1px solid #FFF;
  border-radius: 0 0 5px 5px;
  transition: background 1s ease-out;
}
.program-dashboard-container .customer-tab .customer-details-row.active-row .customer-details {
  font-family: 'Roboto-Regular';
  border-bottom: 1px solid #D3E3F7;
}
@media (max-width: 1024px) {
  .program-dashboard-container .customer-tab .customer-details-row.active-row .customer-details {
    font-weight: normal;
  }
}
.program-dashboard-container .customer-tab .customer-details-row.active-row .customer-details .show-more-btn {
  background-image: url('../../../images/MoreButtonDark.svg');
}
.program-dashboard-container .customer-tab .customer-details-row.active-row .customer-more-details {
  display: flex;
  transition: all 0.3s ease;
}
.program-dashboard-container .customer-tab .customer-details-row .customer-details {
  display: flex;
  width: 100%;
  min-height: 50px;
  align-items: center;
  font-family: 'Roboto-Light';
  font-size: 14px;
  word-break: break-word;
}
.program-dashboard-container .customer-tab .customer-details-row .customer-details label {
  display: none;
  margin-right: 1em;
  width: 100px;
}
@media (max-width: 1024px) {
  .program-dashboard-container .customer-tab .customer-details-row .customer-details label {
    display: inline-block;
    width: 100%;
    padding-bottom: 0.5em;
    margin-right: 0;
    font-family: 'Roboto-Medium';
  }
}
@media (max-width: 1024px) {
  .program-dashboard-container .customer-tab .customer-details-row .customer-details span {
    width: 100%;
    padding-bottom: 0.5em;
    text-align: left;
  }
}
@media (max-width: 1024px) {
  .program-dashboard-container .customer-tab .customer-details-row .customer-details {
    flex-direction: column;
    padding: 1em;
    width: auto;
  }
}
.program-dashboard-container .customer-tab .customer-details-row .customer-details div {
  padding: 0 15px;
  display: flex;
}
@media (max-width: 1024px) {
  .program-dashboard-container .customer-tab .customer-details-row .customer-details div {
    width: 100% !important;
    padding: 5px 0;
  }
}
@media (max-width: 500px) {
  .program-dashboard-container .customer-tab .customer-details-row .customer-details div {
    padding: 2px 0;
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .program-dashboard-container .customer-tab .customer-details-row .customer-details div {
    flex-direction: column;
  }
}
.program-dashboard-container .customer-tab .customer-details-row .customer-details .show-more-btn {
  min-height: 50px;
  background-repeat: no-repeat;
  background-image: url('../../../images/MoreButton.svg');
  background-position: center;
}
@media (max-width: 1024px) {
  .program-dashboard-container .customer-tab .customer-details-row .customer-details .show-more-btn {
    min-height: 15px;
    background-position: right;
  }
}
.program-dashboard-container .customer-tab .customer-details-row .customer-more-details {
  padding: 10px;
  display: none;
  flex-direction: column;
}
.program-dashboard-container .customer-tab .customer-details-row .customer-more-details div {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 400px) {
  .program-dashboard-container .customer-tab .customer-details-row .customer-more-details div {
    flex-direction: column;
    align-items: flex-start;
  }
}
.program-dashboard-container .customer-tab .customer-details-row .customer-more-details div .more-details-links {
  display: flex;
  padding: 15px 10px 15px 40px;
  min-width: 15%;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 10px;
  background-size: 18px;
  font-size: 12px;
  border-radius: 0 5px 5px 0;
  font-family: 'Roboto-Light';
  margin-right: 4%;
  cursor: pointer;
}
.program-dashboard-container .customer-tab .customer-details-row .customer-more-details div .more-details-links:hover {
  background-color: rgba(4, 128, 65, 0.25);
  font-family: 'Roboto-Regular';
  color: #048041;
}
.program-dashboard-container .customer-tab .customer-details-row .customer-more-details div .more-details-links.edit-icon {
  background-image: url('../../../images/EditDocVector.svg');
}
.program-dashboard-container .customer-tab .customer-details-row .customer-more-details div .more-details-links.review-icon {
  background-image: url('../../../images/ReviewVector.svg');
}
.program-dashboard-container .customer-tab .customer-details-row .customer-more-details div .more-details-links.view-icon {
  background-image: url('../../../images/ApplicationVector.svg');
}
.program-dashboard-container .customer-tab .customer-details-row .customer-more-details div .more-details-links.doc-icon {
  background-image: url('../../../images/DocumentVector.svg');
}
.program-dashboard-container .customer-tab .customer-details-row .customer-more-details div .more-details-links.flag-icon {
  background-image: url('../../../images/FlagVector.svg');
}
.program-dashboard-container .customer-tab .customer-details-row .customer-more-details div .more-details-links.suspend-icon {
  background-image: url('../../../images/SuspendUserVector.svg');
}
.program-dashboard-container .customer-tab .customer-details-row .customer-more-details div .more-details-links.reinstate-icon {
  background-image: url('../../../images/ReinstateUserVector.svg');
}
.program-dashboard-container .customer-tab .customer-details-row .customer-more-details div .more-details-links.mail-icon {
  background-image: url('../../../images/MailVector.svg');
}
.program-dashboard-container .customer-tab .customer-details-row .customer-details div:nth-child(1) {
  width: 12%;
}
.program-dashboard-container .customer-tab .customer-details-row .customer-details div:nth-child(2) {
  width: 18%;
}
.program-dashboard-container .customer-tab .customer-details-row .customer-details div:nth-child(3) {
  width: 25%;
}
.program-dashboard-container .customer-tab .customer-details-row .customer-details div:nth-child(4) {
  width: 15%;
}
.program-dashboard-container .customer-tab .customer-details-row .customer-details div:nth-child(5) {
  width: 25%;
}
.program-dashboard-container .customer-tab .customer-details-row .customer-details div:nth-child(6) {
  width: 5%;
}
