.program-filter .check-box-checked {
  background-color: #048041 !important;
}
.diar-labels {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  border-bottom: 1px solid #D3E3F7;
}
.diar-labels .row-label-tag {
  font-size: 14px;
  color: #FFFFFF;
  padding: 0.5em;
  margin: 10px;
  margin-right: 0px;
  text-align: left;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
.diar-labels .row-label-tag img {
  width: 12px;
  padding-left: 1em;
}
.diar-labels .row-label-tag img:hover {
  opacity: 0.4;
}
.diar-tab {
  padding: 0 0 20px 40px;
  color: #001737;
  border: 1px solid #f2f2f2;
}
@media (max-width: 1024px) {
  .diar-tab {
    padding: 1em;
  }
}
@media (max-width: 896px) {
  .diar-tab .exportDIARBtn {
    width: 100%;
    text-align: center;
  }
}
.diar-tab .exportDIARBtn button {
  min-width: 200px;
  font-family: 'Roboto-Regular' !important;
}
@media (max-width: 896px) {
  .diar-tab .exportDIARBtn button {
    width: 100%;
    text-align: center;
    margin: 0;
  }
}
.diar-tab .check-box-checked {
  background-color: #048041 !important;
}
.diar-tab .check-box {
  width: 20px !important;
  height: 20px !important;
  display: flex !important;
  align-items: center !important;
  padding: 0  !important;
  margin-left: 10px;
}
@media (max-width: 1024px) {
  .diar-tab .check-box {
    margin-left: 0px;
  }
}
.diar-tab .diar-top-bar {
  display: flex;
  flex-direction: row;
  flex: 100%;
}
@media (max-width: 1024px) {
  .diar-tab .diar-top-bar > div {
    width: 100% !important;
    max-width: 600px;
  }
}
.diar-tab .diar-top-bar .enerva-react-date-picker-container {
  width: 100%;
}
.diar-tab .diar-top-bar.actions {
  margin: 10px 0;
}
.diar-tab .diar-top-bar.actions .button-set {
  display: flex;
  flex: 100%;
  justify-content: flex-start;
}
@media (max-width: 896px) {
  .diar-tab .diar-top-bar.actions .button-set {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
.diar-tab .diar-top-bar.actions .button-set > div {
  margin-right: 1em;
}
@media (max-width: 896px) {
  .diar-tab .diar-top-bar.actions .button-set > div {
    width: 100%;
    max-width: unset;
  }
}
.diar-tab .diar-top-bar.actions .button-set button {
  width: 30%;
  max-width: 270px;
}
@media (max-width: 896px) {
  .diar-tab .diar-top-bar.actions .button-set button {
    width: 100%;
    max-width: unset;
  }
}
@media (max-width: 896px) {
  .diar-tab .diar-top-bar {
    flex-direction: column;
    padding-bottom: 1em;
    justify-content: center;
    align-items: center;
  }
}
.diar-tab .diar-top-bar .diar-search {
  display: flex;
  flex-direction: row;
  flex: 60% 0;
  margin-right: 10px;
  max-width: 450px;
}
@media (max-width: 896px) {
  .diar-tab .diar-top-bar .diar-search {
    margin-right: 0px;
    max-width: 600px;
  }
}
.diar-tab .diar-top-bar .diar-search .search-box {
  justify-content: center;
}
.diar-tab .diar-top-bar .diar-incentive-amount {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media (max-width: 896px) {
  .diar-tab .diar-top-bar .diar-incentive-amount {
    width: 90%;
    margin: auto;
  }
}
.diar-tab .diar-top-bar .diar-incentive-amount .label {
  padding-right: 0.5em;
}
@media (max-width: 896px) {
  .diar-tab .diar-top-bar .diar-incentive-amount .label {
    width: 40%;
    padding-right: 0;
  }
}
.diar-tab .diar-top-bar .diar-incentive-amount .incentiveTotal {
  display: flex;
  justify-content: center;
  cursor: not-allowed;
  background-color: #e3ddda;
  border-radius: 6px;
  padding: 10px;
  color: #535765;
  word-break: break-word;
  min-width: 90px;
  text-align: center;
}
@media (max-width: 896px) {
  .diar-tab .diar-top-bar .diar-incentive-amount .incentiveTotal {
    width: 60%;
  }
}
.diar-tab .diar-top-bar .diar-incentive-amount .incentiveTotal.exceeded {
  border: 1px solid #D90303;
  color: #D90303;
}
.diar-tab .diar-top-bar .add-participant {
  width: 23px;
  height: 20px;
  background-color: #048041;
  background-repeat: no-repeat;
  padding: 10px 20px;
  margin: 10px 0;
  background-position: center;
  border-radius: 4px;
  cursor: pointer;
  background-image: url('../../../../images/AddUserVector.svg');
}
.diar-tab .diar-top-bar .add-participant:hover {
  opacity: 0.7;
}
.diar-tab .diar-top-bar .diar-filter {
  display: flex;
  flex: 0 40%;
  max-width: 240px;
  position: relative;
}
@media (max-width: 1024px) {
  .diar-tab .diar-top-bar .diar-filter {
    margin: 0;
    width: 100%;
    max-width: 600px;
  }
}
@media (max-width: 680px) {
  .diar-tab .diar-top-bar .diar-filter {
    max-width: unset;
    width: 100%;
  }
}
.diar-tab .diar-top-bar .first {
  margin-right: 10px;
}
.diar-tab .diar-details-row:nth-child(odd) {
  background: #F8F7F6;
}
.diar-tab .diar-details-list {
  max-height: 60vh;
  overflow: auto;
  position: relative;
}
.diar-tab .diar-details-row {
  display: flex;
  flex-direction: column;
}
.diar-tab .diar-details-row .diar-details {
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}
.diar-tab .diar-details-row.diar-details-header {
  display: flex;
  min-height: 50px;
  align-items: center;
  background: none;
}
@media (max-width: 1024px) {
  .diar-tab .diar-details-row.diar-details-header {
    display: none;
  }
}
.diar-tab .diar-details-row.diar-details-header .diar-details {
  font-family: 'Roboto-Medium';
  font-size: 16px;
  word-break: keep-all;
  padding-top: 0.8em;
  padding-bottom: 0.8em;
}
.diar-tab .diar-details-row.diar-details-header .diar-details > .sort-icon {
  background-image: url('../../../../images/SortIcon.svg');
  background-repeat: no-repeat;
  background-position: right;
  width: 9%;
}
.diar-tab .diar-details-row.diar-details-header .diar-details > .sort-icon:hover {
  opacity: 0.7 !important;
  cursor: pointer;
}
.diar-tab .diar-details-row.active-row {
  background: #e3ddda !important;
  border-bottom: 1px solid #FFF;
  border-radius: 0 0 5px 5px;
  transition: background 1s ease-out;
}
.diar-tab .diar-details-row.active-row .diar-details {
  font-family: 'Roboto-Regular';
  border-bottom: 1px solid #D3E3F7;
}
@media (max-width: 1024px) {
  .diar-tab .diar-details-row.active-row .diar-details {
    font-weight: normal;
  }
}
.diar-tab .diar-details-row.active-row .diar-details .show-more-btn {
  background-image: url('../../../../images/MoreButtonDark.svg');
}
.diar-tab .diar-details-row.active-row .diar-more-details {
  display: flex;
  transition: all 0.3s ease;
}
.diar-tab .diar-details-row .diar-details {
  display: flex;
  width: 100%;
  min-height: 50px;
  align-items: center;
  font-family: 'Roboto-Light';
  font-size: 14px;
  word-break: break-word;
}
.diar-tab .diar-details-row .diar-details label {
  display: none;
  margin-right: 1em;
  width: 100px;
}
@media (max-width: 1024px) {
  .diar-tab .diar-details-row .diar-details label {
    display: inline-block;
    width: 60%;
    margin-right: 0;
    font-family: 'Roboto-Medium';
  }
}
@media (max-width: 400px) {
  .diar-tab .diar-details-row .diar-details label {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .diar-tab .diar-details-row .diar-details span {
    width: 40%;
    padding-top: 0.5em;
    text-align: left;
  }
}
@media (max-width: 400px) {
  .diar-tab .diar-details-row .diar-details span {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .diar-tab .diar-details-row .diar-details {
    flex-direction: column;
    padding: 1em;
    width: auto;
  }
}
.diar-tab .diar-details-row .diar-details > div {
  padding: 0 15px;
  display: flex;
  width: 10%;
  align-items: center;
}
@media (max-width: 1024px) {
  .diar-tab .diar-details-row .diar-details > div {
    width: 100% !important;
    padding: 5px 0;
  }
}
@media (max-width: 500px) {
  .diar-tab .diar-details-row .diar-details > div {
    padding: 2px 0;
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .diar-tab .diar-details-row .diar-details > div {
    flex-direction: column;
  }
}
.diar-tab .diar-details-row .diar-details .show-more-btn {
  min-height: 50px;
  background-repeat: no-repeat;
  background-image: url('../../../../images/MoreButton.svg');
  background-position: center;
}
@media (max-width: 1024px) {
  .diar-tab .diar-details-row .diar-details .show-more-btn {
    min-height: 15px;
    background-position: right;
  }
}
.diar-tab .diar-details-row .diar-more-details {
  padding: 10px;
  display: none;
  flex-direction: column;
}
.diar-tab .diar-details-row .diar-more-details div {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
}
@media (max-width: 400px) {
  .diar-tab .diar-details-row .diar-more-details div {
    flex-direction: column;
    align-items: flex-start;
  }
}
.diar-tab .diar-details-row .diar-more-details div .more-details-links {
  display: flex;
  padding: 15px 10px 15px 40px;
  min-width: 15%;
  background-repeat: no-repeat;
  background-position-y: center;
  background-position-x: 10px;
  background-size: 18px;
  font-size: 12px;
  border-radius: 0 5px 5px 0;
  font-family: 'Roboto-Light';
  margin-right: 4%;
  cursor: pointer;
}
.diar-tab .diar-details-row .diar-more-details div .more-details-links:hover {
  background-color: rgba(4, 128, 65, 0.25);
  font-family: 'Roboto-Regular';
  color: #048041;
}
.diar-tab .diar-details-row .diar-more-details div .more-details-links.edit-icon {
  background-image: url('../../../../images/EditDocVector.svg');
}
.diar-tab .diar-details-row .diar-more-details div .more-details-links.review-icon {
  background-image: url('../../../../images/ReviewVector.svg');
}
.diar-tab .diar-details-row .diar-more-details div .more-details-links.status-log-icon {
  background-image: url('../../../../images/StatusLog.svg');
}
.diar-tab .diar-details-row .diar-more-details div .more-details-links.change-request-icon {
  background-image: url('../../../../images/ChangeRequest.svg');
}
.diar-tab .diar-details-row .diar-more-details div .more-details-links.assign-icon {
  background-image: url('../../../../images/ReviewAssign.svg');
  background-size: 36px;
  background-position-x: 0;
}
.diar-tab .diar-details-row .diar-more-details div .more-details-links.view-icon {
  background-image: url('../../../../images/ApplicationVector.svg');
}
.diar-tab .diar-details-row .diar-more-details div .more-details-links.doc-icon {
  background-image: url('../../../../images/DocumentVector.svg');
}
.diar-tab .diar-details-row .diar-more-details div .more-details-links.flag-icon {
  background-image: url('../../../../images/FlagVector.svg');
}
.diar-tab .diar-details-row .diar-more-details div .more-details-links.suspend-icon {
  background-image: url('../../../../images/SuspendUserVector.svg');
}
.diar-tab .diar-details-row .diar-more-details div .more-details-links.reinstate-icon {
  background-image: url('../../../../images/ReinstateUserVector.svg');
}
.diar-tab .diar-details-row .diar-more-details div .more-details-links.mail-icon {
  background-image: url('../../../../images/MailVector.svg');
}
.diar-tab .diar-details-row .diar-more-details div .more-details-links.label-icon {
  background-image: url('../../../../images/label-icon.svg');
}
.diar-tab .no-records-found {
  text-align: center;
  padding: 1em;
  background: #F8F7F6;
}
.diar-tab .no-checkbox {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  display: flex;
}
.diar-filterCal .react-daterange-picker__calendar--open {
  left: -25px !important;
}
.incent-Btn {
  max-width: 420px;
  width: 200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
}
@media (max-width: 896px) {
  .incent-Btn {
    width: 100%;
    max-width: 600px;
  }
}
@media (max-width: 550px) {
  .incent-Btn {
    width: 100%;
  }
}
@media (max-width: 896px) {
  .secondRow {
    display: flex !important;
    width: 100%;
  }
}
@media (max-width: 550px) {
  .secondRow {
    display: flex !important;
    flex-direction: column  !important;
    align-items: baseline !important;
  }
}
.secondRow .filter-btn.incent-Btn {
  margin: 0px;
  margin-top: 10px;
}
