.modal-content .route-heading {
  text-transform: uppercase;
  font-size: 24px;
}
.modal-content .text-container {
  border: 0.5px solid #E3DDDA;
  text-align: left;
  padding: 10px;
  height: 300px;
  overflow-y: auto;
}
.modal-content .text-container p {
  text-align: left;
}
.toast-message {
  position: fixed;
  display: block;
  left: 0;
  width: 100%;
  top: 100px;
  text-align: center;
  z-index: 99;
  padding-left: 40%;
}
.toast-message .container {
  display: flex;
  flex-direction: row;
  background: #E7B100;
  color: white;
  border-radius: 5px;
  padding: 10px;
  width: fit-content;
  font-family: 'Roboto-Bold';
}
.toast-message .container .image {
  height: 20px;
  width: 20px;
}
.confirmation-details-modal .quill .ql-toolbar {
  display: none;
}
.check-box-checked {
  background-color: #048041;
}
.main-container {
  width: 100%;
  display: flex;
  flex-direction: column;
}
.review-application-container {
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}
.review-application-container .section-header {
  padding-top: 2.5em;
  padding-bottom: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 896px) {
  .review-application-container .section-header {
    padding-top: 5em;
    padding-bottom: 1em;
  }
}
.review-application-container .section-header .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  max-width: 600px;
  margin: auto;
}
.review-application-container .container-box {
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #E3DDDA;
  text-align: center;
  text-align: left;
  overflow: overlay;
  margin-bottom: 30px;
}
@media (max-width: 896px) {
  .review-application-container .container-box {
    width: 95%;
    margin: auto;
    text-align: center;
    padding: 5px;
  }
}
.review-application-container .container-box .application-summary {
  margin: 1.5em;
}
.review-application-container .container-box .application-summary .step-header-container .step-header-container-title {
  text-align: left;
  color: #048041;
  text-transform: uppercase;
  font-size: 18px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
}
.review-application-container .container-box .application-summary .form-section {
  display: flex;
}
.review-application-container .container-box .application-summary .form-section .details {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}
.review-application-container .container-box .application-summary .form-section .details .form-inner-elements {
  display: flex;
  width: 100%;
  margin: 25px 0;
  align-items: center;
}
@media (max-width: 896px) {
  .review-application-container .container-box .application-summary .form-section .details .form-inner-elements {
    flex-direction: column;
    align-items: flex-start;
    margin: 10px 0;
  }
}
.review-application-container .container-box .application-summary .form-section .details .form-inner-elements .application-form-label {
  font-weight: bold;
  flex: 50% 0;
  text-align: left;
  font-size: 16px;
  padding: 0 2em;
}
@media (max-width: 896px) {
  .review-application-container .container-box .application-summary .form-section .details .form-inner-elements .application-form-label {
    margin: 10px 0;
    padding: 0;
  }
}
.review-application-container .container-box .application-summary .form-section .details .form-inner-elements .application-form-element {
  flex: 0 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.review-application-container .container-box .application-summary .form-section .details .form-inner-elements .application-form-element .info-icon {
  margin: 0 10px;
  width: 20px;
  position: absolute;
  right: 0;
}
@media (max-width: 896px) {
  .review-application-container .container-box .application-summary .form-section .details .form-inner-elements .application-form-element .info-icon {
    margin: 0 5px;
    position: relative;
  }
}
.review-application-container .container-box .application-summary .form-section .details .form-inner-elements .application-form-element .input-container textarea {
  width: 18%;
}
.review-application-container .container-box .application-summary .form-section .details .form-inner-elements .application-form-element .error-message {
  padding: 5px 0;
}
.review-application-container .container-box .application-summary .form-section .details .form-inner-elements .application-form-element .input-box {
  width: 240px;
  height: 24px;
  font-size: 16px;
  font-family: 'Roboto-Regular';
}
.review-application-container .container-box .application-summary .form-section .details .form-inner-elements .application-form-element .input-box.enerva-dropdown {
  height: 44px;
}
.review-application-container .container-box .application-summary .form-section .details .form-inner-elements .application-form-element .input-box.textarea-box {
  width: 240px;
  height: 40px;
}
.review-application-container .container-box .application-summary .form-section .details .form-inner-elements .application-form-element .date-picker {
  max-width: 170px;
}
.review-application-container .container-box .application-summary .form-section .vertical-separator-box {
  display: flex;
  opacity: 0.5;
  flex: 8%;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
}
.review-application-container .container-box .application-summary .form-section .vertical-separator-box .separator {
  background-color: #048041;
  width: 1px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 32%;
  height: 85%;
}
@media (max-width: 896px) {
  .review-application-container .container-box .application-summary .form-section .vertical-separator-box {
    display: none;
  }
}
.review-application-container .start-review-container {
  width: 100%;
  text-align: center;
  text-align: left;
  overflow: overlay;
}
@media (max-width: 896px) {
  .review-application-container .start-review-container {
    width: 95%;
    margin: auto;
    text-align: center;
    padding: 5px;
  }
}
.review-application-container .start-review-container .static-btn {
  margin: 0 1.5em;
  display: flex;
  justify-content: end;
}
.review-application-container .start-review-container .static-btn .heading-btn {
  width: 250px;
  height: 50px;
  background: #f4f4f4;
  border: #f4f4f4;
  color: #E3DDDA;
}
.review-application-container .start-review-container .static-btn .heading-btn:hover {
  opacity: unset;
  cursor: default;
}
.review-application-container .start-review-container .primary-review-start {
  margin: 0 1.5em;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #E3DDDA;
  padding: 1.5em;
  text-align: center;
  text-align: left;
  overflow: overlay;
}
@media (max-width: 896px) {
  .review-application-container .start-review-container .primary-review-start {
    width: 95%;
    margin: auto;
    text-align: center;
    padding: 5px;
  }
}
.review-application-container .start-review-container .primary-review-start .primary-review-start-heading {
  font-size: 16px;
  text-align: center;
}
.review-application-container .start-review-container .primary-review-start .primary-review-start-sub-heading {
  text-align: center;
}
.review-application-container .start-review-container .primary-review-start .primary-review-start-sub-heading span {
  font-family: 'Roboto-Medium';
}
.review-application-container .start-review-container .primary-review-start-button-set {
  margin: 0 1.5em;
  display: flex;
  justify-content: flex-end;
}
.review-application-container .start-review-container .primary-review-start-button-set button {
  width: 250px;
}
.primary-review-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background-color: #E3DDDA;
}
.primary-review-container .section-header {
  padding-top: 2.5em;
  padding-bottom: 2em;
  display: flex;
  justify-content: space-between;
  width: 100%;
}
@media (max-width: 896px) {
  .primary-review-container .section-header {
    padding-top: 5em;
    padding-bottom: 1em;
  }
}
.primary-review-container .section-header .section-title {
  text-align: center;
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  max-width: 600px;
  margin: auto;
}
.primary-review-container .custom-btn {
  width: 100%;
  padding-right: 28px;
  text-align: right;
  margin-bottom: 30px;
}
.primary-review-container .custom-btn .save-btn {
  width: 250px;
  height: 50px;
}
.primary-review-container .tab-section {
  width: 100%;
}
.primary-review-container .tab-section .tabs {
  margin: 1.5em;
  width: auto;
}
.primary-review-container .tab-section .tabs .tab-list {
  justify-content: center;
}
.primary-review-container .tab-section .tabs .tab-list .tab-list-item {
  background-color: #E3DDDA;
  border-bottom: 2px solid #E3DDDA;
}
.primary-review-container .tab-section .tabs .tab-list .tab-list-active {
  background-color: #c9d3c1;
  border-bottom: 2px solid #009639;
}
.primary-review-container .tab-section .tabs .tab-content {
  background: #ffffff;
  padding: 1.5em;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab {
  margin: 1.5em;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .highlight {
  color: #048041;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .terms {
  color: #048041;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .participant-tab-table .responsive-table-container {
  margin-bottom: 30px;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .participant-tab-table .responsive-table-container .responsive-table-row .responsive-table-details span .doc-table {
  height: 150px;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .participant-tab-table .responsive-table-container .responsive-table-row .responsive-table-details span .doc-table .document {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #dce4ec;
  padding: 10px 0;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .participant-tab-table .responsive-table-container .responsive-table-row .responsive-table-details span .doc-table .document .doc-name {
  width: 85%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .participant-tab-table .responsive-table-container .responsive-table-row .responsive-table-details span .doc-table .document:nth-last-child(1) {
  border-bottom: none;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .participant-tab-table .responsive-table-container .responsive-table-row .responsive-table-details:nth-child(3) {
  padding: 0;
  overflow-y: auto;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .form-container .input-container {
  margin: 10px 0;
  display: flex;
  align-items: center;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .form-container .input-container .input-parent .input-container textarea {
  width: 18%;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .form-container .input-container .input-parent .input-label {
  width: 70%;
  margin-right: 10%;
  font-size: 16px;
  text-align: left;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .form-container .input-container .input-parent .input-box {
  width: 20%;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .form-container .input-container .input-parent .textarea-box {
  width: 200px;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .form-container .input-container .input-parent .error-message {
  padding-left: 80%;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .form-container .input-container .info-icon {
  margin-left: 10px;
  width: 10px;
}
.primary-review-container .tab-section .tabs .tab-content .participant-tab .disabled-fields {
  background: #f6f6f5;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab {
  margin: 1.5em;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .facility-tab-table .responsive-table-container {
  margin-bottom: 30px;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .facility-tab-table .responsive-table-container .responsive-table-header {
  position: sticky;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .facility-tab-table .responsive-table-container .responsive-table-row .responsive-table-details span .facility-name .view-facility {
  color: #9fc967;
  text-decoration: underline;
  cursor: pointer;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .facility-tab-table .responsive-table-container .responsive-table-row .responsive-table-details span .doc-table {
  height: 150px;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .facility-tab-table .responsive-table-container .responsive-table-row .responsive-table-details span .doc-table .document {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #dce4ec;
  padding: 10px;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .facility-tab-table .responsive-table-container .responsive-table-row .responsive-table-details span .doc-table .document .doc-name {
  width: 85%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .facility-tab-table .responsive-table-container .responsive-table-row .responsive-table-details span .doc-table .document:nth-last-child(1) {
  border-bottom: none;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .facility-tab-table .responsive-table-container .responsive-table-row .responsive-table-details:nth-child(8) {
  padding: 0;
  overflow-y: auto;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .facility-tab-table .responsive-table-container .in-progress {
  background-color: #dee2dd;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .facility-tab-table .responsive-table-container .in-progress .responsive-table-details:nth-child(8) {
  padding: 0;
  overflow-y: auto;
  background-color: #ffffff;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .facility-tab-table .responsive-table-container .completed {
  background-color: #e3ddda;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .facility-tab-table .responsive-table-container .completed .responsive-table-details:nth-child(8) {
  padding: 0;
  overflow-y: auto;
  background-color: #ffffff;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .stepper-review-container .responsive-table-container {
  margin-bottom: 30px;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .stepper-review-container .responsive-table-container .responsive-table-header {
  position: sticky;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .stepper-review-container .responsive-table-container .responsive-table-row .responsive-table-details span .facility-name .view-facility {
  color: #9fc967;
  text-decoration: underline;
  cursor: pointer;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .stepper-review-container .responsive-table-container .responsive-table-row .responsive-table-details span .doc-table {
  height: 150px;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .stepper-review-container .responsive-table-container .responsive-table-row .responsive-table-details span .doc-table .document {
  display: flex;
  flex-direction: row;
  border-bottom: 2px solid #dce4ec;
  padding: 10px;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .stepper-review-container .responsive-table-container .responsive-table-row .responsive-table-details span .doc-table .document .doc-name {
  width: 85%;
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .stepper-review-container .responsive-table-container .responsive-table-row .responsive-table-details span .doc-table .document:nth-last-child(1) {
  border-bottom: none;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .stepper-review-container .responsive-table-container .responsive-table-row .responsive-table-details:nth-last-child(1) {
  padding: 10px 0;
  overflow-y: auto;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .stepper-review-container .responsive-table-container .in-progress {
  background-color: #dee2dd;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .stepper-review-container .responsive-table-container .in-progress .responsive-table-details:nth-last-child(1) {
  padding: 0;
  overflow-y: auto;
  background-color: #ffffff;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .stepper-review-container .responsive-table-container .completed {
  background-color: #e3ddda;
}
.primary-review-container .tab-section .tabs .tab-content .facility-tab .stepper-review-container .responsive-table-container .completed .responsive-table-details:nth-last-child(1) {
  padding: 0;
  overflow-y: auto;
  background-color: #ffffff;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab {
  display: grid;
  grid-template-columns: 60% 40%;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container {
  padding: 1%;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .container-head {
  display: flex;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .container-head .section-title {
  font-size: 18px;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .container-head .filter-component .filterIcon {
  background-image: url('../../../images/ReviewSummaryFilter.svg');
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info {
  border: 1px solid #f0edeb;
  padding: 10px;
  max-height: 800px;
  overflow-y: auto;
  height: 800px;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .q-header {
  text-transform: uppercase;
  font-size: 18px;
  color: #048041;
  padding-top: 10px;
  padding-bottom: 10px;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .q-header .show-details {
  text-decoration: underline;
  cursor: pointer;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .question-ans {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .question-ans .question {
  font-size: 14px;
  color: #686c78;
  flex: 0 70%;
  margin-right: 10%;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .question-ans .ans {
  font-size: 14px;
  color: #686c78;
  flex: 0 20%;
  display: flex;
  flex-direction: column;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .question-ans .ans .turncate-text {
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .question-ans .ans .expand {
  color: #00629b;
  text-decoration: underline;
  cursor: pointer;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .participant-question-ans .question-ans:nth-child(even) {
  background-color: #f4f3f3;
  padding: 5px;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .q-header {
  text-transform: uppercase;
  font-size: 18px;
  color: #048041;
  padding-top: 30px;
  padding-bottom: 10px;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .q-header .show-details {
  text-decoration: underline;
  cursor: pointer;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .question-ans {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .question-ans .question {
  font-size: 14px;
  color: #686c78;
  flex: 0 70%;
  margin-right: 10%;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .question-ans .ans {
  font-size: 14px;
  color: #686c78;
  flex: 0 20%;
  display: flex;
  flex-direction: column;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .question-ans .ans .view-file-btn {
  border: 1px solid black;
  padding: 5px;
  width: fit-content;
  cursor: pointer;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .question-ans .ans .turncate-text {
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .question-ans .ans .expand {
  color: #00629b;
  text-decoration: underline;
  cursor: pointer;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .summary-container .review-summar-info .questions-ans .facility-question-ans .question-ans:nth-child(even) {
  background-color: #f4f3f3;
  padding: 5px;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .review-decision-container {
  padding: 1%;
  background-color: #E3DDDA;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .review-decision-container .section-title {
  font-size: 18px;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .review-decision-container .decision-options {
  padding-bottom: 30px;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .review-decision-container .note-section .note-label {
  color: #048041;
  font-size: 18px;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .review-decision-container .note-section .input-parent {
  margin-top: 10px;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .review-decision-container .note-section .input-parent .input-container {
  background-color: #ffffff;
  height: 730px;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .review-decision-container .note-section .input-parent .input-container .quill {
  max-height: none;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .review-decision-container .note-section .input-parent .input-container .quill p {
  color: black;
}
.primary-review-container .tab-section .tabs .tab-content .summary-tab .review-decision-container .secondary .input-parent .input-container {
  height: 340px;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .terms {
  color: #048041;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .stepper-next-btn {
  display: flex;
  align-items: center;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .stepper-next-btn img {
  margin-left: 5px;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .step-header-title {
  font-size: 18px;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .project-eligibility-review-questions .responsive-table-details {
  width: 50%;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .measures-review-questions .responsive-table-container {
  max-height: 400px;
  overflow-y: auto;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .measures-review-questions .responsive-table-details .facility-name .view-facility {
  color: #9fc967;
  text-decoration: underline;
  cursor: pointer;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .measures-review-questions .responsive-table-details .criteria-text {
  display: contents;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .measures-review-questions .responsive-table-details .criteria-text .turncate-text {
  font-size: 14px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .measures-review-questions .responsive-table-details .criteria-text .expand {
  color: #00629b;
  text-decoration: underline;
  cursor: pointer;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .project-costs-review-questions .responsive-table-details {
  width: 100%;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .project-costs-review-questions .view-btn {
  text-decoration: underline;
  color: #00629b;
  cursor: pointer;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .facility-review-questions .responsive-table-details {
  width: 100%;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .facility-review-questions .responsive-table-details .view-btn {
  text-decoration: underline;
  color: #00629b;
  cursor: pointer;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .disposal-review-questions .responsive-table-details {
  width: 100%;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .disposal-review-questions .responsive-table-details .view-btn {
  text-decoration: underline;
  color: #00629b;
  cursor: pointer;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .highlight {
  color: #048041;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container {
  margin: 10px 0;
  display: flex;
  align-items: center;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .input-container textarea {
  width: 18%;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .input-container .upload-section {
  display: flex;
  flex-direction: column;
  width: 20%;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .input-container .upload-section .error-message {
  font-family: 'Roboto-Light';
  font-size: 12px;
  display: flex;
  padding-left: 8%;
  padding-top: 5px;
  margin-top: 0.15em;
  color: #DB1900;
  text-align: left;
  line-height: 12px;
}
@media (max-width: 896px) {
  .primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .input-container .upload-section .error-message {
    padding-left: 0;
  }
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .input-container .upload-section .error-message .error-icon {
  position: relative;
  opacity: 1;
  margin-right: 5px;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .input-container .upload-section .error-message .error-icon img {
  width: 8px;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .input-container .switch-container {
  flex: none;
  width: 20%;
  justify-content: flex-end;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .input-label {
  width: 70%;
  margin-right: 10%;
  font-size: 16px;
  text-align: left;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .input-box {
  width: 20%;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .textarea-box {
  width: 200px;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .file-upload-container {
  flex: none;
  width: 20%;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .file-upload-container .file-dropzone {
  flex-direction: row;
  padding: 0 1em;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .file-upload-container .file-dropzone svg {
  width: 100px;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .file-upload-container .file-dropzone p {
  font-size: 12px;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .input-parent .error-message {
  padding-left: 80%;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .form-container .input-container .info-icon {
  margin-left: 10px;
  width: 10px;
}
.primary-review-container .tab-section .tabs .tab-content .stepper-review-container .stepper-details .disabled-fields {
  background: #f6f6f5;
}
.primary-review-container .tab-section .tabs div[class*="flag-New Assigned Pre-Project Application"] {
  background-color: #00629b;
  color: #ffffff;
  padding: 5px;
  text-align: center;
}
.primary-review-container .tab-section .tabs div[class*="flag-New Assigned Post-Project Application"] {
  background-color: #00629b;
  color: #ffffff;
  padding: 5px;
  text-align: center;
}
.primary-review-container .tab-section .tabs div[class*="flag-QA-QC - Mandatory"] {
  background-color: #87bc40;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.primary-review-container .tab-section .tabs div[class*="flag-QA-QC - Random Selection"] {
  background-color: #FC427B;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.primary-review-container .tab-section .tabs div[class*="flag-Peer Review Assigned"] {
  background-color: #368B85;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.primary-review-container .tab-section .tabs div[class*="flag-Peer Review Rejected"] {
  background-color: #05C3DE;
  color: #ffffff;
  text-align: center;
  padding: 5px;
}
.primary-review-container .tab-section .tabs div[class*="New Assigned Pre-Project Application"] {
  border: 4px #00629b solid;
}
.primary-review-container .tab-section .tabs div[class*="New Assigned Post-Project Application"] {
  border: 4px #00629b solid;
}
.primary-review-container .tab-section .tabs div[class*="QA-QC - Mandatory"] {
  border: 4px #87bc40 solid;
}
.primary-review-container .tab-section .tabs div[class*="QA-QC - Random Selection"] {
  border: 4px #FC427B solid;
}
.primary-review-container .tab-section .tabs div[class*="Peer Review Assigned"] {
  border: 4px #368B85 solid;
}
.primary-review-container .tab-section .tabs div[class*="Peer Review Rejected"] {
  border: 4px #05C3DE solid;
}
.confirm-review-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: hidden;
}
@media (max-width: 1024px) {
  .confirm-review-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
    padding-bottom: 2em;
  }
}
@media (max-width: 896px) {
  .confirm-review-container {
    overflow-x: hidden;
    max-width: 90vw;
  }
}
.confirm-review-container .uploaded-documents-table {
  border: 1px solid #E3DDDA;
  padding: 20px;
  width: 92%;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 60vh;
}
.confirm-review-container .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .confirm-review-container .section-header {
    padding: 10px 0;
  }
}
.confirm-review-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media (max-width: 1024px) {
  .confirm-review-container .section-header .section-title {
    font-size: 16px;
  }
}
.confirm-review-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.confirm-review-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.confirm-review-container .section-below-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .confirm-review-container .section-below-header {
    padding: 10px 0;
    flex-direction: column;
  }
}
.confirm-review-container .section-below-header .title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .confirm-review-container .section-below-header .title {
    font-size: 16px;
    margin: 0;
  }
}
.confirm-review-container .container {
  width: 97%;
  overflow-y: auto;
  box-shadow: 0px 0px 0px #5A71D01B;
  border: 0.5px solid #98989A;
  padding: 10px;
  max-height: 370px;
  text-align: center;
}
@media (max-width: 896px) {
  .confirm-review-container .container {
    width: 92%;
    max-height: 260px;
  }
}
.confirm-review-container .container span {
  color: #048041;
}
.confirm-review-container .container .facility-table {
  min-height: 50vh;
}
.confirm-review-container .container .facility-table .horizontal-bar {
  height: 0px;
  border-bottom: 1px solid #E3DDDA;
  margin: 3em 25%;
  width: 50%;
}
.confirm-review-container .container .facility-table .message-block {
  display: grid;
  grid-template-columns: 55% 5% 40%;
}
@media (max-width: 896px) {
  .confirm-review-container .container .facility-table .message-block {
    grid-template-rows: auto;
  }
}
.confirm-review-container .container .facility-table .message-block .fields-block .decision-options {
  margin-top: 30px;
}
.confirm-review-container .container .facility-table .message-block .fields-block .decision-options label {
  text-align: left;
  padding-left: 0;
  flex: 40% 1;
}
.confirm-review-container .container .facility-table .message-block .fields-block .decision-options .date-picker {
  border-bottom: 1px solid #048041;
}
.confirm-review-container .container .facility-table .message-block .fields-block .decision-options .date-picker svg {
  fill: #048041;
}
.confirm-review-container .container .facility-table .message-block .message p span {
  color: #048041;
}
.confirm-review-container .container .facility-table .message-block .vertical-separator {
  width: 4%;
  min-height: 120px;
}
.confirm-review-container .container .facility-table .message-block .vertical-separator .separator {
  background-color: #E3DDDA;
  width: 1px;
  height: 110px;
  border: none;
  margin-top: 10px;
}
@media (max-width: 1024px) {
  .confirm-review-container .container .facility-table .message-block .vertical-separator {
    display: none;
  }
}
.confirm-review-container .container .facility-table .note-section {
  margin-top: 20px;
}
.confirm-review-container .container .facility-table .note-section label {
  display: flex;
  font-size: 16px;
}
.confirm-review-container .container .facility-table .note-section .text-disabled-container {
  border: 0.5px solid #98989A;
  padding: 10px;
  height: 25vh;
  text-align: left;
  background: #d9d9d9;
  margin-top: 20px;
}
.confirm-review-container .container .secondary-facility-table .message-block {
  grid-template: none;
}
.confirm-review-container .container .secondary-facility-table .fields-block {
  border: 1px solid #E3DDDA;
  padding: 20px;
}
.confirm-review-container .button-set {
  width: 100%;
  margin-top: 20px;
}
.confirm-review-container .no-records-found {
  text-align: center;
  padding: 1em;
  background: #F8F7F6;
}
