.eye-icon {
  width: 18px;
  cursor: pointer;
}
.pass-input {
  width: 100%;
  color: #009639;
  font-size: 14px;
  border-width: 0px;
  appearance: none;
  outline: none;
  padding: 0px !important;
}
.pass-input:focus {
  appearance: none;
  outline: none;
  box-shadow: unset;
}
.pass-input:hover {
  appearance: none;
  outline: none;
  box-shadow: unset;
}
.pass-input:active {
  appearance: none;
  outline: none;
  box-shadow: unset;
}
