.ipn-spot-check {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}
.ipn-spot-check .page-loader {
  width: 50vw;
  left: unset;
}
.ipn-spot-check p {
  font-family: 'Roboto-Light';
  font-size: 18px;
  color: #686C6D;
}
.ipn-spot-check p span {
  color: #048041;
  font-weight: bold;
}
@media (max-width: 1024px) {
  .ipn-spot-check p {
    font-size: 12px;
  }
}
.ipn-spot-check .button-set {
  float: right;
  justify-content: flex-end !important;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  padding-bottom: 10px;
}
@media (max-width: 400px) {
  .ipn-spot-check .button-set {
    flex-direction: column;
  }
  .ipn-spot-check .button-set .enerva-btn {
    margin: 20px 0 0 0;
    width: 100%;
  }
}
.ipn-spot-check .enerva-btn {
  width: 100%;
  margin-top: 25px;
  max-width: 300px;
}
@media (max-width: 1024px) {
  .ipn-spot-check .enerva-btn {
    width: 240px;
  }
}
@media (max-width: 1024px) {
  .ipn-spot-check {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
    padding-bottom: 2em;
  }
}
.ipn-spot-check .section-below-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
}
@media (max-width: 896px) {
  .ipn-spot-check .section-below-header {
    padding: 10px 0;
    flex-direction: column;
    align-items: flex-start;
  }
}
@media (max-width: 896px) {
  .ipn-spot-check .section-below-header .spot-check-search {
    width: 100%;
  }
}
.ipn-spot-check .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: "Roboto-Medium";
  font-weight: normal;
  text-align: left;
  width: 100%;
  margin: 0;
}
@media (max-width: 1024px) {
  .ipn-spot-check .section-below-header .section-title {
    font-size: 16px;
  }
}
.ipn-spot-check .empty-summary-container {
  width: 84%;
}
.ipn-spot-check .apps-list {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: 1px solid #f2f2f2;
}
.ipn-spot-check .apps-list .app {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  background-color: rgba(0, 0, 0, 0.04);
  margin-bottom: 10px;
  align-items: center;
  padding: 0 15px;
  height: 50px;
}
.ipn-spot-check .apps-list .app .column {
  width: 33%;
  font-size: 12px;
  font-weight: 'Roboto-Light';
}
.ipn-spot-check .apps-list .app .column.right {
  text-align: right;
}
@media (max-width: 896px) {
  .ipn-spot-check .ReactModalPortal .modal-content .button-set {
    flex-direction: column !important;
  }
}
.ipn-spot-check .section-summary {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 1.5em 0;
  width: 100%;
}
@media (max-width: 896px) {
  .ipn-spot-check .section-summary {
    padding: 10px 0;
    flex-direction: column;
  }
}
.ipn-spot-check .section-summary .section-block {
  padding: 0 2% 0 0;
  text-align: left;
  font-family: "Roboto-Medium";
  font-size: 16px;
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
@media (max-width: 1024px) {
  .ipn-spot-check .section-summary .section-block {
    width: 100%;
    margin: 10px 0;
  }
}
.ipn-spot-check .section-summary .section-block .info {
  color: #333131;
  word-break: break-word;
  padding: 10px;
  border-radius: 6px;
  background-color: #E3DDDA;
}
.ipn-spot-check .section-summary .section-block .label {
  color: #333131;
  word-break: break-word;
  padding-right: 15px;
}
.ipn-spot-check .section-summary .section-block .label.right {
  text-align: right;
}
@media (max-width: 1024px) {
  .ipn-spot-check .section-summary .section-block .label.right {
    text-align: left;
  }
}
.ipn-spot-check .section-summary .section-block .totals {
  cursor: not-allowed;
  background-color: #e3ddda;
  border-radius: 6px;
  padding: 10px;
  color: #535765;
  min-width: 150px;
  word-break: break-word;
}
@media (max-width: 1024px) {
  .ipn-spot-check .section-summary .section-block .totals {
    width: 50%;
  }
}
@media (max-width: 321px) {
  .ipn-spot-check .section-summary .section-block .totals {
    width: 40%;
    min-width: 0;
  }
}
.ipn-spot-check .section-summary :nth-child(2) {
  justify-content: flex-end;
}
@media (max-width: 896px) {
  .ipn-spot-check .section-summary :nth-child(2) {
    justify-content: flex-start;
  }
}
.ipn-spot-check .section-below-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  margin-bottom: 10px;
  align-items: center;
}
@media (max-width: 896px) {
  .ipn-spot-check .section-below-header {
    padding: 10px 0;
    flex-direction: column;
    align-items: flex-start;
  }
}
.ipn-spot-check .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: "Roboto-Medium";
  font-weight: normal;
  text-align: left;
  width: 70%;
  margin: 0;
}
@media (max-width: 1024px) {
  .ipn-spot-check .section-below-header .section-title {
    font-size: 16px;
    margin: 0 0 0 10px;
  }
}
@media (max-width: 896px) {
  .ipn-spot-check .section-below-header .section-title {
    font-size: 14px;
    margin: 0 0 10px 0;
    width: auto;
  }
}
@media (max-width: 320px) {
  .ipn-spot-check .section-below-header .section-title {
    margin: 0 0 0 0px;
    font-size: 14px;
  }
}
.ipn-spot-check .main-container {
  border: 2px solid #dedede;
  padding: 20px;
  width: calc(98% - 30px);
  max-height: 60vh;
}
@media (max-width: 896px) {
  .ipn-spot-check .main-container {
    width: calc(92% - 20px);
    height: 40vh;
    overflow-y: auto;
  }
}
.ipn-spot-check .main-container .eft-details {
  display: flex;
  flex-direction: row;
  font-family: 'Roboto-Medium';
  font-size: 16px;
  align-items: center;
  padding: 0.8em 0;
}
@media (max-width: 1024px) {
  .ipn-spot-check .main-container .eft-details {
    display: none;
  }
}
.ipn-spot-check .main-container .eft-details .check-box-checked {
  background-color: #048041 !important;
}
.ipn-spot-check .main-container .eft-details > div {
  padding: 0 15px;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
}
@media (max-width: 1024px) {
  .ipn-spot-check .main-container .eft-details > div {
    width: 100% !important;
    padding: 5px 0;
  }
}
@media (max-width: 500px) {
  .ipn-spot-check .main-container .eft-details > div {
    padding: 2px 0;
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .ipn-spot-check .main-container .eft-details > div {
    flex-direction: column;
  }
}
.ipn-spot-check .main-container .apps-container {
  max-height: 50vh;
  overflow-y: auto;
}
@media (max-width: 896px) {
  .ipn-spot-check .main-container .apps-container {
    max-height: 38vh;
  }
}
.ipn-spot-check .main-container .apps-container .eft-apps .check-box-checked {
  background-color: #048041 !important;
}
.ipn-spot-check .main-container .apps-container .eft-apps .check-box-checked .check-mark {
  position: unset;
}
.ipn-spot-check .main-container .apps-container .eft-apps .more-options {
  min-height: 50px;
  cursor: pointer;
  background-repeat: no-repeat;
  background-image: url('../../../../images/EyeOpen.svg');
  background-position: center;
}
@media (max-width: 1024px) {
  .ipn-spot-check .main-container .apps-container .eft-apps .more-options {
    min-height: 15px;
    background-position: right;
  }
}
.ipn-spot-check .main-container .apps-container .eft-apps .eft-details-row {
  display: flex;
  width: 100%;
  min-height: 50px;
  align-items: center;
  font-family: 'Roboto-Light';
  font-size: 14px;
  word-break: break-word;
}
.ipn-spot-check .main-container .apps-container .eft-apps .eft-details-row label {
  display: none;
  margin-right: 1em;
  width: 100px;
}
@media (max-width: 1024px) {
  .ipn-spot-check .main-container .apps-container .eft-apps .eft-details-row label {
    display: inline-block;
    width: 60%;
    margin-right: 0;
    font-family: 'Roboto-Medium';
  }
}
@media (max-width: 400px) {
  .ipn-spot-check .main-container .apps-container .eft-apps .eft-details-row label {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .ipn-spot-check .main-container .apps-container .eft-apps .eft-details-row span {
    width: 40%;
    padding-top: 0.5em;
    text-align: left;
  }
}
@media (max-width: 400px) {
  .ipn-spot-check .main-container .apps-container .eft-apps .eft-details-row span {
    width: 100%;
    text-align: unset;
  }
  .ipn-spot-check .main-container .apps-container .eft-apps .eft-details-row span > div {
    margin-left: 45%;
  }
}
@media (max-width: 1024px) {
  .ipn-spot-check .main-container .apps-container .eft-apps .eft-details-row {
    flex-direction: column;
    padding: 1em;
    width: auto;
  }
}
.ipn-spot-check .main-container .apps-container .eft-apps .eft-details-row > div {
  padding: 0 15px;
  display: flex;
  flex-grow: 1;
  flex-basis: 0;
  align-items: center;
}
@media (max-width: 1024px) {
  .ipn-spot-check .main-container .apps-container .eft-apps .eft-details-row > div {
    width: 100% !important;
    padding: 5px 0;
  }
}
@media (max-width: 500px) {
  .ipn-spot-check .main-container .apps-container .eft-apps .eft-details-row > div {
    padding: 2px 0;
    font-size: 14px;
  }
}
@media (max-width: 400px) {
  .ipn-spot-check .main-container .apps-container .eft-apps .eft-details-row > div {
    flex-direction: column;
  }
}
.ipn-spot-check .main-container .apps-container .eft-apps .more-details {
  display: none;
  transition: all 0.3s ease;
}
.ipn-spot-check .main-container .apps-container .eft-apps .more-details div {
  margin-bottom: 10px;
}
.ipn-spot-check .main-container .apps-container .eft-apps.active-row .more-options {
  background-image: url('../../../../images/EyeClose.svg');
}
.ipn-spot-check .main-container .apps-container .eft-apps.active-row .more-details {
  display: flex;
  flex-direction: column;
  padding: 15px 0 15px 20%;
  font-size: 14px;
}
@media (max-width: 1024px) {
  .ipn-spot-check .main-container .apps-container .eft-apps.active-row .more-details {
    padding: 15px 0;
  }
}
.ipn-spot-check .main-container .apps-container .eft-apps.active-row .more-details div {
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 30% 70%;
}
@media (max-width: 400px) {
  .ipn-spot-check .main-container .apps-container .eft-apps.active-row .more-details div {
    display: flex;
    flex-direction: column;
  }
}
.ipn-spot-check .main-container .apps-container .eft-apps.active-row .more-details .more-detail-label {
  font-family: 'Roboto-Medium';
  padding: 0 10px;
}
@media (min-width: 1023px) {
  .ipn-spot-check .main-container .apps-container .eft-apps.active-row .more-details .more-detail-label {
    text-align: right;
  }
}
.ipn-spot-check .main-container .apps-container .eft-apps.active-row .more-details .more-detail-value {
  font-family: 'Roboto-Light';
}
@media (min-width: 1023px) {
  .ipn-spot-check .main-container .apps-container .eft-apps.active-row .more-details .more-detail-value {
    text-align: left;
  }
}
.ipn-spot-check .main-container .apps-container .eft-apps:nth-child(odd) {
  background: #F8F7F6;
}
.ipn-spot-check .main-container .note {
  margin-top: 30px;
}
.ipn-spot-check .main-container .note .note-label {
  width: 100%;
  text-align: left;
  color: #333131;
  font-family: 'Roboto-Medium';
  padding-bottom: 15px;
}
.ipn-spot-check .main-container .note .note-value {
  width: calc(98% - 10px);
  height: 90px;
  border: 2px solid #dedede;
  text-align: left;
  padding: 10px;
}
.ipn-spot-check .main-container .note .note-value span {
  color: #048041;
}
.ipn-spot-check .outer-container {
  border: 2px solid #dedede;
  width: 100%;
  max-height: 30vh;
}
.ipn-spot-check .outer-container .apps-container {
  height: 20vh;
}
.ipn-spot-check .message-container {
  display: flex;
}
@media (max-width: 896px) {
  .ipn-spot-check .message-container {
    flex-direction: column;
  }
}
.ipn-spot-check .message-container .message-block {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.ipn-spot-check .message-container .message-block .vertical-separator {
  width: 4%;
  display: flex;
  align-items: center;
}
.ipn-spot-check .message-container .message-block .vertical-separator .separator {
  background-color: #E3DDDA;
  width: 1px;
  margin: 0 6px;
  border-left: 2px solid #048041;
}
@media (max-width: 896px) {
  .ipn-spot-check .message-container .message-block .vertical-separator {
    display: none;
  }
}
.ipn-spot-check .message-container .message-para {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;
}
.ipn-spot-check .message-container .message-para .input-parent {
  margin-bottom: 15px;
}
.ipn-spot-check .message-container .message-para .input-parent .input-label {
  font-size: 15px;
}
.ipn-spot-check .empty-container {
  background-color: #048041;
  align-self: flex-end;
  width: 200px;
}
.ipn-spot-check .selection-btn {
  background-color: #048041;
  align-self: flex-end;
}
@media (max-width: 896px) {
  .ipn-spot-check .selection-btn {
    margin: 30px 0 0 0;
    width: 100%;
  }
}
