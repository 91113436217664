.assign-payment-forapproval-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: hidden;
  overflow-x: hidden;
}
.assign-payment-forapproval-container .modal-content .input-container {
  margin-top: 0 !important;
}
.assign-payment-forapproval-container .button-block {
  display: flex;
  padding: 1em 0;
  width: 100%;
  flex-direction: column;
}
@media (max-width: 415px) {
  .assign-payment-forapproval-container .button-block .button-set .enerva-btn {
    font-size: 12px;
  }
}
@media (max-width: 1024px) {
  .assign-payment-forapproval-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
  }
}
.assign-payment-forapproval-container .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .assign-payment-forapproval-container .section-header {
    padding: 10px 0;
  }
}
.assign-payment-forapproval-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media (max-width: 1024px) {
  .assign-payment-forapproval-container .section-header .section-title {
    font-size: 16px;
  }
}
.assign-payment-forapproval-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-size: 'Roboto-Medium';
}
.assign-payment-forapproval-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.assign-payment-forapproval-container .section-below-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .assign-payment-forapproval-container .section-below-header {
    padding: 10px 0;
    flex-direction: column;
  }
}
.assign-payment-forapproval-container .section-below-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .assign-payment-forapproval-container .section-below-header .section-title {
    font-size: 16px;
  }
}
@media (max-width: 415px) {
  .assign-payment-forapproval-container .section-below-header .section-title {
    font-size: 14px;
  }
}
.assign-payment-forapproval-container .container-box {
  width: 94%;
  overflow-y: auto;
  box-shadow: 0px 0px 0px #5a71d01b;
  border: 0.5px solid #f2f2f2;
  padding: 2em;
  max-height: 450px;
  text-align: center;
}
@media (max-width: 1024px) {
  .assign-payment-forapproval-container .container-box {
    width: 90%;
  }
}
@media (max-width: 415px) {
  .assign-payment-forapproval-container .container-box {
    max-height: 290px;
    padding: 1em;
  }
}
@media (max-width: 321px) {
  .assign-payment-forapproval-container .container-box {
    max-height: 210px;
    width: 77%;
  }
}
.assign-payment-forapproval-container .new-form-sections {
  display: flex;
  flex-direction: column;
}
@media (max-width: 1024px) {
  .assign-payment-forapproval-container .new-form-sections {
    flex-direction: column;
  }
}
.assign-payment-forapproval-container .new-form-sections .form-inner-section {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin: 10px 0;
}
@media (max-width: 1024px) {
  .assign-payment-forapproval-container .new-form-sections .form-inner-section {
    flex-direction: column;
    width: 100%;
  }
}
.assign-payment-forapproval-container .new-form-sections .form-label.info-text {
  border-left: 2px solid #048041;
  text-align: left;
  padding: 0 2em;
}
@media (max-width: 896px) {
  .assign-payment-forapproval-container .new-form-sections .form-label.info-text {
    padding-top: 0.5em;
  }
}
.assign-payment-forapproval-container .new-form-sections .form-label,
.assign-payment-forapproval-container .new-form-sections .form-element {
  width: 30%;
  padding-right: 25px;
  text-align: left;
}
@media (max-width: 1024px) {
  .assign-payment-forapproval-container .new-form-sections .form-label,
  .assign-payment-forapproval-container .new-form-sections .form-element {
    flex-direction: column;
    width: 100%;
    padding-right: 0;
  }
}
.assign-payment-forapproval-container .new-form-sections .form-label.full,
.assign-payment-forapproval-container .new-form-sections .form-element.full {
  width: 100% !important;
}
.assign-payment-forapproval-container .new-form-sections .form-label.bold,
.assign-payment-forapproval-container .new-form-sections .form-element.bold {
  font-weight: bold;
}
@media (max-width: 896px) {
  .assign-payment-forapproval-container .ReactModalPortal .modal-content .button-set {
    flex-direction: column !important;
  }
}
.assign-payment-forapproval-container .review-btn-container {
  display: flex;
  justify-content: flex-end;
  margin: 1em 0 0 0;
}
.assign-payment-forapproval-container .review-btn-container button {
  margin: 10px 0 10px 20px;
}
.assign-payment-forapproval-container .review-btn-container button.edit-review {
  background-color: #FFF;
  color: #048041;
}
