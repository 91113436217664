.react-calendar__tile {
  border: none !important;
}
.enerva-react-date-picker-container {
  font-family: 'Roboto-Regular';
  display: flex;
}
.enerva-react-date-picker-container abbr {
  font-family: 'Roboto-Regular';
  text-transform: uppercase;
}
.enerva-react-date-picker-container .react-daterange-picker__inputGroup__leadingZero,
.enerva-react-date-picker-container .react-date-picker__inputGroup__divider {
  color: #686C6D;
}
.enerva-react-date-picker-container .react-daterange-picker {
  margin-top: 10px;
  margin-bottom: 10px;
}
.enerva-react-date-picker-container .react-calendar__tile--hasActive {
  background: none;
}
.enerva-react-date-picker-container .react-calendar__tile--rangeStart,
.enerva-react-date-picker-container .react-calendar__tile--rangeEnd {
  background: rgba(0, 150, 57, 0.3) !important;
}
.enerva-react-date-picker-container .react-calendar__tile--rangeStart abbr,
.enerva-react-date-picker-container .react-calendar__tile--rangeEnd abbr {
  color: #535765;
  font-family: 'Roboto-Bold';
}
.enerva-react-date-picker-container .react-daterange-picker__inputGroup {
  min-width: unset;
}
.enerva-react-date-picker-container .react-calendar__navigation__label__labelText {
  font-family: 'Roboto-Medium';
  text-transform: uppercase;
}
.enerva-react-date-picker-container .react-calendar__month-view__days__day--weekend {
  color: #000000;
}
.enerva-react-date-picker-container .react-daterange-picker__wrapper {
  padding: 8px;
  border: solid 1px #e3ddda;
  border-radius: 4px;
}
.enerva-react-date-picker-container .react-calendar__tile--now {
  background: #048041 !important;
  color: #FFFFFF;
}
.enerva-react-date-picker-container .react-calendar__tile--now:hover {
  background: #048041 !important;
}
.enerva-react-date-picker-container .react-daterange-picker__calendar-button {
  display: none;
}
.enerva-react-date-picker-container .react-daterange-picker__inputGroup__input:invalid {
  background: transparent;
}
.enerva-react-date-picker-container .filter-by-date-label {
  color: #048041;
  font-size: 18px;
  font-family: 'Roboto-Regular';
}
.enerva-react-date-picker-container .filter-by-date-label:hover {
  opacity: 0.6;
  cursor: pointer;
}
.enerva-react-date-picker-container .calendar-icon {
  padding-right: 1em;
  cursor: pointer;
  width: 30px;
}
.enerva-react-date-picker-container .calendar-icon:hover {
  opacity: 0.6;
  cursor: pointer;
}
.enerva-react-date-picker-container input {
  font-size: 16px;
  font-family: 'Roboto-Regular';
  line-height: 20px;
  width: 100%;
  text-align: center;
  color: #686C6D;
}
.enerva-react-date-picker-container .react-calendar__tile--active {
  background: #e3ddda;
  color: #686C6D;
}
.enerva-react-date-picker-container .react-calendar__tile--active:hover {
  background: #e3ddda;
}
.enerva-react-date-picker-container .react-calendar__tile--active:enabled:hover,
.enerva-react-date-picker-container .react-calendar__tile--active:enabled:focus {
  background: #e3ddda;
}
.enerva-react-date-picker-container .DateRangePickerInput {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}
.enerva-react-date-picker-container .DateRangePickerInput .CalendarDay__selected_span {
  background: #048041;
  border: 1px solid #e3ddda;
}
.enerva-react-date-picker-container .DateRangePickerInput .CalendarDay__selected {
  background: #048041;
  color: white;
  border: 1px solid #e3ddda;
}
.enerva-react-date-picker-container .DateRangePickerInput .CalendarDay__hovered_span:hover,
.enerva-react-date-picker-container .DateRangePickerInput .CalendarDay__hovered_span {
  background: #048041;
  color: #FFFFFF;
  border: 1px solid #e3ddda;
}
.enerva-react-date-picker-container .DateRangePickerInput .CalendarDay__selected:hover {
  background: #048041;
  color: white;
  border: 1px solid #e3ddda;
}
.enerva-react-date-picker-container .DateRangePickerInput .DateInput {
  display: flex;
}
.enerva-react-date-picker-container .DateRangePickerInput .DateInput .DateInput_input__focused {
  border-bottom-color: #048041;
}
.daterange-filter-label {
  font-family: 'Roboto-Medium';
  color: #048041;
  font-size: 18px;
  margin: 10px 0px;
}
.datePicker-calendar-icon {
  margin-right: 10px;
  width: 23px;
}
.react-daterange-picker__calendar--open {
  width: 275px !important;
}
