.flag-logs-container {
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  overflow-y: hidden;
}
.flag-logs-container .MuiTableCell-body p {
  font-size: 0.875rem;
  font-family: 'Roboto-Regular';
  text-align: left;
  color: rgba(0, 0, 0, 0.87);
}
@media (max-width: 1024px) {
  .flag-logs-container {
    padding: 0;
    margin: auto;
    flex: 100%;
    min-height: 360px;
    text-align: center;
    width: 100%;
    padding-bottom: 2em;
  }
}
.flag-logs-container .container-box {
  border: 0.5px solid #f2f2f2;
  width: 99%;
}
@media (max-width: 680px) {
  .flag-logs-container .container-box .MuiTableContainer-root {
    max-height: 325px;
    overflow-y: auto;
  }
}
@media (max-width: 321px) {
  .flag-logs-container .container-box .MuiTableContainer-root {
    max-height: 240px;
  }
}
.flag-logs-container .container-box .empty-summary-container {
  margin: unset;
  width: unset;
}
.flag-logs-container .section-header {
  display: flex;
  padding: 1em 0;
  width: 100%;
  align-items: center;
}
@media (max-width: 1024px) {
  .flag-logs-container .section-header {
    padding: 10px 0;
  }
}
.flag-logs-container .section-header .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  justify-content: flex-start;
  text-align: left;
}
@media (max-width: 1024px) {
  .flag-logs-container .section-header .section-title {
    font-size: 16px;
  }
}
.flag-logs-container .section-header .section-top-details {
  width: 30%;
  font-size: 14px;
  word-break: break-word;
  padding: 0 2%;
  font-family: 'Roboto-Medium';
}
.flag-logs-container .section-header .section-top-details.name-section {
  border-right: 1px solid #048041;
  border-left: 1px solid #048041;
  color: #048041;
}
.flag-logs-container .section-below-header1 {
  display: flex;
  padding: 1em 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .flag-logs-container .section-below-header1 {
    padding: 10px 0;
    flex-direction: row;
  }
}
@media (max-width: 896px) {
  .flag-logs-container .section-below-header1 {
    padding: 10px 0;
    flex-direction: column;
  }
}
.flag-logs-container .section-below-header1 .app-flag-btn {
  text-align: end;
  width: 35%;
}
@media (max-width: 896px) {
  .flag-logs-container .section-below-header1 .app-flag-btn {
    width: 100%;
    margin-bottom: 8px;
  }
  .flag-logs-container .section-below-header1 .app-flag-btn .enerva-btn {
    margin: 0;
    width: 100%;
  }
}
.flag-logs-container .section-below-header1 .title-container {
  display: flex;
  flex-direction: row;
  width: 65%;
}
@media (max-width: 1024px) {
  .flag-logs-container .section-below-header1 .title-container {
    width: 65%;
  }
  .flag-logs-container .section-below-header1 .title-container .flag-app-icon {
    height: 45px;
  }
  .flag-logs-container .section-below-header1 .title-container p {
    font-size: 16px;
  }
}
@media (max-width: 896px) {
  .flag-logs-container .section-below-header1 .title-container {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  .flag-logs-container .section-below-header1 .title-container .section-title {
    width: 50% !important;
  }
}
.flag-logs-container .section-below-header1 .title-container .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 36%;
}
@media (max-width: 1024px) {
  .flag-logs-container .section-below-header1 .title-container .section-title {
    font-size: 16px;
    width: 30% !important;
  }
}
@media (max-width: 896px) {
  .flag-logs-container .section-below-header1 .title-container .section-title {
    width: 100% !important;
    margin-bottom: 4px;
  }
}
@media (max-width: 1257px) {
  .flag-logs-container .section-below-header1 .title-container .section-title {
    width: 40%;
  }
}
@media (max-width: 1109px) {
  .flag-logs-container .section-below-header1 .title-container .section-title {
    width: 45%;
  }
}
.flag-logs-container .section-below-header1 .title-container .flag-current-status {
  display: flex;
  flex-direction: row;
  margin-bottom: 8px;
}
.flag-logs-container .section-below-header1 .title-container .flag-current-status .flag-app-icon {
  width: 14px;
  padding-right: 6px;
  padding-top: 6px;
}
@media (max-width: 1025px) {
  .flag-logs-container .section-below-header1 .title-container .flag-current-status .flag-app-icon {
    padding-top: unset;
    width: 12px;
  }
}
.flag-logs-container .section-below-header1 .title-container .flag-current-status p {
  font-size: 12px;
  margin-top: 30px;
}
@media (max-width: 1024px) {
  .flag-logs-container .section-below-header1 .title-container .flag-current-status p {
    margin-top: 20px;
  }
}
.flag-logs-container .section-below-header2 {
  display: flex;
  padding: 1em 0;
  width: 100%;
}
@media (max-width: 1024px) {
  .flag-logs-container .section-below-header2 {
    padding: 10px 0;
    flex-direction: column;
  }
}
.flag-logs-container .section-below-header2 .section-title {
  color: #048041;
  text-transform: uppercase;
  font-size: 24px;
  font-family: 'Roboto-Medium';
  font-weight: normal;
  text-align: left;
  width: 100%;
}
@media (max-width: 1024px) {
  .flag-logs-container .section-below-header2 .section-title {
    font-size: 16px;
  }
}
.flag-logs-container .form-sections {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  overflow-y: hidden;
}
@media (max-width: 1024px) {
  .flag-logs-container .form-sections {
    flex-direction: column;
  }
}
.flag-logs-container .form-sections .row-section {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}
@media (max-width: 896px) {
  .flag-logs-container .form-sections .row-section {
    flex-direction: column;
  }
}
.flag-logs-container .form-sections .row-section:nth-child(1) {
  padding-bottom: 1em;
}
.flag-logs-container .form-sections .row-section:nth-child(2n) {
  background-color: #e3ddda;
}
.flag-logs-container .form-sections .row-section .section-title {
  font-size: 18px;
  font-family: 'Roboto-Bold';
  color: #1D1D1D;
  text-transform: capitalize;
}
.flag-logs-container .form-sections .row-section .section-subtitle {
  font-size: 16px;
  font-family: 'Roboto-Regular';
  color: #535765;
  text-transform: capitalize;
}
.flag-logs-container .form-sections .row-section .section-left {
  width: 80%;
  text-align: left;
  padding-right: 1em;
  padding-left: 0.5em;
}
@media (max-width: 896px) {
  .flag-logs-container .form-sections .row-section .section-left {
    width: 100%;
    padding-right: 0px;
  }
}
.flag-logs-container .form-sections .row-section .section-right {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex: 1;
  flex-wrap: wrap;
}
@media (max-width: 896px) {
  .flag-logs-container .form-sections .row-section .section-right {
    width: 100%;
    padding-top: 0px;
  }
}
.flag-logs-container .form-sections .row-section .section-right p {
  color: #535765;
}
@media (max-width: 896px) {
  .flag-logs-container .form-sections .first-row {
    display: none;
  }
}
.flag-logs-container .signup-btn {
  width: 100%;
  margin-top: 25px;
  max-width: 250px;
}
@media (max-width: 1024px) {
  .flag-logs-container .signup-btn {
    max-width: unset;
  }
}
.flag-logs-container .confirm-btn-container {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
@media (max-width: 1024px) {
  .flag-logs-container .confirm-btn-container {
    flex-direction: column;
    min-width: 180px;
  }
}
.flag-logs-container .confirm-btn-container .enerva-btn {
  margin-right: 0px;
}
